<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet'
        })
    }
}
</script>
<template>
    <div class="elektra-tour-view elektra-scroll-y">
        <div class="elektra-tour-view__up ">
            <div class="elektra-tour-view__header">
                <div class="elektra-tour-intro__header-top">
                    <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                </div>
            </div>
            <div class="elektra-tour-view__header-bottom">
                <div class="elektra-tour-view__header-icon">
                    <img src="/elektrizate/chat.svg" alt="">
                </div>
                <div class="elektra-tour-view__header-text">
                    <h2>Diálogo de ventas</h2>
                </div>
            </div>
        </div>
        <div class="elektra-tour-view__content">
            <div class="elektra-tour-view__content-top">
                <div class="elektra-tour-view__content-top-scroll">
                    <div class="elektra-chat__wrap  animate__animated animate__fadeInUp">
                        <p class="">¡Buenos días!</p>
                       <div class="elektra-chat elektra-chat--me  animate__animated animate__fadeInUp">
                            <p>¿Ud. ya recibe los beneficios de la súperapp baz?</p>
                       </div>
                       <div class="elektra-chat  animate__animated animate__fadeInUp">
                            <p>“No aún no”</p>
                       </div>
                       <div class="elektra-chat elektra-chat--me  animate__animated animate__fadeInUpe">
                            <p>
                               <span class="fw-bold">baz</span> es la única súperapp que <span class="fw-bold">le regresa dinero</span> por las operaciones de dinero que Ud. realice.   <span class="fw-bold">¡Le cuento!</span>
                            </p>
                       </div>
                       <div class="elektra-chat elektra-chat--me  animate__animated animate__fadeInUp">
                            <p>
                                Si Ud. <span class="fw-bold">compra tiempo aire</span> por $50 pesos o más, <span class="fw-bold">baz le reembolsa</span> $30 pesos.
                            </p>
                       </div>
                       <div class="elektra-chat elektra-chat--me  animate__animated animate__fadeInUp">
                            <p>
                                Si Ud. <span class="fw-bold">paga sus servicios domésticos</span> por $200 pesos o más, <span class="fw-bold">baz le reembolsa</span> $50 pesos.
                            </p>
                       </div>
                       <div class="elektra-chat elektra-chat--me  animate__animated animate__fadeInUp">
                            <p>
                                Si Ud. <span class="fw-bold">paga el abono semanal de su crédito baz</span> por $50 pesos o más, <span class="fw-bold">baz le reembolsa</span> $30 pesos.
                            </p>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="elektra-tour-view__fotter animate__animated animate__fadeInUp">
            <router-link class="elektra-btn elektra-btn--md" :to="{name: 'ElektrizateTourMenu'}">Menu</router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.elektra-chat{
    display: flex;
    margin: 1.5rem 0;
    &__wrap{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    p{
        background-color: #6700ce;
        color: #fff;
        padding: 0.9rem;
        margin: 0;
        margin-left: auto;
        border-radius: 0.5rem;
        position: relative;
        border-bottom-right-radius: 0;
        &::after{
            content: '';
            display: block;
            background: url('/elektrizate/chat_you.png');
            width: 20px;
            height: 12px;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            bottom: -10px;
        }
    }
    &--me{
        p{
            background-color: #c9ff66;
            margin-left: 0;
            margin-right: auto;
            color: #000;
            border-bottom-left-radius: 0;
            &::before{
                content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                background: #b266f9; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 0.75em;
                height: 0.75em; /* Also needed for space (tweak if needed) */
                border-radius: 50%;
                position: absolute;
                left: -1.5rem;
                top: 50%;
                transform: translateY(-50%);
                box-shadow: 3px 1px 0 3px #a44dff;
            }
            &::after{
                content: '';
                display: block;
                background: url('/elektrizate/chat_me.png');
                width: 20px;
                height: 12px;
                background-size: 100% 100%;
                position: absolute;
                right: auto;
                left: 0;
                bottom: -10px;
            }
        }
    }
}
ul{
    list-style: none;
    li{
        position: relative;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        &::before{
            content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            background: #b266f9; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 0.75em;
            height: 0.75em; /* Also needed for space (tweak if needed) */
            margin-left: -1.5em; /* Also needed for space (tweak if needed) */
            border-radius: 1rem;
            position: absolute;
            left: 0;
            box-shadow: 3px 1px 0 3px #a44dff;
            top: 0.1rem;
        }
    }
}
.elektra-tour-intro__logo{
    width: 60%;
    margin: 0 auto;
    display: block;
}
.elektra-tour-view{
    background-color: #fff;
    height: 100vh;
    &__fotter{
        background-color: #6700ce;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
    &__up{
        position: relative;
        height: 280px;
        min-height: 280px;
    }
    &__header{
        padding-top: 1rem;
        width: 100%;
        background-image: url('/elektrizate/bg_header_score.png');
        background-size: 100% auto;
        background-position: bottom center;
        position: relative;
        height: 280px;
        min-height: 280px;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        
        .elektra-socre__title{
            font-size: 1.5rem;
            font-weight: 600;
        }
        &-icon{
            background: #fff;
            border-radius: 2rem;
            padding: 0.5rem 1rem;

            img{
                width: 40px;
                display: block;
                margin: 0 auto;
            }
        }
        &-text{
            margin-left: 0.5rem;
            padding-right: 2rem;
            font-weight: 800;
            h2{
                font-weight: 800;
            }
        }
    }
    &__user{
        position: absolute;
        bottom: 16%;
        padding: 1rem;
        padding-left: 2.5rem;
    }
    &__badge{
        width: 50%;
        position: relative;
        margin-left: auto;
    }
    &__sun2{
        position: absolute;
        transform: scale(1.5);
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &__sun{
        animation: sun 220s infinite alternate;
    }
    &__iso{
        position: absolute;
        z-index: 3;
        width: 60%;
        left: 20%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header-bottom{
        background-color: #954cdd;
        height: 180px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -5%;
        z-index: 1;
        padding-top: 10%;
        padding-left: 60px;
        display: flex;
        align-items: center;
        &-wrap{
            display: flex;
            align-items: center;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            font-weight: 600;
            font-size: 0.9rem;
            margin-top: -6px;
            margin-bottom: 0;
        }
    }
    &__content{
        color: #000;
        background-color: #954cdd;
        &-top{
            position: relative;
            background-color: #fff;
            padding:  0;
            text-align: left;
            z-index: 3;
            margin-top: -15px;
            border-radius: 0 5rem 0 0;
            font-size: 0.9rem;
            line-height: 1rem;
            
            &::before{
                content: '';
                display: block;
                background-image: url('/elektrizate/score_top_decoration.png');
                background-size: 100% 100%;
                position: absolute;
                top: -49px;
                height: 50px;
                width: 60px;
                left: 0;
              
            }

            &-scroll{
                overflow-y: auto;
                height: calc(100vh - 280px);
                padding: 1.5rem;
                border-radius: 0 5rem 0 0;
              
            }

        }
        
    }   
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        background-color: #f1f1f1;
        width: 32%;
        border-radius: 100px;
        line-height: 0.9rem;
        &-wrap-icon{
            background-color: #c9ff66;
            border-radius: 100px;
            padding: 1rem 0rem;
            box-shadow: 3px 3px 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        &-client{
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 800;
            margin: 0;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        &-user{
            color: #6700ce;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            padding-left: 0.75rem;
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        &-title{
            font-size: 0.60rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &-x{
            padding-right: 0.5rem;
            padding-left: 0.5rem;

        }
    }
}
</style>