<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet'
        })
    }
}
</script>
<template>
    <div class="elektra-tour-view elektra-scroll-y">
        <div class="elektra-tour-view__up ">
            <div class="elektra-tour-view__header">
                <div class="elektra-tour-intro__header-top">
                    <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                </div>
            </div>
            <div class="elektra-tour-view__header-bottom">
                <div class="elektra-tour-view__header-icon">
                    <img src="/elektrizate/file_check.svg" alt="">
                </div>
                <div class="elektra-tour-view__header-text">
                    <h2>Reglas de participación</h2>
                </div>
            </div>
        </div>
        <div class="elektra-tour-view__content">
            <div class="elektra-tour-view__content-top">
                <div class="elektra-tour-view__content-top-scroll">
                    <p class="">Estimad@ colaborador@:</p>
                    <p>
                        En esta nueva etapa baz busca que tu cliente adopte los servicios y lbeneficios que tiene diseñados para él. Tu apoyo y asesoría para que el cliente los conozca es fundamental. 
                    </p>
                    <ul>
                        <li>Ahora, tú ganarás Elektrones si logras que tu cliente:
    
                            <ul>
                                <li>a) Recargue tiempo aire en baz súperapp</li>
                                <li>b) Pague sus servicios domésticos a través de baz súperapp</li>
                                <li>c) Pague el abono semanal de su crédito en baz súperapp</li>
                            </ul>
                        </li>
                        <li>
                            Tú ganarás Elektrones siempre que cualquiera de estas transacciones sean de clientes nuevos que actives a partir del 21 de Marzo del 2023 y que se realicen en  las siguientes 4 semanas a la fecha de su activación como clientes.
                        </li>
                        <li>
                            Ganarás un Elektrón por cada transacción del cliente y como máximo, un Elektrón de cada tipo de transacción. En resumen un máximo de 3 Elektrones por cada cliente.
                        </li>
                        <li>
                            En todo momento podrás visualizar las transacciones que vayan realizado tus clientes y el saldo en Elektrones que vayas ganado por ello.
                        </li>
                        <li>
                            Los Elektrones que ganes podrás cambiarlos directamente en la bóveda de premios que ya conoces, en las fechas que se comunicarán para este fin.
                        </li>
                        <li>
                            En Zeus Box encontrarás infografías con los pasos esenciales para que puedas asesorar a tus clientes sobre estas y otras interesantes transacciones disponibles en baz súperapp.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="elektra-tour-view__fotter animate__animated animate__fadeInUp">
            <router-link class="elektra-btn elektra-btn--md" :to="{name: 'ElektrizateTourMenu'}">Menu</router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
ul{
    list-style: none;
    li{
        position: relative;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        &::before{
            content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            background: #b266f9; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 0.75em;
            height: 0.75em; /* Also needed for space (tweak if needed) */
            margin-left: -1.5em; /* Also needed for space (tweak if needed) */
            border-radius: 1rem;
            position: absolute;
            left: 0;
            box-shadow: 3px 1px 0 3px #a44dff;
            top: 0.1rem;
        }
    }
    ul{
        li{
            &::before{
                display: none;
            }
        }
    }
}
.elektra-tour-intro__logo{
    width: 60%;
    margin: 0 auto;
    display: block;
}
.elektra-tour-view{
    background-color: #fff;
    height: 100vh;
    &__fotter{
        background-color: #6700ce;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
    &__up{
        position: relative;
        height: 280px;
        min-height: 280px;
    }
    &__header{
        padding-top: 1rem;
        width: 100%;
        background-image: url('/elektrizate/bg_header_score.png');
        background-size: 100% auto;
        background-position: bottom center;
        position: relative;
        height: 280px;
        min-height: 280px;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        
        .elektra-socre__title{
            font-size: 1.5rem;
            font-weight: 600;
        }
        &-icon{
            background: #fff;
            border-radius: 2rem;
            padding: 0.5rem 1rem;

            img{
                width: 40px;
                display: block;
                margin: 0 auto;
            }
        }
        &-text{
            margin-left: 0.5rem;
            padding-right: 2rem;
            font-weight: 800;
            h2{
                font-weight: 800;
            }
        }
    }
    &__user{
        position: absolute;
        bottom: 16%;
        padding: 1rem;
        padding-left: 2.5rem;
    }
    &__badge{
        width: 50%;
        position: relative;
        margin-left: auto;
    }
    &__sun2{
        position: absolute;
        transform: scale(1.5);
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &__sun{
        animation: sun 220s infinite alternate;
    }
    &__iso{
        position: absolute;
        z-index: 3;
        width: 60%;
        left: 20%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header-bottom{
        background-color: #954cdd;
        height: 180px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -5%;
        z-index: 1;
        padding-top: 10%;
        padding-left: 60px;
        display: flex;
        align-items: center;
        &-wrap{
            display: flex;
            align-items: center;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            font-weight: 600;
            font-size: 0.9rem;
            margin-top: -6px;
            margin-bottom: 0;
        }
    }
    &__content{
        color: #000;
        background-color: #954cdd;
        &-top{
            position: relative;
            background-color: #fff;
            padding:  0;
            text-align: left;
            z-index: 3;
            margin-top: -15px;
            border-radius: 0 5rem 0 0;
            font-size: 0.9rem;
            line-height: 1rem;
            
            &::before{
                content: '';
                display: block;
                background-image: url('/elektrizate/score_top_decoration.png');
                background-size: 100% 100%;
                position: absolute;
                top: -49px;
                height: 50px;
                width: 60px;
                left: 0;
              
            }

            &-scroll{
                overflow-y: auto;
                height: calc(100vh - 280px);
                padding: 1.5rem;
                border-radius: 0 5rem 0 0;
              
            }

        }
        
    }   
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        background-color: #f1f1f1;
        width: 32%;
        border-radius: 100px;
        line-height: 0.9rem;
        &-wrap-icon{
            background-color: #c9ff66;
            border-radius: 100px;
            padding: 1rem 0rem;
            box-shadow: 3px 3px 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        &-client{
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 800;
            margin: 0;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        &-user{
            color: #6700ce;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            padding-left: 0.75rem;
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        &-title{
            font-size: 0.60rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &-x{
            padding-right: 0.5rem;
            padding-left: 0.5rem;

        }
    }
}
</style>