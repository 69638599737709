<script>
import { mapActions } from 'vuex'
import http from '../../http';
import {decript} from '@/utils/decript'

export default {
    data() {
        return {
            userChallenges: [],
        }
    },
    methods: {
        async getChallenges() {
            try {
                let challenges = await http.challenges.current()
                this.$set(this, 'userChallenges', challenges)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject()
            }
        },
        ...mapActions({
			login: 'auth/login',
			fetchUser: 'auth/fetchUser',
			fetchMaterials: 'auth/fetchMaterials',
		})
    },
    async mounted() {
        let token = this.$route.query.hasOwnProperty('code') ? this.$route.query.code : null
		let code = localStorage.getItem('code') || null
		let goToScore = false
		try {
            if(token) {
                let parse = decript(token)
                // goToScore = parse == code
                code = parse || code
            } 
            if(!code) {
                return  window.location.href = "https://gemas.sorianoariza.com.mx/#/?code=" + token;
            }
            debugger
            let auth = await this.login({code})
            debugger
            if(!auth) {
                return  window.location.href = "https://gemas.sorianoariza.com.mx/#/?code=" + token;
            }
    
            let user = await this.fetchUser()
            await this.getChallenges()
            if(!this.userChallenges.length) {
               return  window.location.href = "https://gemas.sorianoariza.com.mx/#/?code=" + token;
            } 
            debugger
            if(!user.user.tour_complete) {
                return this.$router.push({name: 'ElektrizateTourIntro'})
            } else {
                return this.$router.push({name: 'Home'})
            }
            if(goToScore) {
                this.$router.push({name: 'Score'})
            }
        } catch (error) {
            return  window.location.href = "https://gemas.sorianoariza.com.mx/#/?code=" + token;
        }
    }
}
</script>
<template>
    <div class="elektra-validation">
        <div class="elektra-validation__logo">
            <img  src="/elektrizate/logo.svg" alt="">
        </div>
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</template>
<style lang="scss" scoped>
.elektra-validation{
    color: #000;
    background: url('/elektrizate/bg.png');
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__logo{
        max-width: 250px;
        margin: 1rem auto;
        img{
            width: 100%;
            margin-left: -20px;
        }
    }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>