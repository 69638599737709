<script>
export default {
    data() {
        return {
            menu: [
                {
                    name: 'home',
                    icon: 'nav_home.svg',
                    route: 'Home',
                },
                {
                    name: 'explorer',
                    icon: 'nav_explorer.svg',
                    route: 'Explorer',
                },
                {
                    name: 'notifications',
                    icon: 'nav_notification.svg',
                    route: 'Notifications',
                },
            ]
        }
    },
}
</script>
<template>
    <div class="elektra-menu-nav">
        <router-link v-for="(item, index) in menu" :key="`nav-${index}`" class="elektra-menu-nav__item" :to="{name: item.route}" :class="{'elektra-menu-nav__item-active': item.name == $route.meta.menu}">
            <img class="elektra-menu-nav__item-icon" :src="`elektrizate/${item.icon}`" alt="">
        </router-link>
        
    </div>
</template>
<style lang="scss" scoped>
.elektra-menu-nav{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 420px;
    height: 60px;
    background: transparent;
    display: flex;
    z-index: 100;
    @media screen and (min-width: 420px) {
        transform: translateX(-50%);
        left: 50%;
    }
    &__item{
        display: block;
        background: #7100d6;
        width: 20%;
        height: 60px;
        flex-grow: 1;
        bottom: 0;
        text-align: center;
        display: flex;
        margin-left: -1px;
        margin-right: -1px;
        &-active{
            background: url('/elektrizate/bg_menu_selected.png');
            background-size: 100% 100%;
            .elektra-menu-nav__item-icon{
                margin-top: -12px;
                box-shadow: 0 72px 0 12px rgba(#7100d6, 1);
                border-radius: 50%;
            }
        }
        &-icon{
            width: 50px;
            height: 50px;
            margin: auto;
            transition: 0.3s;
            margin-top: 5px;
            box-shadow: 0 20px 0 20px rgba(#7100d6, 1);
            border-radius: 50%;
        }
    }
}
</style>