<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        product: {
            type: Object,
            default() {
                return null
            }
        },
        buy: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            authWallet: 'auth/wallet',
            products: 'vault/localProducts',
            vaultCurrentPage: 'vault/currentPage',
            vaultLoading: 'vault/loading'
        })
    },
    methods: {
        exchage(amount) {
            let rate = this.authWallet.exchange.value

            return Math.ceil(amount / rate)
        },
    }
}
</script>
<template>
    <div class="elektra-product animate__animated animate__fadeInUp" @click="routeGo({name: 'VaultDetails', params: {sku: product.sku}})">
        <div class="elektra-product-bg"></div>
        <div class="elektra-product-details" v-if="product.type == 'elektra'">
            <div>
                <h2 class="elektra-product-title">{{ product.elektra.ProductName }}</h2>
                <div v-if="buy">
                    <p class="elektra-product-price" v-if="exchage(product.elektra.Price) > authWallet.balance || !authUser.vault_open">Disponible por E{{ exchage(product.elektra.Price) }}</p>
                    <button v-else class="elektra-btn elektra-btn--secondary font-weight-bold">E{{ exchage(product.elektra.Price) }} Canjear</button>
                </div>
            </div>
            <div class="elektra-product-picture">
                <img :src="forceHTTPS(product.elektra.ImageUrl)" alt="">
            </div>
        </div>
        <div class="elektra-product-details" v-else>
            <div>
                <h2 class="elektra-product-title">{{ product.name }}</h2>
                <div v-if="buy">
                    <p class="elektra-product-price" v-if="product.gems > authWallet.balance || !authUser.vault_open">Disponible por E{{ product.gems }}</p>
                    <button v-else class="elektra-btn elektra-btn--secondary font-weight-bold">E{{ product.gems }} Canjear</button>
                </div>
            </div>
            <div class="elektra-product-picture">
                <img :src="forceHTTPS(product.gallery[0])" alt="">
            </div>
        </div>

                
    </div>
</template>

<style lang="scss" scoped>
.elektra{
    &-product{
        padding: 1rem 2rem;
        position: relative;
        max-width: 320px;
        margin: 0 auto;
        margin-bottom: 1rem;
        opacity: 0;
        &-bg{
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 2rem;
            transform: skew(-12deg, 0deg);
            box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
        }
        &-details{
            position: relative;
            padding-top: 1rem;
            z-index: 2;
            color: #000;
            display: flex;
            align-items: center;
        }
        &-title{
            margin: 0;
            font-size: 0.7rem;
            font-weight: 600;
            margin-bottom: 0.7rem;
        }
        &-ref{
            color: #7100d6;
            font-size: 0.6rem;
            margin-bottom: 0;
        }
        &-picture{
            width: 40%;
            min-width: 40%;
            img{
                width: 100%;
                display: block;
                margin-top: -20%;
            }
        }
        &-price{
            color: #6700ce;
            font-size: 0.8rem;
            margin: 0;
        }
        &-icon{
            width: 20%;
            margin-right: 0.5rem;
            img{
                width: 100%;
                margin-top: -25%;
            }
        }
    }
}
</style>