<script>
export default {
	props: {
		canClose: {
			type: Boolean,
			default() {
				return true
			}
		},
		opening: {
			type: Boolean,
			default() {
				return false
			}
		}
	},

	data() {
		return {
			show: false,
			closing: false,
		}
	},

	watch: {
		'opening' : {
			handler(nVal) {
				if(nVal) {
					this.open()
				}
			},
			immediate: true
		}
	},

	methods: {
		open() {
			this.closing = false
			this.show = true
			this.$emit('open')
		},

		close() {
			
			if(!this.canClose) {
				return
			}
			let vm = this
			vm.closing = true
			setTimeout(() => {
				vm.show = false
				vm.$emit('close')
				
			}, 500)

			
		}
	}
}
</script>

<template>
	<div class="app-modal" :class="{'app-modal--closing': closing}" v-if="show" @click="close()">
		<div class="app-modal__wrapper" @click.stop="">
			<div class="app-modal__header justify-content-center">
				<slot name="header">
					Header
				</slot>
				<button class="app-modal__close btn btn-sm btn-transparent" v-if="canClose" @click="close()">
					<img style="width: 18px" src="/assets/cancel.png" alt="">
				</button>
			</div>
			<div class="app-modal__body justify-content-center">
				<slot name="body" :close="close">
					Body
				</slot>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.app{
	&-modal{
		position: fixed;
		padding: 0.5rem;
		top: 0;
		left: 0;
		z-index: 101;
		width: 100%;
		height: 100%;
		display: flex;
		background-color: rgba($color: #545999, $alpha: 0);
		backdrop-filter: blur(0);
		animation: modal 0.3s ease-in-out forwards;
		&__wrapper{
			margin: auto;
			background-color: #fff;
			border-radius: 0.75rem;
			max-width: 768px;
			min-width: 300px;
			max-height: 90%;
			overflow: auto;
			opacity: 0;
			box-shadow: 3px 3px 5px rgba($color: #505a79, $alpha: 1);
			transform: translateY(50%);
			animation: modalWrapper 0.3s 0.3s  ease-in-out forwards;
		
		}


		&--closing{
			animation: modalClosing 0.2s 0.2s ease-in-out forwards;
			background-color: rgba($color: #545999, $alpha: 0.7);
			backdrop-filter: blur(2px);
			.app-modal__wrapper{
				opacity: 1;
				transform: translateY(0);
				animation: modalWrapperClosing 0.2s ease-in-out forwards;
			}
		}
		&__header{
			padding: 0.5rem 1rem;
			border-bottom: 1px solid #eee;
			display: flex;
			align-items: center;
			position: relative;
		}
		&__close{
			margin-left: auto;
			position: absolute;
			right:  0;
			top: 0;
		}
		
		&__body{
			padding: 0.5rem 1rem;
		}
		
	}
}


@keyframes modalWrapper {
	0% {
		opacity: 0;
		transform: translateY(50%);
	} 100%{
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes modal {
	0% {
		background-color: rgba($color: #545999, $alpha: 0);
		backdrop-filter: blur(0);
	} 100%{
		background-color: rgba($color: #545999, $alpha: 0.7);
		backdrop-filter: blur(2px);

	}
}

@keyframes modalWrapperClosing {
	0%{
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(50%);
	} 
}
@keyframes modalClosing {
	0%{
		background-color: rgba($color: #545999, $alpha: 0.7);
		backdrop-filter: blur(2px);

	}
	100% {
		background-color: rgba($color: #545999, $alpha: 0);
		backdrop-filter: blur(0);
	} 
}
</style>