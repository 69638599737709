<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data() {
		return {
			classStart: '',
			code: '',
			codeInParam: false
		}
	},

	methods: {
		reconect() {
			let vm = this
			// localStorage.setItem('code', this.code)
			this.login({
				code: this.code
			}).then(res => {
				vm.$router.push({name: 'Score'})
			})
		},
		...mapActions({
			login: 'auth/login',
			fetchUser: 'auth/fetchUser'
		})
	},

	computed:{
		...mapGetters({
			loginFailed: 'auth/loginFailed'
		})
	},

	mounted() {
		this.codeInParam = this.$route.query.hasOwnProperty('code') ? true : false
		let vm = this
		vm.classStart = 'start--show'
	}
}
</script>
<template>
	<div class="start" :class="classStart">
		<div class="start__top">
			<img class="start__robot start__robot--left" src="/assets/pantalla_inicio/robot_pequeno_izquierda.png" alt="">
			<img class="start__robot start__robot--rigth" src="/assets/pantalla_inicio/robot_pequeno_derecha.png" alt="">
			<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
		</div>
		<div class="start__bottom mb-5">
			<div class="start__content">
				<div class="start__bottom__slogan mb-2 mx-auto">
					<img class="start__bottom__slogan-pin" src="/assets/v2/logo_v2.png" alt="">
				</div>
				<h6 v-if="codeInParam||loginFailed" class="text-center text-white">
					¡Oh no! No logramos conectar con tu cuenta.
				</h6>
				<p class="text-white">Estamos preparando tus datos por favor regresa mañana.</p>
			</div>
			<!-- <div class="start__logo">
				<img src="/assets/pantalla_inicio/logo_zeus.png" alt="">
			</div> -->
		</div>
	</div>
</template>