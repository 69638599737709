<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../../http';
import TransactionsChallenge from '../../components/Elektrizate/Challenges/TransactionsChallenge.vue'
export default {
    components: {
        TransactionsChallenge
    },
    data() {
        return {
            userChallenges: [],
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet'
        })
    },
    methods: {
        async getChallenges() {
            try {
                let challenges = await http.challenges.current()
                this.$set(this, 'userChallenges', challenges)
                return Promise.resolve()
            } catch (error) {
                return Promise.reject()
            }
        },
        ...mapActions({
            fetchUser: 'auth/fetchUser',
        })
    },
    async mounted() {
        await this.fetchUser()
        await this.getChallenges()
    }
}
</script>
<template>
    <component v-if="userChallenges.length == 1" :is="userChallenges[0].component" :challenge="userChallenges[0]">

    </component>
    <div v-else-if="userChallenges.length > 1">
    
    </div>
    <div v-else class="text-black">
        <!-- <h1>NO HAY</h1> -->
    </div>
</template>
<style lang="scss" scoped>
.text-black{
    color: #000;
}
.elektra-explorer{
    background: #fff;
    height: 100vh;
    color: #000;
    padding-bottom: 170px;
    &__notice{
        background-color: #c9ff66;
        padding: 2rem;
        width: 100%;
        max-width: 420px;
        position: fixed;
        z-index: 99;
        bottom: -100%;
        padding-bottom: 100px;
        text-align: center;
        border-radius: 100px 0 0 0;
        animation: notice 0.5s forwards ease-in-out;
        &::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            top: -49px;
            right: 0;
            background: url('/elektrizate/notice_top_decoration.png');
            background-size: 100% 100%;
        }
    }
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        width: 30%;
        position: relative;
        filter: grayscale(1);
        opacity: 0.6;
        &-paid{
            filter: grayscale(0);
            opacity: 1;
            .elektra-explorer__challenge-bonus{
                display: flex;
            }
        }
        &-bonus{
            position: absolute;
            display: flex;
            align-items: flex-end;
            bottom: -30%;
            right: -15%;
            display: none;
        }
        &-amount{
            color: #00cb00;
            font-weight: bold;
            display: inline-block;
            padding-bottom: 0.5rem;
            font-size: 0.7rem;
        }
    }
    &__list{
        padding: 1rem 2rem;
        &-item{
            min-height: 150px;
            position: relative;
            &-count{
                background-color: #7100d6;
                color: #fff;
                font-size: 0.7rem;
                border-radius: 25px;
                padding-left: 0.25rem;
                padding-right: 0.25rem;
            }
            &-bg{
                background-color: #fff6cc;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 2rem;
                transform: skew(-12deg, 0deg);
                box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
            }
            &-content{
                padding: 1rem 2rem ;
                position: relative;
                z-index: 2;
            }
            &-title{
                margin: 0;
                font-size: 1rem;
                font-weight: 600;
            }
            &-ref{
                color: #7100d6;
                font-size: 0.6rem;
                margin-bottom: 0;
            }
        }
    }
    &__header{
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 50px;
    }
    &__wallet{
        background-color: #f1f1f1;
        padding: 1rem 2rem;
        border-radius: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-wrap{
            display: flex;
            align-items: center ;
        }
        &-iso{
            width: 40px;
            display: block;
            margin: 0 auto;
        }
        &-balance{
            width: 40px;
            color: #7100d6;
            font-weight: 600;
            margin-top: -5px;
            margin-bottom: -5px;

        }
    }
    &__user{
        color: #7100d6;
        &-name{
            margin: 0;
            font-size: 1.1rem;
            text-align: center;
        }
        &-workposition{
            margin: 0;
            font-size: 0.7rem;
            text-align: center;
            font-weight: bold;
        }
    }
    &__battery{
        min-width: 60px;
    }
    @keyframes notice {
        0%{
            bottom: -100%;
        } 
        80%{
            bottom: 12px;
        }
        100%{
            bottom: 0;
        }
    }
}
</style>