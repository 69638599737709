function getFromLocalStorage(key) {
	return localStorage[key] ? JSON.parse(localStorage[key]) : []
}
export default {
	query:'',
	current_page: 0,
	last_page: 0,
	local_products: [],
	skus: [],
	categories: [],
	api_products: [],
	product: null,
	loading: false,
}