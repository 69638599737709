<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import http from '../../http'
import ModalVue from '../../components/Elektrizate/ModalVue.vue'
import moment from "moment"
import ProductItem from '../../components/Elektrizate/ProductItem.vue'
let _ = require('lodash')

export default {
	components: {
		ModalVue,
		ProductItem
	},
	data() {
		return {
			alowedOrder: true,
			waitTime: 0,
			time: null,
			form: {
				receiver: '',
				phone: '',
				city: '',
				state: '',
				postal_code: '',
				number: '',
				street: '',
				complement: '',
				neighborhood: '',
				reference: '',

				phone: '',
				email: '',
				first_name: '',
				last_name: '',


				address_id: '',

				number_card: '',
				number_card_confirm: '',

			},
			errors: {
				receiver: '',
				phone: '',
				city: '',
				state: '',
				postal_code: '',
				number: '',
				street: '',
				complement: '',
				neighborhood: '',
				reference: '',

				phone: '',
				email: '',
				first_name: '',
				last_name: '',


				address_id: '',

				pickup_selected: '',
				number_card: '',
				number_card_confirm: ''

			},
			loading: false,
			states: [],
			success: false,
			createAddress: false,
			currentPostalCode: [],
			postalCodes: [],
			pickup_selected: [],
			ekts: [],
			isLoading: false,
			typeCard: '',

			you_has_card: false,
			you_has_nip: false,
		}
	},
	computed: {
		
		
		statsRegion() {
			return this.regionReferalsStats(this.user.id)
		},
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			productBySku: 'vault/productBySku',
			product: 'vault/product',
			addresses: 'auth/addresses'
		})
	},
	methods: {
		setTypeCard(type) {
			this.typeCard = type
			if(!type) {
				this.you_has_card = false
				this.you_has_nip = false
			}
		},

		compareCard() {
			let is_same = this.form.number_card == this.form.number_card_confirm

			if(!is_same) {
				this.errors.number_card_confirm = 'No coincide'
			} else {
				this.errors.number_card_confirm = ''
			}
		},
		verifyNumber() {
			let number_card = this.form.number_card

			if(number_card.length != 19) {
				this.errors.number_card = 'Debe contener 16 dígitos'
			}
		},
		async getStates() {
			let vm = this

			try {
				let states = await http.states.list()
				vm.$set(vm, 'states', states)
			} catch (error) {
				
			}
		},

		getPostalCode: _.debounce( async function () {
			let vm = this
			vm.form.city = ''
			vm.form.state = ''
			vm.form.neighborhood = ''
			vm.$set(vm, 'postalCodes', [])
			try {
				vm.clearError('postal_code')
				let postalCodes = await http.postaCode.get(vm.form.postal_code)
				postalCodes
				vm.$set(vm, 'postalCodes', postalCodes)
			} catch (error) {
				console.log(error)
			}
		}, 1000),
	
		async simulateOrder() {
			let vm = this
			vm.$refs.confirmOrderModal.close()
		

			let data = {}

			if(this.form.address_id) {
				data = {
					sku: vm.product.sku,
					address_id: this.form.address_id,
					phone: vm.form.phone,
					email: vm.form.email,
					first_name: vm.form.first_name,
					last_name: vm.form.last_name,
				}
			} else {
				// 72498
				// let state = vm.states.find(s => s.id == vm.form.state)
				// let city = state.cities.find(s => s.id == vm.form.city)
				data = {
					sku: vm.product.sku,
					street: vm.form.street,
					number: vm.form.number,
					neighborhood: vm.form.neighborhood,
					complement: vm.form.complement,
					reference: vm.form.reference,
					state: vm.form.state,
					city: vm.form.city,
					receiver: vm.form.receiver,
					postal_code: vm.form.postal_code,
					// postal_code: 'xxxx',
					phone: vm.form.phone,
					email: vm.form.email,
					first_name: vm.form.first_name,
					last_name: vm.form.last_name,

				}
			}


			
			this.loading = true


			// lastRewards.push({
			// 	sku: vm.product.sku,
			// 	date: moment().toDate()
			// })

			try {
				let response = await http.orders.simulation(data)
				if(response.status == 200) {
					let data = JSON.stringify(response)
					this.createOrder(data)
				} else {
					this.loading = false
					if(response.hasOwnProperty('message')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: response.message,
							type: 'warn',
							duration: 10000
						});
						if(response.message == 'Este producto se ha agotado') {
							setTimeout(() => {
								this.$router.push({name: 'Vault'})
							}, 3000)
						}
						if(response.type == 'priceChange') {
							this.updatePrice(response)
						}
						return
					}
					let body = JSON.parse(response.body)
					let message = body.Message.toLowerCase()
					if(message.includes('el campo código postal de los datos de entrega no es valido')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'No podemos entregar este producto en la dirección solicita',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('el item no cuenta con informacion de envío')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'No podemos entregar este producto en la dirección solicita',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('no está disponible')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'El producto seleccionado ya no se encuentra disponible',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('the selected sla for item')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'No podemos entregar este producto en la dirección solicita',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('sla no dom')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'No podemos entregar este producto en la dirección solicita',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('sla no elektra')) {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'El producto seleccionado no es vendido por Elektra',
							type: 'warn',
							duration: 10000
						});
					} else if(message.includes('en el costo del canje')) {
						console.log(body)
						this.updatePrice(body)
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: message,
							type: 'warn',
							duration: 10000
						});
						
					} else {
						this.$notify({
							group: 'tc',
							title: 'Atención',
							text: 'No podemos canjear este premio selecciona otro o intenta mas tarde',
							type: 'warn',
							duration: 10000
						});
					}
				}
				// vm.success = true
				// this.loading = false
				// this.createOrder()

				// if(response.hasOwnProperty('message')) {
				// 	this.$notify({
				// 		group: 'tc',
				// 		title: 'Atención',
				// 		text: response.message,
				// 		type: 'warn'
				// 	});
				// } else {
				// 	vm.setWallet(response)
				// 	vm.success = true

				// }
			    // this.loading = false
			} catch (error) {
				this.loading = false
				
				this.$notify({
					group: 'tc',
					title: 'Atención',
					text: 'No podemos canjear este premio selecciona otro o intenta mas tarde',
					type: 'warning',
					duration: 10000
				});
			}
		},
		async createOrder(simulation = false) {
			if(!this.alowedOrder) {
				return
			}

			this.alowedOrder = false
			
			let vm = this
			vm.$refs.confirmOrderModal.close()

			let lastRewards = localStorage.getItem('lastRewards')

			if(lastRewards) {
				lastRewards = JSON.parse(lastRewards)
			} else {
				lastRewards = []
			}

			let inLastFive = lastRewards.findIndex(l => {
				return l.sku == vm.product.sku
			})

			if(inLastFive >= 0) {
				let date =  vm.$moment(lastRewards[inLastFive].date).add(5, 'minutes')
				let now = vm.$moment()
				if(date.isAfter(now)) {
				
				} else {
					lastRewards[inLastFive].date = vm.$moment().toDate()
					vm.$refs.waitFiveModal.close()
				}
			} else {
				lastRewards.push({
					sku: vm.product.sku,
					date: vm.$moment().toDate()
				})
				vm.$refs.waitFiveModal.close()
			}

			localStorage.setItem('lastRewards', JSON.stringify(lastRewards))
			
		
			let data = {}
			if(this.product.type == 'elektra') {
				if(this.form.address_id) {
					data = {
						sku: vm.product.sku,
						address_id: this.form.address_id,
						phone: vm.form.phone,
						email: vm.form.email,
						first_name: vm.form.first_name,
						last_name: vm.form.last_name,
					}
				} else {
					// let state = vm.states.find(s => s.id == vm.form.state)
					// let city = state.cities.find(s => s.id == vm.form.city)
					data = {
						sku: vm.product.sku,
						street: vm.form.street,
						number: vm.form.number,
						neighborhood: vm.form.neighborhood,
						complement: vm.form.complement,
						reference: vm.form.reference,
						state: vm.form.state,
						city: vm.form.city,
						receiver: vm.form.receiver,
						postal_code: vm.form.postal_code,
						phone: vm.form.phone,
						email: vm.form.email,
						first_name: vm.form.first_name,
						last_name: vm.form.last_name,
					}
				}
			} else {
				data = {
					sku: vm.product.sku,
					
					phone: vm.form.phone,
					email: vm.form.email,
					first_name: vm.form.first_name,
					last_name: vm.form.last_name,
				}

				if(!this.user.has_card || (this.user.has_card && this.typeCard == 'new')) {
					data.pickup_id = this.pickup_selected[0].id
				}
				if((this.user.has_card && this.typeCard == 'recharge' && this.form.number_card)) {
					data.number_card = this.form.number_card.replace(/\s/g, '')
				}

				data.type_card = this.typeCard || 'new'

				if(this.product.type == 'card_nip') {
					data.type_card = ''
				}
			}

			if(simulation) {
				data.simulation_response = simulation
			}

			
			this.loading = true



			// lastRewards.push({
			// 	sku: vm.product.sku,
			// 	date: moment().toDate()
			// })

			try {
				let response = await http.orders.create(data)
				

				if(response.hasOwnProperty('message')) {
					this.$notify({
						group: 'tc',
						title: 'Atención',
						text: response.message,
						type: 'warn'
					});
				} else {
					vm.setWallet(response)
					vm.success = true

				}
			    this.loading = false
			} catch (error) {
				this.loading = false
				
				this.$notify({
					group: 'tc',
					title: 'Important message',
					text: 'Hello user!',
					type: 'warning'
				});
			}
		},
		clearError(name) {
			this.errors[name] = ''
		},
		toggleAddress() {
			this.form.address_id = null
			this.createAddress = !this.createAddress
		},
		validForm() {
			let vm = this
			let valid = true

			let invalid = () => {
				if(valid) {
					valid = false
				}
			}

				
			if(!this.form.first_name) {
				this.errors.first_name = 'Es requerido'
				invalid()
			}
			if(!this.form.last_name) {
				this.errors.last_name = 'Es requerido'
				invalid()
			}
			if(!this.form.email) {
				this.errors.email = 'Es requerido'
				invalid()
			} else if(!this.validateEmail(this.form.email)) {
				this.errors.email = 'Es invalido'
				invalid()
			}
			if(!this.form.phone) {
				this.errors.phone = 'Es requerido'
				invalid()
			}
			if(this.product.type == 'elektra') {
	
	
				if(!this.form.address_id) {
					let keys = [
						'receiver',
						'city',
						'state',
						'postal_code',
						'number',
						'street',
						'neighborhood',
					]
	
					keys.forEach(key => {
						if(!vm.form[key]) {
							this.errors[key] = 'Es requerido'
							invalid()
						}
					})
				}
			} else {
				if(this.user.has_card) {
					if(!this.pickup_selected.length && this.typeCard == 'new') {
						this.errors.pickup_selected = 'Es requerido'
						invalid()
					}
					if(!this.form.number_card && this.typeCard == 'recharge') {
						this.errors.number_card = 'Es requerido'
						invalid()
					}
					if((this.form.number_card != this.form.number_card_confirm) && this.typeCard == 'recharge') {
						this.errors.number_card_confirm = 'No coinciden'
						invalid()
					}
					if((this.form.number_card.length != 19) && this.typeCard == 'recharge') {
						this.errors.number_card = 'Debe contener 16 dígitos'
						invalid()
					}
				}
			}
			

			return valid
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		setPostalCode() {
			
			let vm = this
			let pc = this.currentPostalCode
			vm.form.neighborhood = `${pc.type_suburb} ${pc.suburb}`
			vm.form.state =  pc.state
			vm.form.city =  pc.town
		},
		setAddress(id) {
			this.form.address_id = id
		},
		getShippingCost(price) {
			return 0
			if(price <= 1000) {
				return 1;
			} else if(price <= 5000) {
				return 2;
			} else {
				return 3;
			}
		},
	
		exchange(price) {
			let shippingCost = this.getShippingCost(price)
			return Math.ceil(price / 102) + shippingCost
		},
		upOrder() {
			this.$refs.upOrder.open()
		},
		confirmOrder() {
			let vm = this
			this.$refs.alertNowOrderModal.close()
			// vm.$refs.waitFiveModal.close()
			if(!this.validForm()) {
				return
			}

			let lastRewards = localStorage.getItem('lastRewards')

			if(lastRewards) {
				lastRewards = JSON.parse(lastRewards)
			} else {
				lastRewards = []
			}

			let inLastFive = lastRewards.findIndex(l => {
				return l.sku == vm.product.sku
			})


			if(inLastFive >= 0) {
				let date =  vm.$moment(lastRewards[inLastFive].date).add(5, 'minutes')
				let now = vm.$moment()
				if(date.isAfter(now)) {
					vm.waitTime = vm.$moment(date.diff(now)).format("mm:ss")
					
					this.time = setInterval(function () {
						let now = vm.$moment()
						vm.waitTime = vm.$moment(date.diff(now)).format("mm:ss")
						if(vm.waitTime == "00:00") {
							clearInterval(vm.time)
						}
					}, 1000)
					vm.$refs.waitFiveModal.open()
					return
				} else {
					lastRewards[inLastFive].date = vm.$moment().toDate()
					vm.$refs.waitFiveModal.close()
				}
			} 

			this.$refs.confirmOrderModal.open()
		},
		alertNowOrder() {
			if(!this.validForm()) {
				return
			}
			this.$refs.alertNowOrderModal.open()
		},
		limitText (count) {
		return `and ${count} other countries`
		},
		
	
		clearAll () {
			this.pickup_selected = []
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
		}),
		...mapMutations({
			setWallet: 'auth/setWallet',
			setProduct: 'vault/setProduct',
			updatePrice: 'vault/updatePrice'
		}),

		asyncFind: _.debounce(
			function(query) {
				let vm = this
				let params = {}
				if(query) {
					params.q = query
				}
				try {
					let reponse = http.agencies.ektFind(params).then(res => {
						vm.$set(vm, 'ekts', res)
					})
				} catch (error) {
					
				}
			},
		300),
		
		
		
	},
	mounted() {
		let vm = this

		if(vm.user) {
			vm.form.email = vm.user.email
			vm.form.phone = vm.user.phone
			vm.form.first_name = vm.user.first_name || ''
			vm.form.last_name = vm.user.last_name || ''
		}
		this.fetchUser().then(res => {
			vm.form.first_name = vm.user.first_name
			vm.form.last_name = vm.user.last_name
			vm.form.email = vm.user.email
			vm.form.phone = vm.user.phone
		})
		// this.getStates()

		if(!this.product) {
			this.$router.push({name: 'Vault'})
		}

		if(this.product.type == 'card_replacement') {
			this.typeCard = 'new'
		}

		if(['card', 'card_replacement'].includes(this.product.type)) {
			this.asyncFind()
		}
	},
	beforeDestroy() {
		this.setProduct(null)
	}
}
</script>
<template>
	<div class="elektra-up-order elektra-scroll-y" v-if="user && product">
		<div  v-if="!success" class="elektra-up-order__header">
            <div class="elektra-up-order__header-content">
                <h1 class="elektra-up-order__header-title">CAJE DE PREMIO</h1>
            </div>
            <div class="elektra-up-order__wallet">
                <div class="elektra-up-order__wallet-wrap">
                    <img class="elektra-up-order__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <p  class="elektra-up-order__wallet-balance">{{ wallet.balance }}</p>
                </div>
            </div>
        </div>
		<modal-vue ref="waitFiveModal">
			<template v-slot:header>
				<h5 class="m-0">Espera  para volver a canjear</h5>
			</template>
			<template v-slot:body>
				<p class="text-center">¿Deseas volver a canjear este premio?</p>
				<div v-if="product.type == 'elektra'"  class="vault__reward" :style="{
					'animation-delay': '0s'
				}">
					<div class="vault__reward-content">
						<div class="vault__reward-image col-4 p-0">
							<img :src="forceHTTPS(product.elektra.ImageUrl)" alt="">
						</div>
						<div class="vault__reward-name col-8 py-2">
							<p class="m-0">{{product.elektra.ProductName}}</p>
						</div>
					</div>
				</div>
				<div v-else  class="vault__reward" :style="{
					'animation-delay': '0s'
				}">
					<div class="vault__reward-content">
						<div class="vault__reward-image col-4 p-0">
							<img :src="product.img" alt="">
						</div>
						<div class="vault__reward-name col-8 py-2">
							<p class="m-0">{{product.name}}</p>
						</div>
					</div>
				</div>
				<p v-if="waitTime != '00:00'" class="m-0 text-center">Por favor espera  antes de canjear nuevamente este premio para confirmar que deseas canjearlo.</p>
				<p v-else class="text-center m-0">Listo! ya puedes continuar con el canje</p>

				<div v-if="product.type == 'elektra'" class="py-2 d-flex">
					<button class="elektra-btn py-2 me-2 ms-auto" @click="$refs.waitFiveModal.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary py-2 fw-bold me-auto" :disabled="waitTime != '00:00'" @click="confirmOrder">
						<span v-if="waitTime != '00:00'">Espera {{waitTime}}</span>
						<span v-else>
							Continuar
						</span>
					</button>
				</div>
				<div v-else class="py-2 d-flex">
					<button class="elektra-btn py-2 me-2 ms-auto" @click="$refs.confirmOrderModal.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary py-2 fw-bold me-auto" @click="confirmOrder">
						<span v-if="waitTime != '00:00'">Espera {{waitTime}}</span>
						<span v-else>
							Continuar
						</span>
					</button>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="confirmOrderModal">
			<template v-slot:header>
				<h5 class="m-0">Confirmar canje</h5>
			</template>
			<template v-slot:body>
				<p class="text-center">¿Seguro deseas canjear este premio?</p>
				<ProductItem :product="product" :buy="false"/>
				<p class="text-center" v-if="product.type != 'elektra' && product.type != 'card_nip'">
					Al realizar el canje de sus gemas por tarjetas de efectivo, Ud. está aceptando y es consiente que el envío de tarjetas plásticas puede tardar de 5 y hasta 6 semanas en llegar al domicilio que Ud. seleccionó. En el caso de haber realizado un canje para recargar una tarjeta que ya tiene en sus manos, por haber hecho previamente un canje, Ud. acepta que los depósitos no son inmediatos y no tienen un plazo fijo para ser depositados. Estos depósitos puede tardar días e incluso semanas. Por favor espere nuestra notificación vía whatsapp sobre el día y hora para la disposición de su saldo. Muchas gracias por su atención
				</p>
				<h6 v-if="product.type == 'elektra'" class="text-center">Se descontarán <span class="text-primary fw-bold">E{{ exchange(product.elektra.Price)}}</span>  no reembolsables</h6>
				<h6 v-else class="text-center">Se descontarán <span class="text-primary fw-bold">E{{ product.gems}}</span>  no reembolsables</h6>
				
				<div v-if="product.type == 'elektra'" class="py-2 d-flex">
					<button class="elektra-btn py-2 me-2 ms-auto" @click="$refs.confirmOrderModal.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary py-2 fw-bold me-auto" @click="simulateOrder">Si canjear 
						<span class="d-none d-md-inline-block">
							por E{{ exchange(product.elektra.Price)}}
						</span>
					</button>
				</div>
				<div v-else class="py-2 d-flex">
					<button class="elektra-btn py-2 me-2 ms-auto" @click="$refs.confirmOrderModal.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary py-2 fw-bold me-auto" @click="createOrder(false)">Si canjear 
						<span class="d-none d-md-inline-block">
							por E{{ product.gems}}
						</span>
					</button>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="alertNowOrderModal">
			<template v-slot:header>
				<h5 class="m-0">Confirmar canje</h5>
			</template>
			<template v-slot:body>
				<p class="text-center">Ya ordenastes este premio hoy ¿Seguro deseas volver a canjear este premio?</p>
				<ProductItem :product="product" :buy="false"/>
				<h6 class="text-center"></h6>
				<div class="py-2 d-flex">
					<button class="elektra-btn py-2 me-2 ms-auto" @click="$refs.alertNowOrderModal.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary py-2 fw-bold me-auto" @click="confirmOrder">Si continuar</button>
				</div>
			</template>
		</modal-vue>
		<div v-if="success" class="elektra-up-order__success px-4">
			
			<div class="elektra-up-order__success-wrap p-3 my-auto py-5">
				<!-- <h4 class="m-0 text-center"><span class="fw-bold text-primary">¡Felicidades!</span> Has canjeado el premio</h4> -->
				<div>
					<ProductItem :product="product" :buy="false"/>
				</div>
				<div class="d-flex">
					<div class="elektra-up-order__wallet">
						<div class="elektra-up-order__wallet-wrap">
							<img class="elektra-up-order__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
							<p  class="elektra-up-order__wallet-balance">{{ wallet.balance }}</p>
						</div>
					</div>
					<div class="elektra-up-order__wallet-wrap ms-1">
						<p class="elektra-up-order__wallet-text">saldo canjeables en la bóveda baz</p>
					</div>
				</div>
				<div class="my-3">
					<div class="elektra-up-order__notice text-center">
						<h2>¡Felicidades!</h2>
						<h6>Haz canjeado el premio</h6>
						<p v-if="product.type == 'elektra'" class="text-center m-0">¡Tu premio será entregado en un lapso de 2 a 3 semanas!</p>
						<p v-else class="text-center m-0">¡Tu premio será entregado en un lapso de 5 a 6 semanas!</p>
					</div>
				</div>
				<div class="text-center">
					<router-link class="py-2 elektra-btn py-2  mx-auto fw-bold" :to="{name: 'Rewards'}">VER PREMIOS CANJEADOS</router-link>
				</div>
			</div>
		</div>
		<div class="px-4 mt-2" v-else-if="!loading">
			<div>
				<ProductItem :product="product"/>
			</div>
			<div v-if="product.type == 'card'" class="mt-3">
				<div v-if="user.has_card && !typeCard">
					<h5 class="text-white text-center">Parece que tienes una tarjeta de regalo</h5>
					<h6 class="text-white text-center">Selecciona una opción</h6>
					<div class="d-flex">
						<button class="w-50 elektra-btn elektra-btn--secondary py-2 fw-bold me-auto me-2" @click="setTypeCard('recharge')">Recargar tarjeta</button>
						<button class="w-50 btn btn--rounded btn-success text-white fw-bold me-auto ms-2" @click="setTypeCard('new')">Nueva tarjeta</button>
					</div>
				</div>
				<div v-else-if="user.has_card && typeCard == 'recharge'" class="col-12 py-2">
					
					<div v-if="user.has_card" class="text-center pb-3">
						<button class="btn btn--rounded btn-danger text-white fw-bold me-auto me-2" @click="setTypeCard('')">Cambiar opción</button>
					</div>

					<div v-if="!you_has_card">
						<h6 class="text-white text-center">¿Cuenta con una tarjeta donde ha recibido su saldo y esta tarjeta está en sus manos ahora?</h6>
						<div class="d-flex py-3">
							<button class="w-50 btn btn--rounded btn-success text-white fw-bold me-auto me-2" @click="you_has_card =  true">SI</button>
							<button class="w-50 btn btn--rounded btn-danger text-white fw-bold me-auto ms-2" @click="setTypeCard('new')">NO</button>
						</div>
					</div>
					<div v-if="you_has_card && !you_has_nip">
						<h6 class="text-white text-center">¿Cuenta con el NIP de la tarjeta que tiene en sus manos?</h6>
						<div class="d-flex py-3">
							<button class="w-50 btn btn--rounded btn-success text-white fw-bold me-auto me-2" @click="you_has_nip =  true">SI</button>
							<button class="w-50 btn btn--rounded btn-danger text-white fw-bold me-auto ms-2" @click="setTypeCard('new')">NO</button>
						</div>
					</div>
					
					<div v-else-if="you_has_card && you_has_nip" class="box p-3 px-0">
						<div class="px-3 pt-2">
							<p class="m-0">Datos de la tarjeta.</p>
						</div>
						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Número de tarjeta</label>
							<div class="vault__search-input">
								<input type="text" v-mask="'#### #### #### ####'" v-model="form.number_card" @blur="verifyNumber" @input="clearError('number_card')">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.number_card" >{{errors.number_card}}</p>

						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Confirmar Número de tarjeta</label>
							<div class="vault__search-input">
								<input type="text" v-mask="'#### #### #### ####'" v-model="form.number_card_confirm" @input="() => {clearError('number_card_confirm'), compareCard()}">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.number_card_confirm" >{{errors.number_card_confirm}}</p>
					</div>
				</div>
				<div v-else-if="!user.has_card || (user.has_card && typeCard == 'new')" class="col-12 py-2">
					<div  v-if="user.has_card" class="text-center pb-3">
						<button class="btn btn--rounded btn-danger text-white fw-bold me-auto me-2" @click="setTypeCard('')">Cambiar opción</button>
					</div>
					<div class="box p-3">
						<div class="px-0 py-2">
							<p class="m-0">Selecciona punto de entrega.</p>
						</div>
						<multiselect v-model="pickup_selected" id="ajax"
						selectLabel=""
						deselectLabel=""
						 label="name" track-by="code" placeholder="Buscar PDV  elektra" open-direction="bottom" :options="ekts" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind">
							<template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
							<template slot="clear" slot-scope="props">
							<div class="multiselect__clear" v-if="pickup_selected.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
							</template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
						</multiselect>

						<div v-if="pickup_selected.length" class="alert alert-warning mt-3" role="alert">
							Has seleccionado {{pickup_selected[0].name}} tu tarjeta se entregara en este punto
						</div>

						<div v-if="pickup_selected.length">
							{{pickup_selected[0].name}} ubicado en 
							{{pickup_selected[0].domicile}} 
							ext: {{pickup_selected[0].num_ext}} 
							{{ pickup_selected[0].num_int ? `int: ${pickup_selected[0].num_int}` : `` }}
							calle: {{pickup_selected[0].street_1}}
							calle 2: {{pickup_selected[0].street_2}}
							Colonia: {{pickup_selected[0].neighborhood}}
							Municipio: {{pickup_selected[0].municipality}}
							Localidad: {{pickup_selected[0].town}}
							Estado: {{pickup_selected[0].state}}
							Codigo postal: {{pickup_selected[0].postal_code}}
						</div>
					</div>
				</div>
			</div>
			<div v-if="product.type == 'card_replacement'">
				<div class="box p-3">
					<div class="px-0 py-2">
						<p class="m-0">Selecciona punto de entrega.</p>
					</div>
					<multiselect v-model="pickup_selected" id="ajax"
					selectLabel=""
					deselectLabel=""
						label="name" track-by="code" placeholder="Buscar PDV  elektra" open-direction="bottom" :options="ekts" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true" @search-change="asyncFind">
						<template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
						<template slot="clear" slot-scope="props">
						<div class="multiselect__clear" v-if="pickup_selected.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
						</template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
					</multiselect>

					<div v-if="pickup_selected.length" class="alert alert-warning mt-3" role="alert">
						Has seleccionado {{pickup_selected[0].name}} tu tarjeta se entregara en este punto
					</div>

					<div v-if="pickup_selected.length">
						{{pickup_selected[0].name}} ubicado en 
						{{pickup_selected[0].domicile}} 
						ext: {{pickup_selected[0].num_ext}} 
						{{ pickup_selected[0].num_int ? `int: ${pickup_selected[0].num_int}` : `` }}
						calle: {{pickup_selected[0].street_1}}
						calle 2: {{pickup_selected[0].street_2}}
						Colonia: {{pickup_selected[0].neighborhood}}
						Municipio: {{pickup_selected[0].municipality}}
						Localidad: {{pickup_selected[0].town}}
						Estado: {{pickup_selected[0].state}}
						Codigo postal: {{pickup_selected[0].postal_code}}
					</div>
				</div>
			</div>
			<div class="elektra-box elektra-box--normal mt-3  p-0 pb-3">
				<div class="px-3 py-2 pt-3">
					<p class="m-0">Ingresa tus datos.</p>
				</div>
				<div class="d-flex flex-wrap">
					<div class="col-12">
						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Nombres</label>
							<div class="vault__search-input">
								<input type="text" v-model="form.first_name" @input="clearError('first_name')">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.first_name" >{{errors.first_name}}</p>
					</div>
					<div class="col-12">
						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Apellidos</label>
							<div class="vault__search-input">
								<input type="text" v-model="form.last_name" @input="clearError('last_name')">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.last_name" >{{errors.last_name}}</p>
					</div>
					<div class="col-12">
						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Correo Electronico</label>
							<div class="vault__search-input">
								<input type="email" v-model="form.email" @input="clearError('email')">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.email" >{{errors.email}}</p>
					</div>
					<div class="col-12">
						<div class="vault__search flex-wrap mt-1">
							<label class="w-100 d-block ms-2" for="">Teléfono</label>
							<div class="vault__search-input">
								<input type="number" v-model="form.phone" @input="clearError('phone')">
							</div>
						</div>
						<p class="small px-3 fw-bold text-primary" v-if="errors.phone">{{errors.phone}}</p>
					</div>
				</div>
			</div>
			<div v-if="product.type == 'elektra'">

				<div v-if="(!addresses.length || createAddress) && product.type == 'elektra'" class="mt-3">
					<div class="col-12 py-3 text-center" v-if="addresses.length">
						<button class="py-2 elektra-btn text-uppercase fw-bold" @click="createAddress = !createAddress">Usar una dirección anterior</button>
					</div>
					<div class="box my-3 mt-0 p-0 overflow-hidden"  v-if="product">
						<div class="px-3 py-2 pt-3">
							<p class="m-0">Ingresa los datos de quien recibirá el premio.</p>
						</div>
						<div class="d-flex flex-wrap">
							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Nombre completo</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.receiver" @input="clearError('receiver')">
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.receiver">{{errors.receiver}}</p>
							</div>
							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Calle</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.street" @input="clearError('street')"> 
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.street">{{errors.street}}</p>

							</div>

							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Número casa/apartamento</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.number" @input="clearError('number')">
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.number">{{errors.number}}</p>
							</div>


							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Código postal</label>
									<div class="vault__search-input">
										<input type="number" v-model="form.postal_code" @input="getPostalCode" >
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.postal_code">{{errors.postal_code}}</p>

							</div>


							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Colonia / Barrio</label>
									<div class="vault__search-input">
										<select type="text" v-model="currentPostalCode" @change="setPostalCode" @input="clearError('neighborhood')">
											<option disabled value="">{{ postalCodes.length ? `Selecciona Colonia` : 'Antes ingresa Código postal'}}</option>
											<option :value="pc" v-for="(pc, index) in postalCodes" :key="`pc_${index}_${pc.id}`">{{pc.type_suburb}} {{pc.suburb}}</option>
										</select>
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.neighborhood">{{errors.neighborhood}}</p>
							</div>
							
							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Municipio</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.city" disabled @input="clearError('city')"> 
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.city">{{errors.city}}</p>
							</div>


							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Estado</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.state" disabled @input="clearError('state')">
									</div>
								</div>
								<p class="small px-3 fw-bold text-primary" v-if="errors.state">{{errors.state}}</p>
							</div>


							

							
							
							
							
							<div class="col-12">
								<div class="vault__search flex-wrap mt-1">
									<label class="w-100 d-block ms-2" for="">Referencia (opcional)</label>
									<div class="vault__search-input">
										<input type="text" v-model="form.reference" maxlength="150">
									</div>
								</div>
							</div>

							<div v-if="product.type == 'elektra'" class="col-12 py-3">
								<div class="text-center">
									<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{ exchange(product.elektra.Price)}}</button>
									<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ exchange(product.elektra.Price)}}</button>
									
								</div>
							</div>
							<div v-else class="col-12 py-3">
								<div class="text-center">
									<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{ product.gems}}</button>
									<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ product.gems }}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex flex-wrap" v-else-if="addresses.length && product.type == 'elektra'">
					<h6 class="my-3 text-center col-12 text-uppercase fw-bold text-white">Selecciona dirección</h6>
					<div class="col-12 py-2" v-for="(address, index) in addresses" :key="`address_${index}_${address.id}`">
						<div class="elektra-box  p-3" :class="{'selected': address.id == form.address_id}" @click="setAddress(address.id)">
							<div class="elektra-box__bg"></div>
							<div class="elektra-box__details">
								<h6 class="fw-bold m-0">{{address.label}}</h6>
								<p class="m-0 small">
									<span class="fw-bold">Recibe:</span> {{address.receiver}}
								</p>
								<p class="m-0 small">
									<span class="fw-bold">Direccón:</span> {{address.street}} {{ address.number }} {{ address.neighborhood }} {{ address.postal_code }} {{ address.city }} {{ address.state }}
								</p>
							</div>
						</div>	
					</div>
					<div class="col-12 py-2 text-center">
						<button class="py-2 elektra-btn text-uppercase fw-bold" @click="toggleAddress">Agregar otra dirección</button>
					</div>
					<div class="col-12 py-2 text-center" v-if="form.address_id">
						<div v-if="product.type == 'elektra'">
							<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{ exchange(product.elektra.Price)}}</button>
							<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ exchange(product.elektra.Price)}}</button>
						</div>
						<div v-else>
							<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{product.gems}}</button>
							<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ product.gems}}</button>
						</div>
					</div>
				</div>	
			</div>
			<div v-else-if="['card', 'card_replacement'].includes(product.type)">
				<div v-if="(!user.has_card && pickup_selected.length) || (typeCard == 'new' && pickup_selected.length) || ((typeCard == 'recharge' && form.number_card && (form.number_card_confirm == form.number_card ) && form.number_card.length == 19))" class="col-12 py-2 text-center">
					<div>
						<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{product.gems}}</button>
						<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ product.gems}}</button>
					</div>
				</div>
			</div>
			<div v-else-if="['card_nip'].includes(product.type)">
				<div  class="col-12 py-2 text-center">
					<div>
						<button v-if="!user.order_now.includes(product.sku)" class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="confirmOrder">Canjear premio por  E{{product.gems}}</button>
						<button v-else class="py-2 elektra-btn py-2 elektra-btn--secondary fw-bold" @click="alertNowOrder">Canjear premio por  E{{ product.gems}}</button>
					</div>
				</div>
			</div>
			
		</div> 
		<div v-else class="px-2">
			<div class="elektra-up-order__logo-wrap">
                <img class="elektra-up-order__logo" src="/elektrizate/iso_elektrizate.svg" alt="">
                <img class="elektra-up-order__sun" src="/elektrizate/sun.svg" alt="">
                <img class="elektra-up-order__sun2" src="/elektrizate/sun.svg" alt="">
            </div>
			
			<div class="box p-3">
				<h6 class="m-0 text-center">Canjeando premio...</h6>
			</div>
		</div>
		
	</div>
</template>

<style lang="scss" scoped>
.text-primary{
	color: #6700ce !important;
}
.vault__search-input{
	background: #6700ce !important;
}
.elektra-up-order{
	background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 170px;
	color: #000;
	&__header{
        padding-top: 60px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 50px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 4rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
	&__success{
		padding-top: 60px;
		height: calc(100vh - 170px);
		display: flex;
		&-wrap{
			background: rgba($color: #fff, $alpha: 0.7);
			border-radius: 4rem;
		}
		.elektra-up-order__wallet{
			position: relative;
			top: auto;
			left: auto;
			bottom: auto;
			transform: none;
			margin: 0;
			&-text{
				font-size: 0.7rem;
				margin: 0;
			}
		}
	}
	&__notice{
		background-color: #c9ff66;
		border-radius: 3rem 3rem 0 3rem;
		padding: 2rem 2rem;
		padding-right: 5rem;
		margin-right: -15%;
		position: relative;
		&::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            bottom: -49px;
            right: 0;
            background: url('/elektrizate/notice_bottom_decoration.png');
            background-size: 100% 100%;
        }
		h2{
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
		}
		h6{
			color: #6700ce;
			font-weight: 600;
		}
		p{
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
	&__logo{
        max-width: 70%;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        left: 15%;
		top: 5%;
        z-index: 2;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}

.elektra-box.selected{
	.elektra-box__bg{
		box-shadow: 4px 4px 0 4px #fff;
		background-color: #b0f566;
	}
}
</style>