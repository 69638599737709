
export const user = (state) => {
	return state.user
}

export const addresses  = (state) => {
	return state.user.addresses
}

export const hasUpdate  = (state) => {
	return state.hasUpdate
}

export const token = (state) => {
	return state.token
}

export const wallet = (state) => {
	return state.wallet
}

export const notifications = (state) => {
	return state.notifications
}

export const newNotifications = (state) => {
	return state.notifications.filter(n => !n.viewed)
}

export const loginFailed = (state) => {
	return state.loginFailed
}

export const badge = (state) => {
	if(!state.user) return null

	return state.user.badge
}

export const materials = (state) => {
	return state.materials.filter(m => m.roles.includes(state.user.role_id))
}

export const material = (state) => {
	return state.material
}


export const intro = (state) => {
	return state.materials.find(m => {
		return m.roles.includes(state.user.role_id) && m.is_intro
	})
}

export const intros = (state) => {
	let materialsViewed = localStorage.getItem('materials_viewed')
	if(!materialsViewed) {
		materialsViewed = []
	} else {
		materialsViewed = JSON.parse(materialsViewed)
	}
	return state.materials.filter(m => {
		return m.roles.includes(state.user.role_id) && m.is_intro && !materialsViewed.includes(m.id)
	})
}

