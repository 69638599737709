<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        name: {
            type: String,
            default() {
                return 'Producto'
            }
        },
        price: {
            type: Number,
            default() {
                return 215
            }
        },
        image: {
            type: String,
            default() {
                return ''
            }
        },
        buy: {
            type: Boolean,
            default() {
                return true
            }
        }
    },
}
</script>
<template>
    <div class="elektra-product animate__animated animate__fadeInUp">
        <div class="elektra-product-bg"></div>
        <div class="elektra-product-details">
            <div>
                <h2 class="elektra-product-title">{{ name }}</h2>
                <div v-if="buy">
                    <button class="elektra-btn elektra-btn--secondary font-weight-bold">{{ price }} elektrones</button>
                </div>
                <div v-else>
                    <p class="elektra-product-price">Disponible por E{{ price }}</p>
                </div>
            </div>
            <div class="elektra-product-picture">
                <img :src="image" alt="">
            </div>
        </div>
                
    </div>
</template>

<style lang="scss" scoped>
.elektra-btn{
    white-space: nowrap;
}
.elektra{
    &-product{
        padding: 1rem 1rem;
        position: relative;
        max-width: 320px;
        margin: 0 auto;
        margin-bottom: 0;
        opacity: 0;
        &-bg{
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 2rem;
            transform: skew(-12deg, 0deg);
            box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
        }
        &-details{
            position: relative;
            padding-top: 1rem;
            z-index: 2;
            color: #000;
            display: flex;
            align-items: center;
        }
        &-title{
            margin: 0;
            font-size: 0.7rem;
            font-weight: 600;
            margin-bottom: 0.7rem;
        }
        &-ref{
            color: #7100d6;
            font-size: 0.6rem;
            margin-bottom: 0;
        }
        &-picture{
            width: 40%;
            min-width: 40%;
            img{
                width: 100%;
                display: block;
                margin-top: -20%;
            }
        }
        &-price{
            color: #6700ce;
            font-size: 0.8rem;
            margin: 0;
        }
        &-icon{
            width: 20%;
            margin-right: 0.5rem;
            img{
                width: 100%;
                margin-top: -25%;
            }
        }
    }
}
</style>