<script>
import ProductHolder from '../../components/Elektrizate/ProductHolder.vue'
export default {
    components: {
        ProductHolder
    },
    data() {
        return {}
    },
}
</script>
<template>
    <div class="elektra-tour-intro ">
        <div class="elektra-tour-intro__header animate__animated animate__fadeInUp">
            <div class="elektra-tour-intro__header-top">
                <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
            </div>
        </div>
        <div class="elektra-tour-intro__content px-4 animate__animated animate__fadeInUp">
            <div class="elektra-box elektra-box--normal mb-2">
                <div class="elektra-box__content px-1 py-1  relative d-flex align-items-center">
                    <div class="elektra-icon-bonus">
                        <img src="/elektrizate/bonus.svg" alt="">
                    </div>
                    <div class="px-3">
                        <p class="m-0">Gana Elektrones canjeables en la bóveda de premios por las transacciones que el cliente realice en las siguientes 4 semanas a partir de su fecha de activación.</p>
                    </div>
                </div>
            </div>

            <div class="my-3">
                <div class="elektra-tour-intro-box px-2 py-4 overflow-hidden">
                    <ProductHolder class="mb-3" name="Almohada Songare Fussion Estándar Suave" :price="215" image="/elektrizate/products/2885192-1200-auto.webp"/>
                    <ProductHolder name="God of War Ragnarok PS4" :price="250" image="/elektrizate/products/3567674-1200-auto.webp"/>
                </div>
            </div>

            <div class="elektra-tour-intro__notice">
                <img class="elektra-tour-intro__notice-icon" src="/elektrizate/recarga.svg" alt="">
                <div>
                    <p class="m-0">Avísale a tus clientes que:</p>
                    <p class="m-0 fw-bold">¡baz es la única superapp que por pagar, te hace ganar!</p>
                </div>
            </div>


            <div class="text-center mt-3 d-flex justify-content-center">
                <router-link class="elektra-btn elektra-btn--md me-2" :to="{name: 'ElektrizateTourIntro'}">Anterior</router-link>
                <router-link class="elektra-btn elektra-btn--md" :to="{name: 'ElektrizateTourMenu'}">Siguiente</router-link>
            </div>
        </div>

    </div>
</template>
<style lang="scss" scoped>
.elektra-icon-bonus{
    width: 60px;
    min-width: 60px;
}
.elektra-box{
    border-radius: 1rem;
    font-size: 0.9rem;
    h5{
        font-size: 0.8rem;
        font-weight: 600;

    }
    p{
        font-size: 0.8rem;
        text-align: justify;
    }
}
.elektra-btn--lg {
    width: 55%;
}
.elektra-tour-intro-box{
    background-color: rgba($color: #000000, $alpha: 0.7);
    border: 2px solid #bcff3f;
    border-radius: 2rem;
}
.elektra-start{
    color: #fff;
    background-image: url('/elektrizate/start_bg.png');
    position: absolute;
    height: 100vh;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size:100% 100%;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.elektra-tour-intro{
    display: flex;
    flex-direction: column;
    &__notice{
        background: #bcff3f;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        display: flex;
        color: #000;
        font-size: 0.8rem;

        &-icon{
            width: 65px;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    &__battery{
        position: absolute;
        width: 40px;
        right: 12%;
        top: -27px;
    }
    &__badge{
        width: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: -40%;
    }
    &__iso{
        position: relative;
        z-index: 3;
        width: 100%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header{
        &-top{
            position: relative;
            margin-bottom: 1.5rem;
        }
    }
    &__button{
        text-align: center;
        padding-bottom: 2rem;
    }
    &__up{
        height: 45vh;
        flex-grow: 1;
        position: relative;
    }
    &__down{
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__logo{
        max-width: 60%;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        bottom: 45%;
        z-index: 2;
        display: block;
        margin-left: auto;
        margin-right: auto;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
            top: -10%;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }

   
}
</style>
