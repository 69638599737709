import Vue from 'vue'
import VueRouter from 'vue-router'
import ElektrizateStart from '../views/Elektrizate/StartView'
import ElektrizateHome from '../views/Elektrizate/HomeView.vue'
import ElektrizateExplorer from '../views/Elektrizate/ExplorerView.vue'
import ElektrizateVault from '../views/Elektrizate/VaultView.vue'
import ElektrizateVaultDetail from '../views/Elektrizate/VaultDeatil.vue'
import ElektrizateUpOrder from '../views/Elektrizate/UpOrder.vue'
import ElektrizateRewards from '../views/Elektrizate/Rewards.vue'
import ElektrizateNotifications from '../views/Elektrizate/Notifications.vue'
import ElektrizateHelp from '../views/Elektrizate/Help.vue'
import ElektrizateMaterial from '../views/Elektrizate/Material.vue'
import ElektrizateTour from '../views/Elektrizate/Tour.vue'
import ElektrizateTourIntro from '../views/Elektrizate/TourIntro.vue'
import ElektrizateTourRewards from '../views/Elektrizate/TourRewards.vue'
import ElektrizateTourMenu from '../views/Elektrizate/TourMenu.vue'
import ElektrizateTourRules from '../views/Elektrizate/TourRules.vue'
import ElektrizateTourTerms from '../views/Elektrizate/TourTerms.vue'
import ElektrizateTourChat from '../views/Elektrizate/TourChat.vue'
import ElektrizateTourEvaluation from '../views/Elektrizate/TourEvaluation.vue'
import ElektrizateValidation from '../views/Elektrizate/Validation.vue'
import ElektrizateAcceptTerms from '../views/Elektrizate/AcceptTerms.vue'
import Score from '../views/Score.vue'
import Vault from '../views/Vault.vue'
import VaultDetails from '../views/VaultDetails.vue'
import UpOrder from '../views/UpOrder.vue'
import Menu from '../views/Menu.vue'
import Help from '../views/Help.vue'
import Rewards from '../views/Rewards.vue'
import Download from '../views/Download.vue'
import RegionalRanking from '../views/RegionalRanking.vue'
import SessionFail from '../views/SessionFail.vue'
import VersionFail from '../views/VersionFail.vue'
import ErrorApp from '../views/ErrorApp.vue'
import TerritorialRanking from '../views/TerritorialRanking.vue'
import AgencyRanking from '../views/AgencyRanking.vue'
import LeaderRegionRanking from '../views/LeaderRegionRanking.vue'
import LeaderTerritoryRanking from '../views/LeaderTerritoryRanking.vue'
import CorpTerritoryRanking from '../views/CorpTerritoryRanking.vue'
import CorpRegionRanking from '../views/CorpRegionRanking.vue'
import Notifications from '../views/Notifications.vue'
import Support from '../views/Support.vue'
import Material from '../views/Material.vue'
import HistoryActivations from '../views/HistoryActivations.vue'
import Maintenance from '../views/Maintenance.vue'
import middlewares from '../middleware'

import NewTicketSupport from '../views/NewTicketSupport.vue'
import MessagesTicket from '../views/MessagesTicket.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Maintenance',
  //   component: Maintenance,
  // },
  // {
  //   path: '/*',
  //   name: '404',
  //   redirect: '/'
  // },
  {
    path: '/tour',
    name: 'ElektrizateTour',
    component: ElektrizateTour,
    meta: {
      menu: null,
      headerInterface: false
    },
    children: [
      {
        path: '/tour/intro',
        name: 'ElektrizateTourIntro',
        component: ElektrizateTourIntro,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/rewards',
        name: 'ElektrizateTourRewards',
        component: ElektrizateTourRewards,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/menu',
        name: 'ElektrizateTourMenu',
        component: ElektrizateTourMenu,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/rules',
        name: 'ElektrizateTourRules',
        component: ElektrizateTourRules,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/terms',
        name: 'ElektrizateTourTerms',
        component: ElektrizateTourTerms,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/chat',
        name: 'ElektrizateTourChat',
        component: ElektrizateTourChat,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
      {
        path: '/tour/evaluation',
        name: 'ElektrizateTourEvaluation',
        component: ElektrizateTourEvaluation,
        meta: {
          menu: null,
          headerInterface: false
        },
      },
    ]
  },
  {
    path: '/',
    name: 'ElektrizateValidation',
    component: ElektrizateValidation,
    meta: {
      menu: null,
      headerInterface: false
    }
  },
  {
    path: '/our/terms',
    name: 'ElektrizateAcceptTerms',
    component: ElektrizateAcceptTerms,
    meta: {
      menu: null,
      headerInterface: false
    }
  },
  {
    path: '/start',
    name: 'Start',
    component: ElektrizateStart,
    meta: {
      menu: null,
      headerInterface: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: ElektrizateHome,
    meta: {
      middleware: [middlewares.auth],
      menu: 'home',
      headerInterface: true
    }
  },
  {
    path: '/explorer',
    name: 'Explorer',
    component: ElektrizateExplorer,
    meta: {
      middleware: [middlewares.auth],
      menu: 'explorer',
      headerInterface: true

    }
  },
  {
    path: '/vault',
    name: 'Vault',
    component: ElektrizateVault,
    meta: {
      middleware: [middlewares.auth],
      menu: 'explorer',
      headerInterface: true

    }
  },
  {
    path: '/vault/:sku',
    name: 'VaultDetails',
    component: ElektrizateVaultDetail,
    meta: {
      middleware: [middlewares.auth],
      menu: 'explorer',
      headerInterface: true

    }
  },

  {
    path: '/order',
    name: 'UpOrder',
    component: ElektrizateUpOrder,
    meta: {
      middleware: [middlewares.auth],
      menu: 'explorer',
      headerInterface: true

    }
  },

  {
    path: '/rewards',
    name: 'Rewards',
    component: ElektrizateRewards,
    meta: {
      middleware: [middlewares.auth],
      menu: 'explorer',
      headerInterface: true

    }
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: ElektrizateNotifications,
    meta: {
      middleware: [middlewares.auth],
      menu: 'notifications',
      headerInterface: true

    }
  },

  {
    path: '/help/:tab?',
    name: 'Help',
    component: ElektrizateHelp,
    meta: {
      middleware: [middlewares.auth],
      menu: 'other',
      headerInterface: true
    }
  },

  {
    path: '/material',
    name: 'Material',
    component: ElektrizateMaterial,
    meta: {
      middleware: [middlewares.auth],
      menu: 'other',
      headerInterface: true
    }
  },
  
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/new-tickets-support',
    name: 'NewTicketSupport',
    component: NewTicketSupport,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/messages-ticket/:ticket_id',
    name: 'MessagesTicket',
    component: MessagesTicket,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  
  
  
  
  {
    path: '/version',
    name: 'VersionFail',
    component: VersionFail,
    
  },
  {
    path: '/errorapp',
    name: 'ErrorApp',
    component: ErrorApp,
    
  },
  {
    path: '/fail',
    name: 'SessionFail',
    component: SessionFail,
  },

  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
	meta: {
		middleware: [middlewares.auth]
	}
  },
  {
    path: '/help/:tab?',
    name: 'Help',
    component: Help,
	meta: {
		middleware: [middlewares.auth]
	}
  },
  
  {
    path: '/ranking/regional',
    name: 'RegionalRanking',
    component: RegionalRanking,
	meta: {
		middleware: [middlewares.auth]
	}
  },
  
  {
    path: '/ranking/territorial',
    name: 'TerritorialRanking',
    component: TerritorialRanking,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/ranking/agency/:id?',
    name: 'AgencyRanking',
    component: AgencyRanking,
	meta: {
		middleware: [middlewares.auth]
	}
  },
  {
    path: '/ranking/region/leader',
    name: 'LeaderRegionRanking',
    component: LeaderRegionRanking,
	meta: {
		middleware: [middlewares.auth]
	}
  },
  {
    path: '/ranking/territory/leader',
    name: 'LeaderTerritoryRanking',
    component: LeaderTerritoryRanking,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/ranking/territory/corp/:id',
    name: 'CorpTerritoryRanking',
    component: CorpTerritoryRanking,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/ranking/region/corp/:id',
    name: 'CorpRegionRanking',
    component: CorpRegionRanking,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/history/activations/:id',
    name: 'HistoryActivations',
    component: HistoryActivations,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  
  {
    path: '/',
    name: 'Download',
    component: Download
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
	document.querySelector('html').scrollTop = 0
    if(to.name != from.name) {
        // sto're.commit('status/showLoadingScreen')
    }

    if (to.meta.middleware) {
        const middlewareArr = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context = {
            from,
            next,
            router,
            to,
        };

        const nextMiddleware = nextFactory(context, middlewareArr, 1)
        return middlewareArr[0]({ ...context, next: nextMiddleware })
    }

    return next();

});

router.afterEach((to, form, next)=> {
	document.querySelector('html').scrollTop = 0
}) 

function nextFactory (context, middlewareArr, index) {
    const subsequentMiddleware = middlewareArr[index]

    if (!subsequentMiddleware) {
      return context.next
    }

    return (param) => {
        if (param !== undefined) {
            return context.next(param)
        }
        const nextMiddleware = nextFactory(context, middlewareArr, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }

}

export default router
