<script>
import { mapActions, mapGetters } from 'vuex'
import ChallengesTransactions from '../ChallengesTransactions.vue'
export default {
    components: {
        ChallengesTransactions,
    },
    props: {
        challenge: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            loading: false,
            userChallenges: [],
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        paramPage() {
            let params = {
                size: 15,
                challenge_id: this.challenge.id,
                page: 1
            }
            if(this.statsHistoryTransactions) {
                params.page = this.statsHistoryTransactions.current_page + 1
            }
            return params
        },
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet',
            'statsHistoryTransactions': 'stats/historyTransactions'
        })
    },
    methods: {
        async loadMore() {
            try {
                this.loading = true
                await this.statsFetchHistoryTransactions(this.paramPage)
            } catch (error) {
                
            }
            this.loading = false
        },
        scrollEnd(e) {
            if(this.statsHistoryTransactions && !this.loading) {
                if(this.statsHistoryTransactions.current_page < this.statsHistoryTransactions.last_page) {
                   this.scrollBottomEnd(e, this.loadMore)
                }
            }
        },
        ...mapActions({
            statsFetchHistoryTransactions: 'stats/fetchHistoryTransactions'
        })
    },
    async mounted() {
        await this.statsFetchHistoryTransactions({...this.paramPage, page: 1})
    }
}
</script>
<template>
     <div class="elektra-explorer elektra-scroll-y" @scroll="scrollEnd">
        <div class="elektra-explorer__header">
            <div class="elektra-explorer__wallet">
                <div class="elektra-explorer__wallet-wrap">
                    <img class="elektra-explorer__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <p class="elektra-explorer__wallet-balance">{{ authWallet.balance }}</p>
                </div>
                <div class="elektra-explorer__user">
                    <p class="elektra-explorer__user-name">{{ authUser.name }}</p>
                    <p class="elektra-explorer__user-workposition">{{ authUser.work_position }}</p>
                </div>
            </div>
            <div v-if="statsHistoryTransactions && statsHistoryTransactions.data.length" class="d-flex  align-items-center mt-3 px-3">
                <div>
                    <h1 class="elektra-title">Tus activaciones están cumpliendo desafíos</h1>
                    <p class="m-0 small">Llena la batería para estar a full</p>
                </div>
                <div class="elektra-explorer__battery">
                    <img src="/elektrizate/battery.svg" alt="">
                </div>
            </div>
            <div class="mt-3 text-center" v-else>
                <div class="mb-3">
                    <p class="m-0 small">Tus activaciones aun no completan desafíos</p>
                </div>
                <ChallengesTransactions :challenges="referalChallenges" />
            </div>
        </div>
        <div class="elektra-explorer__list" v-if="statsHistoryTransactions && statsHistoryTransactions.data.length">
            <div class="elektra-explorer__list-item mb-3" v-for="(transaction, index) in statsHistoryTransactions.data" :key="`history-${index}-${transaction.id}`">
                <template v-if="!authUser.is_leader">
                    <div class="elektra-explorer__list-item-bg"></div>
                    <div class="elektra-explorer__list-item-content">
                        <div class="d-flex elektra-explorer__challenge-wrap">
                            <div class="elektra-explorer__challenge-icon">
                                <img :src="`/elektrizate/types/${transaction.type}.svg`" alt="">
                            </div>
                            <div>
                                <h1 class="elektra-explorer__list-item-title">{{transaction.type}}</h1>
                                <p class="elektra-explorer__list-item-ref">{{ transaction.external_id }}</p>
                            </div>
                            <div class="elektra-explorer__challenge-bonus" v-if="transaction.amount">
                                <span class="elektra-explorer__challenge-amount">+{{ transaction.amount }}</span>
                                <img class="elektra-explorer__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                            </div>
                            <p class="elektra-explorer__list-item-date">{{ transaction.created_at | moment("DD [de] MMMM h:mm A") }}</p>
                        </div>
                       
                    </div>
                </template>
                <template v-else>

                    <div class="elektra-explorer__list-item-bg"></div>
                    <div class="elektra-explorer__list-item-content">
                        <span class="elektra-explorer__list-item-count">{{ transaction.goals }}/3</span>
                        <div class="d-flex elektra-explorer__challenge-wrap">
                            
                            <div class="mb-3">
                                <p class="m-0 elektra-explorer__list-item-text">{{ transaction.user.name }}</p>
                                <p class="elektra-explorer__list-item-ref2">{{ transaction.user.code }} | {{ transaction.user.territory.name }} | {{ transaction.user.region.name }} | {{ transaction.user.agency.name }}</p>
                            </div>
                            <div class="elektra-explorer__challenge-bonus" v-if="transaction.leaders_paid">
                                <span class="elektra-explorer__challenge-amount">+1</span>
                                <img class="elektra-explorer__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                            </div>
                            <p class="elektra-explorer__list-item-date">{{ transaction.created_at | moment("DD [de] MMMM h:mm A") }}</p>
                        </div>
                       
                    </div>
                </template>
            </div>
            <p class="small text-center" v-if="loading">Cargando...</p>
        </div>
        <div class="elektra-explorer__notice">
            <router-link class="elektra-btn elektra-btn--md" :to="{name: 'Vault'}">VER PREMIOS</router-link>
        </div>
     </div>
</template>
<style lang="scss" scoped>
.text-black{
    color: #000;
}
.elektra-explorer{
    background: #fff;
    height: 100vh;
    color: #000;
    padding-bottom: 170px;
    &__notice{
        background-color: #c9ff66;
        padding: 2rem;
        width: 100%;
        max-width: 420px;
        position: fixed;
        z-index: 99;
        bottom: -100%;
        padding-bottom: 100px;
        text-align: center;
        border-radius: 100px 0 0 0;
        animation: notice 0.5s forwards ease-in-out;
        &::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            top: -49px;
            right: 0;
            background: url('/elektrizate/notice_top_decoration.png');
            background-size: 100% 100%;
        }
    }
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        width: 30%;
        position: relative;
        filter: grayscale(1);
        opacity: 0.6;
        &-wrap{
            display: flex;
            align-items: center;
            position: relative;
        }
        &-icon{
            width: 50px;
            min-width: 50px;
        }
        &-paid{
            filter: grayscale(0);
            opacity: 1;
            .elektra-explorer__challenge-bonus{
                display: flex;
            }
        }
        &-bonus{
            display: flex;
            align-items: flex-end;
            bottom: -30%;
            right:  0;
            width: 25px;
            min-width: 25px;
            position: absolute;
            left: 25px;
            bottom: -15px;
            img{
                width: 100%;
            }
        }
        &-amount{
            color: #00cb00;
            font-weight: bold;
            display: inline-block;
            padding-bottom: 0.5rem;
            font-size: 0.7rem;
            position: absolute;
            left: -10px;
            top: 10px;
        }
    }
    &__list{
        padding: 1rem 2rem;
        &-item{
            position: relative;
            &-count{
                background-color: #7100d6;
                color: #fff;
                font-size: 0.7rem;
                border-radius: 25px;
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0.25rem;
            }
            &-bg{
                background-color: #fff6cc;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 2rem;
                transform: skew(-12deg, 0deg);
                box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
            }
            &-content{
                padding: 1rem 1rem ;
                position: relative;
                z-index: 2;
            }
            &-title{
                margin: 0;
                font-size: 1rem;
                font-weight: 600;
            }
            &-text{
                font-size: 0.9rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }
            &-ref{
                color: #7100d6;
                font-size: 0.6rem;
                margin-bottom: 0;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }
            &-ref2{
                color: #7100d6;
                font-size: 0.6rem;
                margin-bottom: 0;
                display: block;
                width: 100%;
            }
            &-date{
                position: absolute;
                right: 0;
                bottom: -0.5rem;
                padding-right: 0.5rem;
                margin: 0;
                font-size: 0.5rem;
            }
        }
    }
    &__header{
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 50px;
    }
    &__wallet{
        background-color: #f1f1f1;
        padding: 1rem 2rem;
        border-radius: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-wrap{
            display: flex;
            align-items: center ;
        }
        &-iso{
            width: 40px;
            display: block;
            margin: 0 auto;
        }
        &-balance{
            width: 40px;
            color: #7100d6;
            font-weight: 600;
            margin-top: -5px;
            margin-bottom: -5px;

        }
    }
    &__user{
        color: #7100d6;
        &-name{
            margin: 0;
            font-size: 1.1rem;
            text-align: center;
        }
        &-workposition{
            margin: 0;
            font-size: 0.7rem;
            text-align: center;
            font-weight: bold;
        }
    }
    &__battery{
        min-width: 60px;
    }
    @keyframes notice {
        0%{
            bottom: -100%;
        } 
        80%{
            bottom: 12px;
        }
        100%{
            bottom: 0;
        }
    }
}
</style>