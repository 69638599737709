<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import http from '../../http'
import ModalVue from '../../components/Elektrizate/ModalVue.vue'
export default {
	components: {
		ModalVue,
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
				address: '',
				state: '',
				city: '',
			},
			tab: 'materials',
			faqs: [],
			selectedFaq: 0,
			loadingFaqs: false,
		}
	},
	computed: {
		
		...mapGetters({
			user: 'auth/user',
			materials: 'auth/materials'
		})
	},
	watch: {
		'$route.params.tab': {
			handler(nVal) {
				
				if(nVal) {
					this.tab = nVal
				}
			},
			immediate: true
		},
	},
	methods: {	
		async fetchFaqs() {
			let vm = this
			if(!this.faqs.length) {
				this.loadingFaqs = true
			}
			try {
				let reponse = await http.faq.all();
				vm.$set(vm, 'faqs', reponse)
				this.loadingFaqs = false
			} catch (error) {
				this.loadingFaqs = false
			}
		},

		handlerSetMaterial(material) {
			this.setMaterial(material)
			
			this.$router.push({name: 'Material'})
		},
	
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchMaterials: 'auth/fetchMaterials',
		}),

		...mapMutations({
			setMaterial: 'auth/setMaterial'
		})
	}, 
	mounted() {
		let vm = this
		vm.fetchUser().then(() => {
			vm.fetchMaterials()
		})

		vm.fetchFaqs()
	}
}
</script>
<template>
	<div class="elektra-up-order elektra-scroll-y" v-if="user">
		<div  class="elektra-up-order__header">
            <div class="elektra-up-order__header-content">
                <h1 class="elektra-up-order__header-title">AYUDA</h1>
            </div>
        </div>
		
		<div class="px-2 mt-3" v-if="tab == 'materials'">
			<div class="d-flex flex-wrap">
				<div class="col-12 p-1" v-for="(material, index) in materials" :key="`mterial_${index}`">
					<div class="elektra-box elektra-box--normal p-3 py-4">
						<div v-if="material.type == 'video'">
							<h6 class="fw-bold small  mb-3">{{material.title}}</h6>
							<div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="material.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="material.title"></iframe></div>
						</div>
						<div class="d-flex align-items-center" v-if="material.type == 'html'" @click="handlerSetMaterial(material)">
							<div class="menu-item__icon m-0">
								<span></span>
							</div>
							<h6 class="m-0 fw-bold small ms-3">
								{{material.title}}
							</h6>
						</div>
					</div>
				</div>
				<!-- <div class="col-12">
					<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/590431057?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=02f09ac1b4" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="01+Activadores+Juegodegemas_SA&amp;amp;A_2021_V4"></iframe></div>
				</div> -->
				<!-- <div class="col-12 p-1 ">
					<div class="box p-3 h-100" @click="$router.push({name: 'Terms'})">
						<div class="d-flex align-items-center">
							<div class="menu-item__icon m-0">
								<span></span>
							</div>
							<h6 class="m-0 fw-bold small ms-3">
								Terminos y condiciones
							</h6>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="px-2 mt-3" v-else-if="tab == 'faq'">
			<div class="col-12">
				<div class="box p-2">
					<div v-if="!loadingFaqs">

						<div @click="selectedFaq = faq.id" class="mx-n2" :class="{'border-bottom': index + 1 < faqs.length}" v-for="(faq, index) in faqs" :key="`faq_${index}`">
							<div class="py-2 px-2">
								<h6 class="fw-bold m-0">{{faq.question}}</h6>

								<p v-show="faq.id == selectedFaq" class="mt-2 mb-0 px-2">
									{{faq.answer}}
								</p>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="d-flex flex-wrap">
							<div class="mx-auto text-primary">
								<div class="lds-ripple"><div></div><div></div></div>
							</div>
						</div>
						<h6 class="text-center m-0">
							Cargando...
						</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.box{
	border-radius: 1rem;
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
.menu-item__icon{
	margin-bottom: 0.5rem;
	span{
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #36C800;
	}
}

.text-primary{
	color: #6700ce !important;
}
.vault__search-input{
	background: #6700ce !important;
}
.elektra-up-order{
	background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 170px;
	color: #000;
	&__header{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 50px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 1rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
	&__success{
		padding-top: 60px;
		height: calc(100vh - 170px);
		display: flex;
		&-wrap{
			background: rgba($color: #fff, $alpha: 0.7);
			border-radius: 4rem;
		}
		.elektra-up-order__wallet{
			position: relative;
			top: auto;
			left: auto;
			bottom: auto;
			transform: none;
			margin: 0;
			&-text{
				font-size: 0.7rem;
				margin: 0;
			}
		}
	}
	&__notice{
		background-color: #c9ff66;
		border-radius: 3rem 3rem 0 3rem;
		padding: 2rem 2rem;
		padding-right: 5rem;
		margin-right: -15%;
		position: relative;
		&::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            bottom: -49px;
            right: 0;
            background: url('/elektrizate/notice_bottom_decoration.png');
            background-size: 100% 100%;
        }
		h2{
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
		}
		h6{
			color: #6700ce;
			font-weight: 600;
		}
		p{
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
	&__logo{
        max-width: 70%;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        left: 15%;
		top: 5%;
        z-index: 2;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }
}
</style>