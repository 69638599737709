<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../http'
export default {
    data() {
        return {
            activations: [],
            loading: true,
            activator: null
        }
    },
	computed: {
		
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			regionReferals: 'stats/regionReferals',
			leaderRegionReferalsStats: 'stats/leaderRegionReferalsStats',
		})
	},
	methods: {
        async getHistory() {
            let vm = this
            vm.loading = true

            try {
                let response = await http.dailyReferals.byUser(vm.$route.params.id)
                vm.$set(vm, 'activations', response.activations)
                vm.$set(vm, 'activator', response.user)
                vm.loading = false
            } catch (error) {
                console.log(error)
                vm.loading = false
            }
        },

        calcGems(total) {
            return Math.floor(total/5)
        },
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchRegionReferals: 'stats/fetchRegionReferals',
		})
	},
	mounted() {
        this.getHistory()
	}
}
</script>
<template>
	<div class="ranking" >
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<h5 class="m-0 w-100 text-center mb-0">Historial de activaciones</h5>
                    <h6 v-if="activator" class="m-0 w-100 text-center mb-2">{{activator.name}}</h6>
				</div>
			</div>
		</div>

		<div class="ranking__wrappers mt-3 px-2">
			<div class="start--show" v-if="loading || !activations.length">
				<div class="start__top">
					<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				</div>
			</div>
			<div  class="box px-0">
				<div v-if="!loading && activations.length">
                    <div class="d-flex flex-wrap">
                        <div v-for="(activation, index) in activations" :key="`activation_d_${index}`" class="col-12 px-3 py-2 border-bottom">
                            <p class="small text-muted m-0 fw-bold text-center">{{activation.date | moment("DD-MMMM")}}</p> 
                            <div class="d-flex flex-wrap">
                                <div class="m-0 col-4 mt-auto">
                                    <h5 class="m-0 mt-auto fw-bold text-primary d-block text-center">{{activation.total}}</h5>
                                    <span class="fw-bold d-block text-center">Activaciones</span>
                                </div>
                                <div class="mb-0 ms-auto mt-auto col-4">
                                    <h6 v-if="activation.total == 5" class="mb-0 mt-auto fw-bold text-primary d-block text-center">Cumplida</h6>
                                    <h6 v-else-if="activation.total > 5" class="mb-0 mt-auto fw-bold text-primary d-block text-center">Sobrepaso</h6>
                                    <h6 v-else class="mb-0 mt-auto fw-bold d-block text-center text-danger">Fallo</h6>
                                    <span class="fw-bold d-block text-center">Meta</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
				</div>
				<div v-else class="px-2">
                    <div v-if="loading">
                        <h6 class="text-center m-0">Estamos preparando los datos.</h6>
                        <h6 class="text-center m-0 fw-bold">Cargando historial de activaciones</h6>
                    </div>
                    <div v-else-if="!activations.length">
                        <h6 class="text-center m-0">Este activador no ha empezado a activar</h6>
                        <h6 class="text-center m-0 fw-bold">Ayudalo a alcanzar su meta</h6>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>