
export const localProducts = (state) => state.local_products 

export const productBySku = (state) => (sku) => {
	return state.local_products.find(p => p.sku == sku)
}

export const product = (state) => state.product

export const loading = (state) => state.loading

export const currentPage = (state) => state.current_page

export const lastPage = (state) => state.last_page