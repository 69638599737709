<script>
export default {
    data() {
        return {
            clients: [],
            types: [
                {
                    name: 'Recarga de celular',
                    slug: 'TIEMPO-AIRE'
                },
                {
                    name: 'Pago de Servicio',
                    slug: 'PAGO-SERVICIO'
                },
                {
                    name: 'Pago de Credito',
                    slug: 'ABONO-CREDITO'
                },
            ]
        }
    }
}
</script>
<template>
    <div>
        <div v-if="!clients.length" class="">
            <div class="box-diamante px-3 pb-3 pt-3 mb-2 text-center">

                <p class="">Ninguna de tus activaciones ha cumplido alguno de sus desafíos.</p>
                <p class="mb-0">Continúa <span class="text-success fw-bold">activando</span> y <span class="text-success fw-bold">comunicando</span> a tus clientes los desafíos.</p>
            </div>

            <div class="flex">
                <div class="c_item d-flex">
                    <img class="c_item__icon" src="img/TIEMPO-AIRE.svg" alt="">
                    <div class="c_item__info">
                        <h5 class="c_item__title">Recarga celular</h5>
                        <p class="m-0 c_item__text">Cliente gana $30</p>
                    </div>
                    <div class="c_item__gem">
                        <div class="d-flex align-items-center justify-content-center">
                            <h5 class="c_item__title">+1</h5>
                            <img class="c_item__gem" src="img/gem.png" alt="">
                        </div>
                        <div class="c_item__text">Tú ganas</div>
                    </div>
                </div>
                <div class="c_item d-flex">
                    <img class="c_item__icon" src="img/PAGO-SERVICIO.svg" alt="">
                    <div class="c_item__info">
                        <h5 class="c_item__title">Pago de servicios</h5>
                        <p class="m-0 c_item__text">Cliente gana $50</p>
                    </div>
                    <div class="c_item__gem">
                        <div class="d-flex align-items-center justify-content-center">
                            <h5 class="c_item__title">+1</h5>
                            <img class="c_item__gem" src="img/gem.png" alt="">
                        </div>
                        <div class="c_item__text">Tú ganas</div>
                    </div>
                </div>
                <div class="c_item d-flex">
                    <img class="c_item__icon" src="img/ABONO-CREDITO.svg" alt="">
                    <div class="c_item__info">
                        <h5 class="c_item__title">Pago de crédito</h5>
                        <p class="m-0 c_item__text">Cliente gana $30</p>
                    </div>
                    <div class="c_item__gem">
                        <div class="d-flex align-items-center justify-content-center">
                            <h5 class="c_item__title">+1</h5>
                            <img class="c_item__gem" src="img/gem.png" alt="">
                        </div>
                        <div class="c_item__text">Tú ganas</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="box-diamante px-3 pb-3 pt-3 mb-2 text-center">
                <p class="">Tus activaciones estan compliendo desafios.</p>
                <p class="mb-0">Continúa <span class="text-success fw-bold">activando</span> y <span class="text-success fw-bold">comunicando</span> a tus clientes los desafíos.</p>
            </div>
            <div class="referal-item" v-for="(client, index) in clients" :key="`client_${index}_${client.id}`">
                <p class="referal-item__id">Referencia: <span class="text-primary">{{ client.external_id }}</span></p>
                <p class="referal-item__title d-flex">Desafíos cumplidos <span class="referal-item__counter">{{ client.goals }} de {{ types.length }}</span></p>

                <div class="d-flex referal-item__challenges">
                    <div class="referal-item__challenges-wrap" v-for="(type, index) in client.types" :key="`type_${index}_${type.slug}`">
                       <div class="referal-item__challenges-badge">
                           <img v-if="type.completed" class="referal-item__challenges-icon" :src="`img/completed/${type.slug}.svg`" alt="" >
                           <img v-else class="referal-item__challenges-icon" :src="`img/pending/${type.slug}.svg`" alt="" >
                           <span v-if="type.completed" class="referal-item__challenges-bonus">
                              <img class="referal-item__challenges-gem" src="/img/gem.png" alt=""> <span>+</span>
                           </span>
                       </div>
                        <p class="referal-item__challenges-name " :class="type.completed ? 'text-secondary':''">{{ type.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.text-secondary{
    color: #711dd8;
}
.referal-item{
    background: #fff;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: 0 14px 28px rgba(77, 70, 86, 0.1), 0 10px 10px rgba(77, 70, 86, 0.1);
    padding: 0.5rem;
    &__id{
        margin: 0;
        font-size: 0.7rem;
    }
    &__text{
        font-size: 0.8rem;
    }
    &__title{
        color: #4e4d4f;
        font-size: 1rem;
    }
    &__counter{
        color: #fff;
        background-color: #08c908;
        border-radius: 0.5rem;
        padding: 0 0.5rem;
        font-size: 0.9rem;
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    &__challenges{
        display: flex;
        justify-content: space-around;
        width: 100%;
        text-align: center;
        padding: 0 0.5rem;
        &-wrap{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &-name{
            font-size: 0.65rem;
            text-align: center;
            margin: 0;
        }
        &-icon{
            width: 40px;
        }
        &-badge{
            position: relative;
            margin-bottom: 0.25rem;
        }
        &-bonus{
            position: absolute;
            display: flex;
            bottom: -10px;
            right: -10px;
            align-items: center;
            border-radius: 5rem;
            padding: 2px;
            font-size: 1rem;
            color: #711dd8;
        }
        &-gem{
            width: 20px;
            margin-right: -8px;
            margin-top: -5px;
        }
    }
}
.c_item{
    display: flex;
    background: #fff;
    border: 1px solid #eee;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    align-items: center;
    margin-bottom: 0.5rem;
    box-shadow: 0 14px 28px rgba(77, 70, 86, 0.1), 0 10px 10px rgba(77, 70, 86, 0.1);
    &__icon{
        max-width: 50px;
        color: red;
        width: 50px;
    }
    &__gem{
        img{
            max-width: 25px;
        }
    }
    &__info{
        flex-grow: 1;
        width: 50%;
        padding-left: 0.5rem;
    }
    &__title{
        color: #711dd8;
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
    }
    &__text{
        color: #4e4d4f;
        font-size: 0.8rem;
    }
}
</style>