<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../http'
import CardActivations from '../components/CardActivations.vue'

export default {
    components: {
        CardActivations
    },
    data() {
        return {
            regions: [],
            territory: null,
        }
    },
	computed: {
       
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			territoryById: 'stats/territoryById',
		})
	},
	methods: {
		goToHistory(user_id) {
			this.$router.push({name: 'HistoryActivations', params: {id: user_id}})
		},
        
        async fetchRegions () {
            let id = this.$route.params.id
            try {
                let data = await http.regions.byTerritory(id)
                this.$set(this, 'regions', data.regions)
                this.$set(this, 'territory', data.territory)
            } catch (error) {
                
            }
        },

        gotTo(data) {
			this.$router.push(data)
		},

		...mapActions({
			fetchUser: 'auth/fetchUser',
            fetchTerritories: 'stats/fetchTerritories',

		})
	},
	mounted() {
		this.fetchUser()
        // this.fetchTerritories()
        this.fetchRegions()
		// this.fetchTerritoryReferals()
	}
}
</script>
<template>
	<div class="ranking" v-if="user">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<h5 class="m-0 w-100 text-center mb-2">Regiones</h5>
					<h6 class="fw-bold text-center w-100" v-if="territory">{{territory.name}}</h6>

				</div>
			</div>
		</div>

        <div class="mt-3 px-3">
            <div v-if="regions.length">
                <CardActivations 
                    v-for="(region, index) in regions" 
                    :key="`t_${region.id}_${index}`"
                    :title="region.name"
                    :activators="region.users"
                    :total="region.activations" 
                    :now="region.activations_now" 
                    :weekly="region.activations_weekly" 
                    :weekly_goal="region.weekly_goal" 
                    :route="'CorpRegionRanking'"
                    :id="region.id"
                />
               
            </div>
            <div class="d-flex" v-else>
                <div class="mx-auto">
                    <div class="lds-ripple"><div></div><div></div></div>
                    <p class="text-center text-white">Cargando...</p>
                </div>
            </div>
        </div>
		
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>