import { identity } from 'lodash';
import Vue from 'vue'

export const setLocalProducts = (state, payload) => {
	Vue.set(state, 'local_products', payload)
	localStorage.local_products = JSON.stringify(state.local_products)
}

export const pushLocalProducts = (state, payload) => {
	payload.forEach(element => {
		let findItem = state.local_products.find(i => i.id == element.id)
		if(!findItem) {
			state.local_products.push(element)
		}
		localStorage.local_products = JSON.stringify(state.local_products)
	});
	// Vue.set(state, 'local_products', payload)
}
export const finish = (state) => {
	state.loading = false
	// Vue.set(state, 'local_products', payload)
}
export const start = (state) => {
	state.loading = true
	// Vue.set(state, 'local_products', payload)
}

export const setCurrentPage = (state, payload) => {
	Vue.set(state, 'current_page', payload)
}

export const setQuery = (state, payload) => {
	Vue.set(state, 'query', payload)
}

export const setLastPage = (state, payload) => {
	Vue.set(state, 'last_page', payload)
}

export const setSkus = (state, payload) => {
	Vue.set(state, 'skus', payload)
}

export const setApiProducts = (state, payload) => {
	Vue.set(state, 'skus', payload)
}

export const setProduct = (state, payload) => {
	Vue.set(state, 'product', payload)
}

export const updatePrice = (state, payload) => {
	if(state.product.id = payload.id) {
		Vue.set(state.product.elektra, 'Price', payload.price)
	} else {
		location.reload()
	}
}