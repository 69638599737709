<script>
import { mapGetters } from 'vuex'
import http from '../http'

export default {
    data(){
        return {
            tickets: [],
            loading: false,
        }
    },
    async mounted(){
        let vm = this
        await vm.getTicketsByUser();
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
		}),
        canOpenTicket(){
            let flag = true;
            this.tickets.forEach(t => {
                if(t.status == 'ABIERTO'){
                    flag = false;
                }
            });

            return flag;
        }
    },
    methods:{
        go(name, params) {
			this.$router.push({name: name, params })
		},
        async getTicketsByUser(){
            let vm = this


            vm.loading = true
            await http.support.tickets(vm.user.id).then((response) => {
                setTimeout(function () {
                    vm.loading = false
                    vm.tickets = response.tickets;
                }, 1000)

            });
        }
    }
}
</script>
<template>
    <div class="position-relative score">
        <div class="score__wellcome text-center pb-3">
            <h3 class="title m-0 text-white">
                Módulo de tickets <br/> de soporte
            </h3>
        </div>
        <div class="score">
            <div v-if="tickets.length > 0" class="d-flex flex-wrap">
                <div class="col-12 col-md-12 p-1">
                    <div data-v-7b8b0119="" class="box p-3 h-100 ">
                        <h6 data-v-7b8b0119="" class="m-0 mb-2 text-center fw-bold small"> Mis tickets </h6>

                        <template
                            v-for="(ticket, index) in tickets"
                            >
                            
                            <div @click="go('MessagesTicket', {ticket_id: ticket.id})" class="d-flex border-top py-2 mx-n3" :key="`tickets_support_${index}`">
                                <div class="col-12 height-95 px-2">
                                    
                                    <p class="m-0 text-ellipsis fw-bold small">
                                        {{ticket.title}}
                                    </p>
                                    <p class="m-0 text-ellipsis fw-bold small">
                                        Folio #{{ticket.folio}}
                                    </p>
                                    <p class="small m-0">
                                        {{ticket.extract.content.substring(0, 80)}}...
                                    </p>

                                    <span :class="{'bg-primary':ticket.status == 'ABIERTO','bg-danger':ticket.status == 'CERRADO' }" class="badge">{{ticket.status}}</span>
                                    
                                    <span v-if="ticket.dont_read" class="globe-dont-read-message">{{ticket.dont_read}}</span>
                                </div>

                            </div>
                        </template>
                    </div>
                </div>

                <button v-if="canOpenTicket && user.can_up_ticket" @click="go('NewTicketSupport', {})" class="btn btn-primary btn-sm btn--rounded btn-add-ticket"><span>+</span></button>
            </div>
            <div v-else class="ranking__wrappers px-2">
                <div class="start--show">
                    <div class="start__top">
                        <img src="/assets/pantalla_inicio/robot_grande_central.png" alt="" class="start__robot start__robot--front">
                    </div>
                </div>
                <div class="box px-0">
                    <div class="px-2">
                        <h6 v-if="!loading" class="text-center m-0">
                            <span class="d-block mb-3">
                                Aquí veras todos tus tickets de soporte
                            </span>
                            <button v-if="user.can_up_ticket" @click="go('NewTicketSupport', {})" class="btn btn--rounded btn-sm btn-primary fw-bold">
                                Crea un ticket
                            </button>
                        </h6>
                        <div v-else>
                            <div class="d-flex flex-wrap">
                                <div class="mx-auto text-primary">
                                    <div class="lds-ripple"><div></div><div></div></div>
                                </div>
                            </div>
                            <h6 class="text-center m-0">
                                Cargando...
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
	    </div>
    </div>
</template>

<style lang="scss" scoped>
.btn-add-ticket{
    position: fixed;
    bottom: 30px;
    right: 30px;
    -webkit-animation: buttonsupport2 1.5s ease-in-out infinite;
    animation: buttonsupport2 1.5s ease-in-out infinite;
}
@keyframes buttonsupport2{
	0%{
		box-shadow: 0 0 0 0px rgba($color: #6807d6, $alpha: 1.0);
	} 

	100%{
		box-shadow: 0 0 0 15px rgba($color: #6807d6, $alpha: 0);
	}
}
.btn-add-ticket span{
    font-weight: bold;
    font-size: 20px;
    padding-left: 3px;
    padding-right: 3px;
}

.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}

.height-95{
    overflow: hidden;
    position: relative;
}
.globe-dont-read-message{
    display: flex;
    align-items: center;
    position: absolute;
    top: 35%;
    right: 3px;
    background: #dd6161;
    color: #ffffff;
    padding: 2px 10px;
    border-radius: 20px;

}
</style>