<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import http from '../http'
import ModalVue from '../components/ModalVue.vue'
export default {
	components: {
		ModalVue,
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
				address: '',
				state: '',
				city: '',
			},
			tab: 'materials',
			faqs: [],
			selectedFaq: 0,
			loadingFaqs: false,
		}
	},
	computed: {
		
		...mapGetters({
			user: 'auth/user',
			materials: 'auth/materials'
		})
	},
	watch: {
		'$route.params.tab': {
			handler(nVal) {
				
				if(nVal) {
					this.tab = nVal
				}
			},
			immediate: true
		},
	},
	methods: {	
		async fetchFaqs() {
			let vm = this
			if(!this.faqs.length) {
				this.loadingFaqs = true
			}
			try {
				let reponse = await http.faq.all();
				vm.$set(vm, 'faqs', reponse)
				this.loadingFaqs = false
			} catch (error) {
				this.loadingFaqs = false
			}
		},

		handlerSetMaterial(material) {
			this.setMaterial(material)
			
			this.$router.push({name: 'Material'})
		},
	
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchMaterials: 'auth/fetchMaterials',
		}),

		...mapMutations({
			setMaterial: 'auth/setMaterial'
		})
	}, 
	mounted() {
		let vm = this
		vm.fetchUser().then(() => {
			vm.fetchMaterials()
		})

		vm.fetchFaqs()
	}
}
</script>
<template>
	<div class="ranking" v-if="user">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<div v-if="tab == 'materials'" class="w-100">
						<h6  class="w-100 text-center m-0 fw-bold">
							<span class="me-1">Ayuda</span>
						</h6>
						<p class="m-0 text-center w-100">
							Encontrarás consejos para usar y activar
						</p>
					</div>
					<div v-else-if="tab == 'faq'" class="w-100">
						<h6  class="w-100 text-center m-0 fw-bold">
							<span class="me-1">Preguntas</span>
						</h6>
						<p class="m-0 text-center w-100">
							Frecuentes
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="px-2 mt-3" v-if="tab == 'materials'">
			<div class="d-flex flex-wrap">
				<div class="col-12 p-1" v-for="(material, index) in materials" :key="`mterial_${index}`">
					<div class="box p-3 py-4">
						<div v-if="material.type == 'video'">
							<h6 class="fw-bold small  mb-3">{{material.title}}</h6>
							<div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="material.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="material.title"></iframe></div>
						</div>
						<div class="d-flex align-items-center" v-if="material.type == 'html'" @click="handlerSetMaterial(material)">
							<div class="menu-item__icon m-0">
								<span></span>
							</div>
							<h6 class="m-0 fw-bold small ms-3">
								{{material.title}}
							</h6>
						</div>
					</div>
				</div>
				<!-- <div class="col-12">
					<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/590431057?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=02f09ac1b4" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="01+Activadores+Juegodegemas_SA&amp;amp;A_2021_V4"></iframe></div>
				</div> -->
				<!-- <div class="col-12 p-1 ">
					<div class="box p-3 h-100" @click="$router.push({name: 'Terms'})">
						<div class="d-flex align-items-center">
							<div class="menu-item__icon m-0">
								<span></span>
							</div>
							<h6 class="m-0 fw-bold small ms-3">
								Terminos y condiciones
							</h6>
						</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="px-2 mt-3" v-else-if="tab == 'faq'">
			<div class="col-12">
				<div class="box p-2">
					<div v-if="!loadingFaqs">

						<div @click="selectedFaq = faq.id" class="mx-n2" :class="{'border-bottom': index + 1 < faqs.length}" v-for="(faq, index) in faqs" :key="`faq_${index}`">
							<div class="py-2 px-2">
								<h6 class="fw-bold m-0">{{faq.question}}</h6>

								<p v-show="faq.id == selectedFaq" class="mt-2 mb-0 px-2">
									{{faq.answer}}
								</p>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="d-flex flex-wrap">
							<div class="mx-auto text-primary">
								<div class="lds-ripple"><div></div><div></div></div>
							</div>
						</div>
						<h6 class="text-center m-0">
							Cargando...
						</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.box{
	border-radius: 1rem;
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
.menu-item__icon{
	margin-bottom: 0.5rem;
	span{
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #36C800;
	}
}
</style>