<script>
export default {
	data() {
		return {
			classStart: '',
			code: '',
			codeInParam: false,
			message: 'Error'
		}
	},

	mounted() {
		this.message = this.$route.params.message
	}

}
</script>
<template>
	<div class="start start--show" :class="classStart">
		<div class="start__top">
			<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
		</div>
		<div class="start__bottom mb-5">
			<div class="start__content">
				<div class="start__bottom__slogan mb-2 mx-auto">
					<img class="start__bottom__slogan-pin" src="/assets/v2/logo_v2.png" alt="">
				</div>
				<h6  class="text-center text-white" v-if="message">
					{{message}}
				</h6>
				<div v-else>
					<p class="text-white">Por favor cierra sesión en Zeus y vuelve a ingresar.</p>
					<p class="text-white">Si el problema persiste por favor apaga y enciende tu celular.</p>
				</div>
				
			</div>
		</div>
	</div>
</template>