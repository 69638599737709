<script>
import ProductHolder from '../../components/Elektrizate/ProductHolder.vue'
import ModalVue from '../../components/Elektrizate/ModalVue.vue'
export default {
    components: {
        ProductHolder,
        ModalVue
    },
    data() {
        return {
            viewItemsTour: []
        }
    },
    methods: {
        openVideo() {
            this.$refs.videoModal.open()
            this.setViewItemTour('video')
        },
        setViewItemTour(name) {
            if(!this.viewItemsTour.includes(name)) {
                this.viewItemsTour.push(name)
            }
            localStorage.setItem('viewItemsTour', JSON.stringify(this.viewItemsTour))
        },
        goTo(route) {
            this.setViewItemTour(route.name)
            this.$router.push(route)
        },
        routerPush(route) {
            this.$router.push(route)
        }
    },
    mounted() {
        let viewItemsTour = localStorage.getItem('viewItemsTour') || null
        
        if(!viewItemsTour) {
            localStorage.setItem('viewItemsTour', '[]')
        } else {
            this.viewItemsTour = JSON.parse(viewItemsTour)
        }
    }
}
</script>
<template>
    <div class="elektra-tour-intro ">
        <div class="elektra-tour-intro__header animate__animated animate__fadeInUp">
            <div class="elektra-tour-intro__header-top">
                <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
            </div>
        </div>
        <div class="elektra-tour-intro__content px-4 animate__animated animate__fadeInUp">
            
            <div class="px-4">
                <div class="px-2">
                    <button class="elektra-btn mt-5 animate__animated animate__fadeInUp" @click="openVideo">
                        <div class="d-flex px-2 py-2">
                            <img class="elektra-btn__icon" src="/elektrizate/nav_explorer.svg" alt="">
                            <span class="fw-bold elektra-btn__text">
                                ¡Descubre cómo ganar con el Juego de Elektrones! 
                            </span>
                        </div>
                    </button>
                    <button  class="elektra-btn mt-5 animate__animated animate__fadeInUp" @click="goTo({name: 'ElektrizateTourRules'})" :disabled="!viewItemsTour.includes('video')">
                        <div class="d-flex px-2 py-2">
                            <img class="elektra-btn__icon" src="/elektrizate/file_check.svg" alt="">
                            <span class="fw-bold elektra-btn__text">
                                Reglas de participación
                            </span>
                        </div>
                    </button>
                    <button  class="elektra-btn mt-5 animate__animated animate__fadeInUp"  @click="goTo({name: 'ElektrizateTourChat'})" :disabled="!viewItemsTour.includes('ElektrizateTourRules')">
                        <div class="d-flex px-2 py-2">
                            <img class="elektra-btn__icon" src="/elektrizate/chat.svg" alt="">
                            <span class="fw-bold elektra-btn__text">
                                Diálogo de ventas
                            </span>
                        </div>
                    </button>
                    <button  class="elektra-btn mt-5 animate__animated animate__fadeInUp" @click="goTo({name: 'ElektrizateTourTerms'})" :disabled="!viewItemsTour.includes('ElektrizateTourChat')">
                        <div class="d-flex px-2 py-2">
                            <img class="elektra-btn__icon" src="/elektrizate/terms.svg" alt="">
                            <span class="fw-bold elektra-btn__text">
                                Términos y condiciones
                            </span>
                        </div>
                    </button>
                </div>
            </div>


            <div class="mt-5">
                <p v-if="viewItemsTour.length >= 4" class="text-center">Al continuar reconoces los Términos y Condiciones. Cualquier mala práctica será sancionada.</p>
                <p v-else class="text-center">Debes estudiar todo el contenido para continuar.</p>
            </div>

            <div class="text-center d-flex justify-content-between">
                <router-link class="elektra-btn elektra-btn--md me-2" :to="{name: 'ElektrizateTourRewards'}">Anterior</router-link>
                <button class="elektra-btn elektra-btn--secondary elektra-btn--md" @click="routerPush({name: 'ElektrizateTourEvaluation'})" :disabled="viewItemsTour.length < 4">Continuar</button>
            </div>
        </div>
        <modal-vue ref="videoModal">
			<template v-slot:header>
				<h5 class="m-0 text-center w-100"></h5>
			</template>
			<template v-slot:body>
				
				<div >
					<div style="width:100%;height:0px;position:relative;padding-bottom:177.778%;"><iframe src="https://streamable.com/e/qndmm5" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>
					<div class="py-2 d-flex">
						
						<!-- <button class="btn btn--rounded fw-bold me-auto ms-auto" @click="$refs.confirmCardModal.close">Cancelar</button> -->
						<button class="btn btn--rounded fw-bold mx-auto px-3" @click="$refs.videoModal.close">Continuar</button>
					</div>
				</div>
			</template>
		</modal-vue>
    </div>
</template>
<style lang="scss" scoped>
.elektra-btn__icon{
    min-width: 50px;
    width: 50px;
    margin-right: 0.5rem;
}
.elektra-btn__text{
    font-size: 1.2rem;
    text-align: left;
    color: #000;
    line-height: 1.2rem;
}
.elektra-icon-bonus{
    width: 60px;
    min-width: 60px;

}
.elektra-box{
    border-radius: 1rem;
    font-size: 0.9rem;
    h5{
        font-size: 0.8rem;
        font-weight: 600;

    }
    p{
        font-size: 0.8rem;
        text-align: justify;
    }
}
.elektra-btn--lg {
    width: 55%;
}
.elektra-tour-intro-box{
    background-color: rgba($color: #000000, $alpha: 0.7);
    border: 2px solid #bcff3f;
    border-radius: 2rem;
}
.elektra-start{
    color: #fff;
    background-image: url('/elektrizate/start_bg.png');
    position: absolute;
    height: 100vh;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size:100% 100%;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.elektra-tour-intro{
    display: flex;
    flex-direction: column;
    &__notice{
        background: #bcff3f;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        display: flex;
        color: #000;
        font-size: 0.8rem;

        &-icon{
            width: 65px;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    &__battery{
        position: absolute;
        width: 40px;
        right: 12%;
        top: -27px;
    }
    &__badge{
        width: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: -40%;
    }
    &__iso{
        position: relative;
        z-index: 3;
        width: 100%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header{
        &-top{
            position: relative;
            margin-bottom: 1.5rem;
        }
    }
    &__button{
        text-align: center;
        padding-bottom: 2rem;
    }
    &__up{
        height: 45vh;
        flex-grow: 1;
        position: relative;
    }
    &__down{
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__logo{
        max-width: 60%;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        bottom: 45%;
        z-index: 2;
        display: block;
        margin-left: auto;
        margin-right: auto;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
            top: -10%;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }

   
}
</style>
