<script>
export default {
    props: {
        challenges: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>
<template>
        
        <div class="elektra-section">
            <h2 class="elektra-title mb-3">Descubre los desafíos</h2>

            <div class="elektra-score__challenges">
                <div v-for="(referalChallenge, index) in challenges" :key="`challenge-${index}`" class="elektra-score__challenge">
                    <div class="elektra-score__challenge-x">

                        <div class="elektra-score__challenge-wrap-icon">
                            <div>
                                <img :src="`elektrizate/${referalChallenge.icon}`" alt="">
                            </div>
                            <div>
                                <p class="elektra-score__challenge-client">Cliente Gana ${{ referalChallenge.client_bonus }}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="elektra-score__challenge-title">{{ referalChallenge.title }}</p>

                        <div class="elektra-score__challenge-user">
                            <span class="fw-bold">+{{ referalChallenge.user_bonus }}</span>
                            <img class="elektra-score__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                            <span>Tú <br> ganas</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>
<style lang="scss" scoped>
.elektra-score{
    background-color: #fff;
    height: 100vh;
    padding-bottom: 70px;
    &__up{
        position: relative;
        height: 40vh;
        min-height: 406px;
    }
    &__header{
        padding-top: 50px;
        width: 100%;
        height: 40vh;
        background-image: url('/elektrizate/bg_header_score.png');
        background-size: 100% 100%;
        position: relative;
        min-height: 406px;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        .elektra-socre__title{
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
    &__user{
        position: absolute;
        bottom: 16%;
        padding: 1rem;
        padding-left: 2.5rem;
    }
    &__badge{
        width: 50%;
        position: relative;
        margin-left: auto;
    }
    &__sun2{
        position: absolute;
        transform: scale(1.5);
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &__sun{
        animation: sun 220s infinite alternate;
    }
    &__iso{
        position: absolute;
        z-index: 3;
        width: 60%;
        left: 20%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__wallet{
        background-color: #954cdd;
        height: 160px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        padding-top: 75px;
        padding-left: 60px;
        &-wrap{
            display: flex;
            align-items: center;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            font-weight: 600;
            font-size: 0.9rem;
            margin-top: -6px;
            margin-bottom: 0;
        }
    }
    &__content{
        color: #000;
        &-top{
            position: relative;
            background-color: #fff;
            padding: 1.5rem;
            text-align: center;
            z-index: 3;
            margin-top: -30px;
            border-radius: 0 20% 0 0;
            font-size: 0.9rem;
            line-height: 1rem;
            &::before{
                content: '';
                display: block;
                background-image: url('/elektrizate/score_top_decoration.png');
                background-size: 100% 100%;
                position: absolute;
                top: -49px;
                height: 50px;
                width: 60px;
                left: 0;

            }

        }
        
    }   
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        background-color: #f1f1f1;
        width: 32%;
        border-radius: 100px;
        line-height: 0.9rem;
        &-wrap-icon{
            background-color: #c9ff66;
            border-radius: 100px;
            padding: 1rem 0rem;
            box-shadow: 3px 3px 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        &-client{
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 800;
            margin: 0;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        &-user{
            color: #6700ce;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            padding-left: 0.75rem;
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        &-title{
            font-size: 0.60rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &-x{
            padding-right: 0.5rem;
            padding-left: 0.5rem;

        }
    }
}
</style>