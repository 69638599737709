<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            material: 'auth/material' 
        })
    },

    mounted() {
        if(!this.material) {
            this.$router.push({name: 'Help'})
        }
    }
}
</script>
<template>
	<div class="ranking">
		<div  v-if="material">
			<div class="vault__header">
				<div class="vault__header-content">
					<div class="vault__header-wrapper flex-wrap text-center w-100 ">
						<h6  class="w-100 text-center mb-3 fw-bold">
							<span class="me-1">{{material.title}}</span>
						</h6>
						
					</div>
				</div>
			</div>
			<div class="col-12 px-2 py-3">
				<div class="box px-2"> 
					<div v-if="material.type == 'html'" v-html="material.content"></div>
				</div>
			</div>
		</div>

		<div v-if="faq">
			
		</div>
	</div>
</template>