<script>
import { mapGetters } from 'vuex'
import ModalVue from '@/components/Elektrizate/ModalVue.vue'

export default {
    components: {
        ModalVue
    },
    data() {
        return {
            menuOpen: false,
            menu: [
                {
                    name: 'Premios canjeados',
                    route: 'Rewards'
                },
                {
                    name: 'Notificaiones',
                    route: 'Notifications'
                },
                {
                    name: 'Comunicación',
                    route: 'Material'
                },
            ]
        }
    },
    computed: {
        theme() {
            let primary = ['Explorer']

            if(primary.includes(this.$route.name)) {
                return 'primary'
            }

            return 'white'
        },
        ...mapGetters({
            authUser: 'auth/user'
        })
    },
    methods: {
        handlerOpenMenu() {
            this.menuOpen = true
            let body = document.querySelector('body')
            this.$nextTick(() => {
                setTimeout(() => {
                    body.addEventListener('click', this.handlerCloseMenu)
                }, 300)
            })
        },
        handlerCloseMenu() {
            this.menuOpen = false
            let body = document.querySelector('body')
            
            body.removeEventListener('click', this.handlerCloseMenu)
        },
        openVideo() {
            this.$refs.videoModal.open()
            this.setViewItemTour('video')
        },
    }
}
</script>
<template>
    <div class="elektra-header-interface">
        <div class="elektra-header-interface">
            <button class="elektra-header-interface__btn" @click="() => this.$router.go(-1)">
                <img :src="`/elektrizate/${theme}_back.svg`" alt="">
            </button>
            <button class="elektra-header-interface__btn" @click="handlerOpenMenu">
                <img :src="`/elektrizate/${theme}_menu.svg`" alt="">
            </button>
        </div>
        <div class="elektra-header-interface__menu" :class="{'elektra-header-interface__menu--open': menuOpen}">
            <div class="elektra-header-interface__menu-top">
                <div class="d-flex">
                    <div class="px-4 py-3">
                        <h3>
                            {{ authUser.name }}
                        </h3>
                        <h4>
                            {{ authUser.work_position || 'SIN DEFINIR'}}
                        </h4>
                    </div>
                    <button class="elektra-header-interface__btn" @click="handlerCloseMenu">
                        <img src="/elektrizate/primary_menu.svg" alt="">
                    </button>
                </div>
                <h5 class="px-4 pb-3">¡Ahora <span class="fw-bold">baz tú!</span></h5>

            </div>
            <div class="p-3">

                <div v-for="(item, index) in menu" :key="`itemh-${index}`" class="mb-3">
                    <router-link :to="{name: item.route}" class="elektra-btn elektra-btn--md col-12 text-primary">{{ item.name }}</router-link>
                </div>
                <div>
                    <button class="elektra-btn elektra-btn--md col-12 text-primary" @click.stop="openVideo">
                        <span class="text-sm" style="font-size: 0.9rem;">
                            ¡Descubre cómo ganar! 
                        </span>
                    </button>
                </div>

            </div>
        </div>

        <modal-vue ref="videoModal">
			<template v-slot:header>
				<h5 class="m-0 text-center w-100"></h5>
			</template>
			<template v-slot:body>
				
				<div>
					<div style="width:100%;height:0px;position:relative;padding-bottom:177.778%;"><iframe src="https://streamable.com/e/qndmm5" frameborder="0" width="100%" height="100%" allowfullscreen style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>
					<div class="py-2 d-flex">
						
						<!-- <button class="btn btn--rounded fw-bold me-auto ms-auto" @click="$refs.confirmCardModal.close">Cancelar</button> -->
						<button class="btn btn--rounded fw-bold mx-auto px-3" @click="$refs.videoModal.close">Continuar</button>
					</div>
				</div>
			</template>
		</modal-vue>
    </div>
</template>
<style lang="scss" scoped>

.text-primary{
    color: #6700ce !important;
}
.elektra-header-interface{
    position: absolute;
    z-index: 101;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    &__btn{
        width: 50px;
        height: 50px;
        min-width: 50px;
        background: transparent;
        padding: 0.5rem;
        border: 0;
        outline: none !important;
    }
    &__menu{
        background-color: #fff4d3;
        width: 70%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: -100%;
        opacity: 0;
        z-index: 101;
        transition: 0.3s;
        &--open{
            opacity: 1;
            right: 0;
        }
        &-top{
            background-color: #fff;
            padding: 0rem;
            border-radius: 0 0 0 3rem;
            h3{
                color: #ab23f6;
                font-size: 1.1rem;
                font-weight: 400;
            }
            h4{
                color: #6700ce;
                font-size: 1.1rem;
                font-weight: 600;
            }
            h5{
                font-size: 1.3rem;
                color: #000;
            }
        }
    }
}
</style>