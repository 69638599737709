<script>
import { mapGetters } from 'vuex'
import http from '../../http';
export default {
    data() {
        return {
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet'
        })
    },
    methods: {
        async acceptedTerms () {
            try {
                let accepted = await http.auth.acceptedTerms()
                this.$router.push({name: 'ElektrizateTourMenu'})
            } catch (error) {
                
            }
        }
    },
    mounted() {
        
    }
}
</script>
<template>
    <div class="elektra-tour-view elektra-scroll-y">
        <div class="elektra-tour-view__up ">
            <div class="elektra-tour-view__header">
                <div class="elektra-tour-intro__header-top">
                    <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                </div>
            </div>
            <div class="elektra-tour-view__header-bottom">
                <div class="elektra-tour-view__header-icon">
                    <img src="/elektrizate/terms.svg" alt="">
                </div>
                <div class="elektra-tour-view__header-text">
                    <h2>Términos y condiciones</h2>
                </div>
            </div>
        </div>
        <div class="elektra-tour-view__content">
            <div class="elektra-tour-view__content-top">
                <div class="elektra-tour-view__content-top-scroll px-0">
                    <div class="px-2">

                        <p class="fw-bold">I.- DATOS GENERALES DE LA PROMOCIÓN</p>
                        <div class="ps-2">
                            <p class="fw-bold bg-gray p-2 m-0">
                                Responsable
                            </p>
                            <p class="p-2">
                                Banco Azteca S.A., Institución de Banca Múltiple (“BANCO AZTECA”) y/o BAZ SUPERAPP S.A. DE C.V., En adelante los“RESPONSABLES”
                            </p>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Nombre de la promoción 
                            </p>
                            <p class="p-2">
                                “Elektrones Red Elektra”
                            </p>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Participantes 
                            </p>
                            <p class="p-2">
                                “Asesor baz” dentro de la “Red Elektra” que hayan gestionado entre las personas la descarga de la “aplicación baz súperapp”
                            </p>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Cobertura Geográfica 
                            </p>
                            <div class="p-2">
                                <p>Asesores ligados a las sucursales ubicadasen<strong>MEGA CUAJIMALPA </strong>C. Ocampo 90, Cuajimalpa, Cuajimalpa de Morelos, 05000 Ciudad de M&eacute;xico, CDMX. <strong>EKT TACUBAYA 2 MARTIRES DE LA CONQUISTA </strong>01130, Dominicanos 16, Para&iacute;so, &Aacute;lvaro Obreg&oacute;n, Ciudad de M&eacute;xico, CDMX. <strong>EKT SAN FELIPE DE JES&Uacute;S </strong>Av. Dolores Hidalgo Mz 15-Lt 3, San Felipe de Jes&uacute;s, Gustavo A. <strong>MEGA TLAHUAC </strong>Av. Tl&aacute;huac 5074, Lomas de San Lorenzo, Iztapalapa, 09780 Ciudad de M&eacute;xico, CDMX. <strong>EKT IZTAPALAPA MANUEL CA&Ntilde;AS </strong>Mz15 CARLOS, Manuel Ca&ntilde;as, Hank Gonz&aacute;lez, Iztapalapa, 09700 Ciudad de M&eacute;xico, CDMX. <strong>MEGA SAN ANGEL </strong>Av. Revoluci&oacute;n 1775, San &Aacute;ngel, &Aacute;lvaro Obreg&oacute;n, 01000 Ciudad de M&eacute;xico, CDMX. <strong>MEGA TACUBAYA REVOLUCI&Oacute;N </strong>Av. Jos&eacute; Mart&iacute; 6, Tacubaya, Miguel Hidalgo, 11870 Ciudad de M&eacute;xico, CDMX. <strong>EKT JAMAICA </strong>Sur 75-A 175, Artes Gr&aacute;ficas, Venustiano Carranza, 15830 Ciudad de M&eacute;xico, CDMX <strong>MEGA DF LA LUNA </strong>Av Renato Leduc 501-C, Bosques de Tetlameya, Coyoac&aacute;n, 04730 Coyoac&aacute;n, CDMX. <strong>MEGA XOCHIMILCO </strong>Av. 16 de Septiembre 82, Belen, Xochimilco, 16070 Ciudad de M&eacute;xico, CDMX. <strong>EKT AJUSCO </strong>Carr. Picacho-Ajusco, Territorio Torres, Lomas de Padierna, Tlalpan, 14240 Ciudad de M&eacute;xico, CDMX</p>
                            </div>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Vigencia  
                            </p>
                            <p class="p-2">
                                Del 21 de marzo de 2023 hasta el 31 de diciembre de 2023 o hasta agotar la bolsa destinadapara cada mes.
                            </p>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Transacciones Participantes   
                            </p>
                            <p class="p-2">
                                El “Participante” deberá defomentar la descarga de la “Aplicación baz súperapp”, así como que cumplan con los desafíos.
                            </p>
                            <p class="fw-bold bg-gray p-2 m-0">
                                Bolsa destinada  
                            </p>
                            <p class="p-2">
                                $30,340,800.00 (treinta millones tres cientos cuarenta mil ochocientos pesos 00/100 M.N.)
                            </p>
                        </div>

                        <p class="fw-bold">I.- DATOS GENERALES DE LA PROMOCIÓN</p>
                        <div class="ps-2">
                            <ul>
                                <li>Los “Participantes deberán de encontrarse asignados a la ubicación geografía.</li>
                                <li>Los “clientes” afiliados por el “Participante” deberán de ser clientes nuevos en la “Aplicación baz súperapp”</li>
                            </ul>
                        </div>
                        <p class="fw-bold">III.- DEFINICIONES. </p>
                        <div class="ps-2">
                            <p><strong>&ldquo;Clientes baz&rdquo;.-</strong>&nbsp;Aquellos Clientes que tengan la Aplicaci&oacute;n &ldquo;baz s&uacute;perapp&rdquo; descargada y activa y que su cuenta &ldquo;baz&rdquo; o &ldquo;baz plus&rdquo; este libre de bloqueos.</p>
                            <p><strong>&ldquo;Aplicaci&oacute;n baz superapp&rdquo;.- </strong>Es el software propio de &ldquo;baz&rdquo; que se ejecuta en tel&eacute;fonos inteligentes y/o plataformas digitales, que les permite a los &ldquo;Clientes baz&rdquo; hacer uso de los servicios disponibles en &eacute;l.</p>
                            <p><strong>&ldquo;Negocios baz&rdquo;.-</strong>&nbsp;Aquellos &ldquo;Clientes baz&rdquo; que dentro de su &ldquo;Aplicaci&oacute;n baz superapp&rdquo; afiliaron un &ldquo;Negocio&rdquo;. &nbsp;El cual deber&aacute; de contar con la aplicaci&oacute;n &ldquo;baz superapp&rdquo; para realizar diversas transacciones.</p>
                            <p><strong>&ldquo;Red Elektra&rdquo;.- </strong>Grupo de talento que ejerce actividades remuneradas dentro de las sucursales&nbsp;Elektra.</p>
                            <p><strong>&ldquo;Asesor&rdquo;.- </strong>Personal contratado para ejecutar actividades remuneradas dentro de sucursales Elektra. Estas actividades pueden ser relacionadas con Grupo Elektra y/o Banco Azteca y/o baz s&uacute;perappy van encaminadas al proceso mediante el cual un &ldquo;Cliente baz&rdquo; es afiliado descarg&oacute; y se registr&oacute; en la &ldquo;Aplicaci&oacute;n baz s&uacute;perapp&rdquo;, por conducto y/o interacci&oacute;n del &ldquo;Asesor&rdquo;.</p>
                            <p><strong>&ldquo;Elektron&rdquo;.-</strong>La recompensa que obtendr&aacute; el &ldquo;Participante&rdquo; ganador, al conseguir que los &ldquo;Clientes baz&rdquo; se afilien a la &ldquo;Aplicaci&oacute;n baz s&uacute;perapp&rdquo; de conformidad con lo se&ntilde;alado en la Mecanica5 (cinco) Clientes descarguen y se registren en baz s&uacute;perapp.</p>
                            <p>&ldquo;<strong>Cadena de mando&rdquo;</strong>Grupo de talento que pertenece Red Grupo Elektra responsables de promover e impulsarlos objetivos establecidos en piso de venta.&nbsp;A este grupo pertenecen: Territorial, L&iacute;der volante, Regional, Gerente de tienda y L&iacute;der de formato.&nbsp;</p>
                            <p><strong>B&oacute;vedas electr&oacute;nicas.</strong>&nbsp;- A la funcionalidad en la que los &ldquo;Participantes&rdquo; podr&aacute;n conocer los regalos que podr&aacute;n en su caso, adquirir con sus &ldquo;Elektrones&rdquo;.</p>
                            <p><strong>&ldquo;RGEKT&rdquo;</strong>Grupo de talento que ejerce actividades remuneradas dentro de las sucursales Elektra.</p>
                        </div>
                        <p class="fw-bold">IV.- MECÁNICA.</p>
                        <div class="ps-2">
                            <ol style="list-style-type: lower-alpha;">
                            <li>Los &ldquo;Participantes&rdquo; deber&aacute;n de fomentar entre las personas la descarga de la &ldquo;Aplicaci&oacute;n baz s&uacute;perapp&rdquo;</li>
                            <li>El &ldquo;Participante&rdquo; deber&aacute; de incentivar entre sus nuevos afiliados y/o &ldquo;Clientes baz&rdquo; a la &ldquo;Aplicaci&oacute;n baz s&uacute;perapp&rdquo; la realizaci&oacute;n de las siguientes transacciones y/o desaf&iacute;os:
                            <ul>
                            <li>Recarga y/o compra de tiempo aire, por un monto m&iacute;nimo de $50.00 (cincuenta pesos 00/100 M.N.)</li>
                            <li>Pago de servicios por un monto m&iacute;nimo de $200.00 (dos cientos pesos 00/100 M.N.)</li>
                            <li>Abono a cr&eacute;dito propio (CREDIMAX) por un monto m&iacute;nimo de $50.00 (cincuenta pesos 00/100 M.N.)</li>
                            </ul>
                            </li>
                            <li>Solo podr&aacute;n ser consideradas para participar las transacciones que menciona el inciso anterior si las mismas fueron realizadas dentro de los primeros 28 d&iacute;as h&aacute;biles contados a partir de que se realiz&oacute; su enrolamiento a la &ldquo;Aplicaci&oacute;n baz s&uacute;perapp&rdquo; por parte del &ldquo;Participante&rdquo;.</li>
                            <li>El &ldquo;Participante&rdquo; en caso de resultar ganador, recibir&aacute; como regalo lo siguiente:
                            <ul>
                            <li>Si el &ldquo;Cliente baz&rdquo; reci&eacute;n afiliado por el &ldquo;Participante&rdquo; realiza una recarga y/o compra de tiempo aire, por un monto m&iacute;nimo de $50.00 (cincuenta pesos 00/100 M.N.), el &ldquo;Participante&rdquo; recibir&aacute; 1 (un) Elektron</li>
                            <li>Si el &ldquo;Cliente baz&rdquo; reci&eacute;n afiliado por el &ldquo;Participante&rdquo; realiza Pago de servicios por un monto m&iacute;nimo de $200.00 (dos cientos pesos 00/100 M.N.), el &ldquo;Participante&rdquo; recibir&aacute; 1 (un) Elektron</li>
                            <li>Si el &ldquo;Cliente baz&rdquo; reci&eacute;n afiliado por el &ldquo;Participante&rdquo; realiza un Abono a cr&eacute;dito (CREDIMAX) propio por un monto m&iacute;nimo de $50.00 (cincuenta pesos 00/100 M.N.), el &ldquo;Participante&rdquo; recibir&aacute; 1 (un) Elektron</li>
                            </ul>
                            </li>
                            <li>Los &ldquo;Participantes&rdquo; que resulten ganadores solo podr&aacute;n obtener como m&aacute;ximo 3 (tres) &ldquo;Elektrones&rdquo; por cada &ldquo;Cliente baz&rdquo; reci&eacute;n afiliado y que cumpla con la transacci&oacute;n se&ntilde;alada en los incisos anteriores.</li>
                            <li>En caso de que el &ldquo;Participante&rdquo; resulte ganador de &ldquo;Elektrones&rdquo; derivado de su participaci&oacute;n de esta din&aacute;mica, la &ldquo;Cadena de mando&rdquo; recibir&aacute; 1 (un) &ldquo;Elektron&rdquo; por cada 3 (tres) &ldquo;Elektrones&rdquo; que cada &nbsp;&ldquo;Participante&rdquo; siempre y cuando este &uacute;ltimo este asignado a la zona de la &ldquo;Cadena de mando&rdquo;.</li>
                            <li>Los &ldquo;Participantes&rdquo; y &ldquo;Cadenas de mando&rdquo; que resulten ganadores de los &ldquo;Elektrones&rdquo; podran canjear los mismos en las &ldquo;b&oacute;vedas electr&oacute;nicas&rdquo; disponibles en la plataforma conocida como &ldquo;ZEUS&rdquo;</li>
                            <li>Las fechas en las que los ganadores podr&aacute;n redimir o canjear sus regalos se dar&aacute; a conocer a trav&eacute;s de los comunicados de la &ldquo;RGEKT&rdquo; a trav&eacute;s de la plataforma conocida como ZEUS y podr&aacute;n realizar su canje hasta el 31 de diciembre de 2023.</li>
                            </ol>
                        </div>
                        <p class="fw-bold">V.- RESTRICCIONES</p>
                        <div class="ps-2">
                            <ol style="list-style-type: lower-alpha;">
                            <li>El monto de la &ldquo;Bolsa destinada&rdquo; se distribuir&aacute; de la siguiente forma:
                            <table>
                            <tbody>
                            <tr>
                            <td width="50">
                            <p>MARZO</p>
                            </td>
                            <td width="50">
                            <p>ABRIL</p>
                            </td>
                            <td width="50">
                            <p>MAYO</p>
                            </td>
                            <td width="50">
                            <p>JUNIO</p>
                            </td>
                            <td width="50">
                            <p>JULIO</p>
                            </td>
                            <td width="50">
                            <p>AGOSTO</p>
                            </td>
                            <td width="50">
                            <p>SEPTIEMBRE</p>
                            </td>
                            <td width="50">
                            <p>OCTUBRE</p>
                            </td>
                            <td width="50">
                            <p>NOVIEMBRE</p>
                            </td>
                            <td width="50">
                            <p>DICIEMBRE</p>
                            </td>
                            </tr>
                            <tr>
                            <td width="50">
                            <p>$117,600</p>
                            </td>
                            <td width="50">
                            <p>$735,000</p>
                            </td>
                            <td width="50">
                            <p>$1,260,000</p>
                            </td>
                            <td width="50">
                            <p>$1,470,000</p>
                            </td>
                            <td width="50">
                            <p>$3,675,000</p>
                            </td>
                            <td width="50">
                            <p>$3,675,000</p>
                            </td>
                            <td width="50">
                            <p>$3,675,000</p>
                            </td>
                            <td width="50">
                            <p>$6,615,000</p>
                            </td>
                            <td width="50">
                            <p>$6,615,000</p>
                            </td>
                            <td width="50">
                            <p>$6,615,000</p>
                            </td>
                            </tr>
                            </tbody>
                            </table>
                            </li>
                            <li>Es responsabilidad de los &ldquo;Participantes&rdquo; asegurarse que los &ldquo;Responsables&rdquo; cuente con sus datos de contacto debidamente actualizados para que reciban la comunicaci&oacute;n correspondiente.</li>
                            <li>S&oacute;lo participan personas mayores de 18 a&ntilde;os que cumplan con las condiciones se&ntilde;aladas en estos t&eacute;rminos y condiciones.</li>
                            <li>Los &ldquo;Clientes baz&rdquo; deben de descargar y activar la Aplicaci&oacute;n &ldquo;baz superapp&rdquo; con ayuda de un &ldquo;Asesor&rdquo; y realizar las &ldquo;Transacciones Participantes&rdquo; a trav&eacute;s de dicha Aplicaci&oacute;n.</li>
                            <li>El &ldquo;Cliente baz&rdquo; no deber&aacute; tener bloqueada su cuenta &ldquo;baz&rdquo; o &ldquo;baz plus&rdquo;.</li>
                            <li>Promoci&oacute;n valida &uacute;nicamente en la Rep&uacute;blica Mexicana.</li>
                            <li>Solo participan l&iacute;neas telef&oacute;nicas que no cuenten con un plan de datos contratado.</li>
                            <li>Los regalos no se pueden transferir o ceder a otra persona que no sea el ganador.</li>
                            <li>S&oacute;lo participan las &ldquo;Transacciones participantes&rdquo; previamente descritas.</li>
                            <li>Los regalos est&aacute;n sujetos a disponibilidad.</li>
                            <li>No se permitir&aacute; la entrega de regalos sustitutos o equivalentes.</li>
                            <li>Cualquier costo o gasto relacionado directa o indirectamente con los regalos que no se encuentre previsto en estos t&eacute;rminos y condiciones correr&aacute; por cuenta de los &ldquo;Participantes&rdquo; que resulten ganadores.</li>
                            <li>No aplican devoluciones, reembolso o cambios de los regalos y/o incentivos.</li>
                            <li>Esta promoci&oacute;n no es acumulable con otras promociones.</li>
                            <li>Los&ldquo;Responsables&rdquo;se reservan el derecho de descalificar o excluir de la promoci&oacute;n a aqu&eacute;llos participantes que, directa o indirectamente hayan alterado, incumplido o violado, los t&eacute;rminos y condiciones establecidos en este documento.</li>
                            <li>Los&ldquo;Responsables&rdquo;, se reservan el derecho de no entregar el incentivo en el caso de comprobar la existencia de conductas fraudulentas.</li>
                            <li>Los&ldquo;Responsables&rdquo;el derecho a interpretar los presentes t&eacute;rminos y condiciones en caso de duda, toda decisi&oacute;n del organizador ser&aacute; inapelable, definitiva y sin posibilidad de empate.</li>
                            <li>Los datos que en su caso proporcionen los participantes ser&aacute;n tratados con base en lo establecido en el Aviso de Privacidad que podr&aacute; consultar en <a href="http://www.bancoazteca.com.mx"><strong><u>bancoazteca.com.mx</u></strong></a>y/o<strong>www.elektra.com.mx/aviso-de-privacidad-elektra</strong></li>
                            </ol>
                            <p>&nbsp;</p>
                        </div>
                        <p class="fw-bold">VI.- MODIFICACIONES</p>
                        <div class="ps-2">
                            <p>Los presentes términos y condiciones podrán ser modificados en cualquier momento, para lo cual bastara que los“Responsables” lo autorice y publique a través en su portal web www.baz.app.</p>
                        </div>
                        <p class="fw-bold">VII.- TERMINACION.</p>
                        <div class="ps-2">
                            <p>Los “Responsables” pueden decidir a su completa discreción y sin ninguna responsabilidad, suspender o cancelar la promoción, así mismo publicara en baz.app la fecha en la que dará por terminado la promoción.</p>
                        </div>
                        <p class="fw-bold">VIII.- ACEPTACION DE LOS TERMINOS Y CONDICIONES.</p>
                        <div class="ps-2">
                            <p>Los “Participantes” que de manera voluntaria participen en la presente promoción aceptan dar lectura total al presente documento, por lo que al participar se entenderá que han leído y entendido los presentes términos y condiciones y el aviso de privacidad publicado en su totalidad.</p>
                        </div>
                        <p class="fw-bold">IX.- AUTORIZACION DE USO DE IMAGEN.</p>
                        <div class="ps-2">
                            <p>Los “Participantes” que apliquen en la promoción autorizan el uso de su imagen por su propio derecho y libre de toda coacción, aceptan y autorizan a los“Responsables” para que hagan uso de su imagen, voz o semejanza, así como las publicaciones  de la  redes sociales (FACEBOOK, TWITTER, INSTRAGRAM, TELEGRAM, LINKEDIN, ETC) mediantes que sea(n) captada(s) a través de fotografías, videos, o cualquier otro medio; para ser editadas, publicadas o reproducidas en cualquier medio publicitario y/o de comunicación y para cualquier fin que así convenga a los intereses delos “Responsables”, por el tiempo necesario para cumplir con las finalidades de la comunicación del presente programa y de futuras promociones similares, renunciando expresamente a cualquier contraprestación y/o derechos derivados de la Ley Federal de Derechos de Autor.</p>
                        </div>
                        <p class="fw-bold">X.- ENTREGA DE PREMIOS.</p>
                        <div class="ps-2">
                            <p>La recolección de los regalos será responsabilidad de quien resulte ganador de la promoción. La entrega de los regalos se realizará en las oficinas delos “Responsables” o por medios electrónicos. La dirección, día y hora para su recolección se le dará a conocer al “Participante” ganador por el medio que para ello lo estipule los “Responsables”. El “Participante” ganador al aceptar y/o recoger el regalo lo hace a su entera conformidad, sin reservarse ningún tipo a acción en contra delos “Responsables”</p>
                        </div>
                        <p class="fw-bold">XI.-DERECHO DE PROPIEDAD INTELECTUAL</p>
                        <div class="ps-2">
                            <p>Todos los derechos sobre “Banco Azteca” como programas de cómputo, herramientas, aplicaciones y/o cualquier otro y su contenido, incluidos guiones, personajes, gráficos u otros análogos, se encuentran protegidos por las leyes mexicanas e internacionales, por lo que, queda prohibida la copia, reproducción, adaptación, modificación, distribución o comercialización, total o parcial, por cualquier medio y forma, sin la previa autorización por escrito de los“Responsables”.</p>
                            <p>De igual forma, queda prohibida, total o parcialmente, la traducción, adaptación, arreglo o cualquier otra modificación a cualquier programa resultante del original. </p>
                        </div>
                        <p class="fw-bold">XII.- LEGALES.</p>
                        <div class="ps-2">
                            <p>Los &ldquo;Responsables&rdquo;manifiesta que cualquier consulta, aclaraci&oacute;n y/o reclamaci&oacute;n, el Cliente podr&aacute; acudir a su sucursal o llamar a L&iacute;nea Azteca desde la Ciudad de M&eacute;xico y &aacute;rea Metropolitana al 55 5447 8810, opci&oacute;n 6 o a trav&eacute;s de nuestra p&aacute;gina de internet: <a href="http://www.bancoazteca.com.mx"><u>www.bancoazteca.com.mx</u></a></p>
                            <p>Los medios quelos“Responsables” utilizará para notificar a los “Clientes baz” que fueron ganadores conforme a lo estipulado en estos Términos y Condiciones son SMS, push notification y/o correo electrónico, o cualquier otro medio queel “Responsable” establezca. </p>
                            <p>Los medios quelos&ldquo;Responsables&rdquo; utilizar&aacute; para notificar a los &ldquo;Clientes baz&rdquo; que fueron ganadores conforme a lo estipulado en estos T&eacute;rminos y Condiciones son SMS, push notification y/o correo electr&oacute;nico, o cualquier otro medio queel &ldquo;Responsable&rdquo; establezca.</p>
                            <p>Todas y cualquier situaci&oacute;n no considerada en estas bases, al igual que cualquier omisi&oacute;n, ser&aacute; determinada exclusivamente por el &ldquo;Responsable&rdquo;.</p>
                            <p>En caso de ser necesario el ganador tendr&aacute; la obligaci&oacute;n de entregar oportunamente la documentaci&oacute;n requerida por el &ldquo;Responsable&rdquo;, en los t&eacute;rminos y temporalidad que este &uacute;ltimo le indique.</p>
                            <p>El &ldquo;Responsable&rdquo; podr&aacute; entregar el objeto de la promoci&oacute;n por si o terceros asignados que para tales efecto determine.</p>
                            <p>El &ldquo;Participante&rdquo; acepta que el &ldquo;Responsable&rdquo; pueda disponer o en su caso retener del saldo que el &ldquo;Participante&rdquo; reciba derivado de su participaci&oacute;n en la presente promoci&oacute;n y que debida o indebidamente se vea reflejado en su cuenta. El &ldquo;Participante acepta expresamente lo anterior con el simple hecho de participar en la presente promoci&oacute;n.</p>
                            <p>Todos los logotipos, marcas comerciales, marcas de servicio y nombres comerciales mencionados en los presentes T&eacute;rminos y Condiciones, as&iacute; como en cualquier comunicaci&oacute;n y/o publicidad referente a esta promoci&oacute;n, son propiedad de sus respectivos titulares, sus derechos se encuentran protegidos por las leyes en materia de propiedad industrial e intelectual. Todos los derechos reservados</p>
                            <p>Las operaciones de pagos de servicios, pagos como abonos referenciados y recargas de tiempo aire son servicios operados por Nueva Elektra Del Milenio S.A de C.V.</p>
                            <p>El &ldquo;Responsable&rdquo; se exime de cualquier reclamaci&oacute;n o queja por parte de aquellos &ldquo;Clientes baz&rdquo; o cualquier otro tipo de &ldquo;Cliente&rdquo; que se encuentran registrados en el Registro P&uacute;blico de Usuarios (REUS) y/o aquellos &ldquo;Clientes baz&rdquo; o cualquier otro tipo de &ldquo;Cliente&rdquo; que hayan hecho valer sus derechos ARCO y solicitaron que no les fuera enviada publicidad y/o promoci&oacute;n de &ldquo;baz&rdquo; y/o del &ldquo;Responsable&rdquo; y que por dichas razones no recibieron la comunicaci&oacute;n correspondiente para participar en esta din&aacute;mica, aun cuando cumplan o hayan cumplido con las condiciones indicadas en los presentes t&eacute;rminos y condiciones.</p>
                            <p>Los &ldquo;Responsables&rdquo; no tendr&aacute; responsabilidad sobre ning&uacute;n da&ntilde;o personal o material, perjuicio o p&eacute;rdida de cualquier tipo que directa y/o indirectamente y/o consecuentemente pudieran sufrir y/o llegar a sufrir los &ldquo;Participantes&rdquo; y/o potenciales ganadores y/o ganadores y/o cualquier tercero en sus personas o bienes con motivo de su participaci&oacute;n en la din&aacute;mica relatada en el presente documento.</p>
                            <p>La cuenta baz y baz plus son productos ofrecidos por Banco Azteca, S.A., Instituci&oacute;n de Banca M&uacute;ltiple. Para cualquier consulta sobre requisitos, t&eacute;rminos y condiciones de contrataci&oacute;n, montos de apertura, plazos, as&iacute; como tasas, GAT y comisiones aplicables consulta <a href="http://www.bancoazteca.com.mx"><u>www.bancoazteca.com.mx</u></a></p>
                            <p><strong>GAT Nominal (Ganancia Anual Total Nominal)&nbsp;0.01%, GAT Real (Ganancia Anual Total Real)&nbsp;-4.82%, antes de impuestos.</strong><strong>&nbsp;</strong>C&aacute;lculo realizado sobre un monto de $1,000 M.N. a un plazo de 365 d&iacute;as. Fecha de c&aacute;lculo 01 de diciembre&nbsp;del 2022. Vigencia del 01 de&nbsp;diciembre del 2022 al&nbsp;1&nbsp;de&nbsp;junio del&nbsp;2023. Para fines informativos y de comparaci&oacute;n.&nbsp;<strong>La GAT Real es&nbsp;el rendimiento que obtendr&iacute;a despu&eacute;s de&nbsp;descontar la inflaci&oacute;n estimada.</strong></p>
                            <table>
                            <tbody>
                            <tr>
                            <td width="111">
                            <img src="/elektrizate/Logo_IPAB.png" style="width: 200px;" alt="">
                            </td>
                            </tr>
                            <tr>
                            <td width="425">
                            <p>La cuenta se encuentra protegida por el Instituto para la Protecci&oacute;n al Ahorro Bancario (IPAB) hasta por el equivalente a 400 mil UDIs, por persona por banco.</p>
                            <p>Para mayor informaci&oacute;n consulta la p&aacute;gina de internet del IPAB www.ipab.org.mx</p>
                            </td>
                            </tr>
                            </tbody>
                            </table>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                        <p class="fw-bold">XII.- PROTECCION DE DATOS PERSONALES.L</p>
                        <div class="ps-2">
                            <p>Banco Azteca es responsable del tratamiento de los datos personales del Cliente o Usuario, los cuales son tratados conforme a los t&eacute;rminos y condiciones y finalidades establecidas de su Aviso de Privacidad Integral y en estricto apego a su Pol&iacute;tica de Privacidad.</p>
                            <p>Para mayor informaci&oacute;n acerca del tratamiento de sus Datos Personales, cualquier aclaraci&oacute;n, comentario, queja o inconformidad, el Cliente o Usuario puede consultar nuestro Aviso de Privacidad Integral en la Aplicaci&oacute;n "Banco Azteca&rdquo;.</p>
                            <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones a Pol&iacute;tica de Privacidad y adaptarla a novedades legislativas, jurisprudenciales, as&iacute; como pr&aacute;cticas del mercado. Queda bajo responsabilidad del usuario leer peri&oacute;dicamente las Pol&iacute;ticas de Privacidad para estar al tanto de posibles modificaciones.</p>
                        </div>
                    </div>
                    <div class="elektra-tour-view__fotter animate__animated animate__fadeInUp">
                        <button class="elektra-btn elektra-btn--secondary elektra-btn--md"  @click="acceptedTerms">Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<style lang="scss" scoped>
table{
    th,td{
        border: 1px solid #ccc;
    }
}
.bg-gray{
    background: #d8d8d8;
}
ul{
    list-style: none;
    li{
        position: relative;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        &::before{
            content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            background: #b266f9; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 0.75em;
            height: 0.75em; /* Also needed for space (tweak if needed) */
            margin-left: -1.5em; /* Also needed for space (tweak if needed) */
            border-radius: 1rem;
            position: absolute;
            left: 0;
            box-shadow: 3px 1px 0 3px #a44dff;
            top: 0.1rem;
        }
    }
}
.elektra-tour-intro__logo{
    width: 60%;
    margin: 0 auto;
    display: block;
}
.elektra-tour-view{
    background-color: #fff;
    height: 100vh;
    &__fotter{
        background-color: #6700ce;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }
    &__up{
        position: relative;
        height: 280px;
        min-height: 280px;
    }
    &__header{
        padding-top: 1rem;
        width: 100%;
        background-image: url('/elektrizate/bg_header_score.png');
        background-size: 100% auto;
        background-position: bottom center;
        position: relative;
        height: 280px;
        min-height: 280px;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        
        .elektra-socre__title{
            font-size: 1.5rem;
            font-weight: 600;
        }
        &-icon{
            background: #fff;
            border-radius: 2rem;
            padding: 0.5rem 1rem;

            img{
                width: 40px;
                display: block;
                margin: 0 auto;
            }
        }
        &-text{
            margin-left: 0.5rem;
            padding-right: 2rem;
            font-weight: 800;
            h2{
                font-weight: 800;
            }
        }
    }
    &__user{
        position: absolute;
        bottom: 16%;
        padding: 1rem;
        padding-left: 2.5rem;
    }
    &__badge{
        width: 50%;
        position: relative;
        margin-left: auto;
    }
    &__sun2{
        position: absolute;
        transform: scale(1.5);
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &__sun{
        animation: sun 220s infinite alternate;
    }
    &__iso{
        position: absolute;
        z-index: 3;
        width: 60%;
        left: 20%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header-bottom{
        background-color: #954cdd;
        height: 180px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -5%;
        z-index: 1;
        padding-top: 10%;
        padding-left: 60px;
        display: flex;
        align-items: center;
        &-wrap{
            display: flex;
            align-items: center;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            font-weight: 600;
            font-size: 0.9rem;
            margin-top: -6px;
            margin-bottom: 0;
        }
    }
    &__content{
        color: #000;
        background-color: #954cdd;
        &-top{
            position: relative;
            background-color: #fff;
            padding:  0;
            text-align: left;
            z-index: 3;
            margin-top: -15px;
            border-radius: 0 5rem 0 0;
            font-size: 0.9rem;
            line-height: 1rem;
            
            &::before{
                content: '';
                display: block;
                background-image: url('/elektrizate/score_top_decoration.png');
                background-size: 100% 100%;
                position: absolute;
                top: -49px;
                height: 50px;
                width: 60px;
                left: 0;
              
            }

            &-scroll{
                overflow-y: auto;
                height: calc(100vh - 280px);
                padding: 1.5rem;
                padding-bottom: 0;
                border-radius: 0 5rem 0 0;
              
            }

        }
        
    }   
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        background-color: #f1f1f1;
        width: 32%;
        border-radius: 100px;
        line-height: 0.9rem;
        &-wrap-icon{
            background-color: #c9ff66;
            border-radius: 100px;
            padding: 1rem 0rem;
            box-shadow: 3px 3px 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        &-client{
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 800;
            margin: 0;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        &-user{
            color: #6700ce;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            padding-left: 0.75rem;
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        &-title{
            font-size: 0.60rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &-x{
            padding-right: 0.5rem;
            padding-left: 0.5rem;

        }
    }
}
</style>