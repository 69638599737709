<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../http'
import ModalVue from '../components/ModalVue.vue'
export default {
	components: {
		ModalVue,
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
				address: '',
				state: '',
				city: '',
			}
		}
	},
	computed: {
		calcGoal() {
			if(!this.user) return {
				next: 0,
				total: 0,
				goals: 0
			}

			let data = {
				next_goal: (Math.floor(this.user.daily_activations/this.user.daily_goal) + 1) * this.user.daily_goal  ,
				total: this.user.daily_activations,
				daily_goals: Math.floor(this.user.daily_activations/this.user.daily_goal)
			}

			data.goals = data.total - data.next_goal + this.user.daily_goal
			data.stats = Math.floor((data.goals /  this.user.daily_goal) * 100)

			return data
			return (this.user.daily_activations/this.user.daily_goal) * 100
		},
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			productBySku: 'vault/productBySku',
			product: 'vault/product',
			newNotifications: 'auth/newNotifications'
		})
	},
	methods: {
		logout() {
			// window.authToken = null
			// window.user = null
			// window.wallet = null
			localStorage.clear()
			sessionStorage.clear()
			window.location.href = '/#/'
			window.location.reload()
		},
		getShippingCost(price) {
			return 0
			if(price <= 1000) {
				return 1;
			} else if(price <= 5000) {
				return 2;
			} else {
				return 3;
			}
		},
	
		calcGems(price) {
			let shippingCost = this.getShippingCost(price)
			return Math.ceil(price / 102) + shippingCost
		},
		upOrder() {
			this.$refs.upOrder.open()
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchNotifications: 'auth/fetchNotifications'

		})
	}, 
	mounted() {
		this.fetchUser()
		// this.fetchNotifications()
	}
}
</script>
<template>
	<div class="score" v-if="user">
		<div class="score__content">
			<div class="score__wellcome">
				<div class="text-white">
					<div class="vault__header-wrapper flex-wrap text-center w-100 pb-3">
						<h6  class="w-100 text-center m-0 fw-bold">
							<span class="me-1">Mi perfil</span>
						</h6>
						
					</div>
				</div>
			</div>
			<div v-if="wallet && (user.role_id != 6 || user.role_id != 2)" id="step5" class="mt-5 relative score__wallet" :class="{ 
				'score__step-active ': currentStep == 5
				}">

				<div v-if="wallet && (user.role_id != 6 || user.role_id != 2)" class="score__coinsn">
					<img src="/assets/v2/diamante_verde.png" alt="" style="width: 25px;margin-left: -25px;">
					<span class="score__coinsn-text">{{wallet.balance}}</span>
					<!-- <img class="score__coinsn-bg" src="/assets/pantalla_perfil_puntaje/conteo_monedas.png" alt=""> -->
				</div>

				<p class="m-0 text-center score__wallet-text">
					Tienes <span class="fw-bold" v-if="wallet && (user.role_id != 6 || user.role_id != 2)">{{wallet.balance}} gemas</span> canjeables en <span class="text-success">bóveda <span class="fw-bold">baz</span></span>
				</p>

				<div class="score__wallet-bg">
					<img src="/assets/v2/wallet_bg.png" alt="">
				</div>

				<!-- <div class="score__step-tip step__5" >
					<img src="/assets/steps/5.png" alt="">
				</div> -->
			</div>
			<div class="px-2">
				<div class="d-flex flex-wrap">
					<div class="col-12 p-1">
						<div class="box p-2 h-100 text-center" @click="$router.push({name: 'Notifications'})">
							<div class="d-flex align-items-center small">
								<div class="menu-item__icon m-0">
									<img src="/assets/menu/notification.png" alt="">
								</div>

								<p class="m-0 fw-bold small ms-4 flex-grow-1 text-start" v-if="newNotifications.length">
									Tienes {{newNotifications.length}} {{newNotifications.length > 1 ? 'notificaciones' : 'notificación'}} nuevas
								</p>
								<p class="m-0 fw-bold small ms-4 flex-grow-1 text-start" v-else>
									Historial de notificaciones
								</p>
								
							</div>
						</div>
					</div>
					<div class="col-12 p-1">
						<div v-if="user.role_id == 3" class="box p-3 py-4">
							<h2 class="title text-center mb-3" v-if="calcGoal.next_goal < user.daily_goal">¡Vamos! Tú puedes lograrlo</h2>
							<h2 class="title text-center mb-3" v-if="calcGoal.next_goal > user.daily_goal">¡Wow! ya ganaste {{calcGoal.daily_goals}} Gemas sigue así</h2>
							<div class="score__progress-bar">
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special bg-no-animate" role="progressbar" :style="{
										width: `${calcGoal.stats}%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<div class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
								</div>
							</div>
							<div class="score__progress pt-3 small">
								<div>
									<!-- <h5 class="fw-bold" >
										<span v-if="calcGoal > 100" :class="{'text-success': calcGoal > 100}">+</span>
										{{calcGoal}}% 
									</h5> -->
									<h5 class="fw-bold" >
										{{calcGoal.stats}}%  para ganar
									</h5>
									<p class="m-0">la {{calcGoal.daily_goals + 1}}° gema del día</p>
								</div>
								
								<div class="text-end">
									<h5 class="fw-bold">
										{{calcGoal.goals}}/{{user.daily_goal}}
									</h5>
									<p class="m-0">Activaciones</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-6 col-md-6 p-1 ">
						<div class="box p-3 h-100 text-center" @click="$router.push({name: 'Score'})">
							<div class="menu-item__icon">
								<img src="/assets/menu/score.png" alt="">
							</div>
							<h6 class="m-0 fw-bold small">
								Score
							</h6>
						</div>
					</div>
					<div class="col-6 col-md-6 p-1">
						<div class="box p-3 h-100 text-center" @click="$router.push({name: 'Rewards'})">
							<div class="menu-item__icon">
								<img src="/assets/menu/rewards.png" alt="">
							</div>
							<h6 class="m-0 fw-bold small">
								Premios canjeados
							</h6>
						</div>
					</div>
					<div class="col-6 col-md-6 p-1">
						<div class="box p-3 h-100 text-center"  @click="$router.push({name: 'Vault'})">
							<div class="menu-item__icon">
								<img src="/assets/menu/vault.png" alt="">
							</div>
							<h6 class="m-0 fw-bold small">
								Bóveda
							</h6>
						</div>
					</div>
					<div class="col-6 col-md-6 p-1">
						<div class="box p-3 h-100 text-center" @click="$router.push({name: 'Help'})">
							<div class="menu-item__icon">
								<img src="/assets/menu/help.png" alt="">
							</div>
							<h6 class="m-0 fw-bold small">
								Ayuda
							</h6>
						</div>
					</div>
					
					<!-- <div class="col-12 text-center p-1">
						<button @click="logout" class="btn btn-danger fw-bold text-white">CERRAR SESIÓN</button>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.score__coinsn{
	z-index: 10;
}
.box{
	border-radius: 1rem;
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
.menu-item__icon{
	margin-bottom: 0.5rem;
	img{
		display: block;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-left: auto;
		margin-right: auto;
	}
}
</style>