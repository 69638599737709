<script>
import { mapGetters } from 'vuex'
import http from '../http'

export default {
    data(){
        return {
            ticket: false
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
		}),
        ticketId(){
            let vm = this
            return vm.$route.params.ticket_id
        }
    },
    async mounted(){
        let vm = this
        if(vm.ticketId){
            await http.support.getCommentsTicker(vm.ticketId).then((response) => {
                vm.ticket = response;
            });
        }
    }
}
</script>

<template>
    <div>
        <div class="score__wellcome text-center pb-3">
            <h3 class="title m-0 text-white">
                Módulo de tickets <br/> de soporte
            </h3>
        </div>
        <div v-if="ticket" class="score">
            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-12 p-1">
                    <div class="box p-3 h-100 ">
                        <h6 class="m-0 text-center mb-2"> 
                            <span class="fw-bold"> Ticket con folio: </span> {{ticket.folio}}
                        </h6>
                        <div v-for="(comment,index) in ticket.comments" class="d-flex border-top py-2 mx-n3" :key="`tickets_messages_${index}`">
                            <div class="col-12 height-95 px-2">
                                <p class="m-0 mb-1 text-ellipsis fw-bold small">
                                    <span v-html="comment.user"></span> dijo:
                                </p>

                                <div v-html="comment.content" class="small m-0 mb-1 text-black-50">
                                    
                                </div>
                                <small class="text-black-50 fst-italic">
                                    {{ comment.time_ago }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>