import CryptoJS  from 'crypto-js'

export const decript = (token) => {
    try {
        var ciphertext = CryptoJS.enc.Hex.parse(token);
        var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("RaG&j!9G2zczJbka"));
        var key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
        let text = pwhash.toString(CryptoJS.enc.Hex).substr(0, 32)
        var decrypted = CryptoJS.AES.decrypt({
            ciphertext: ciphertext
        }, key, {
            mode:     CryptoJS.mode.ECB,
            padding:  CryptoJS.pad.Pkcs7
        });
    
        var plaintext = decrypted.toString(CryptoJS.enc.Utf8);
    
        return plaintext
    } catch (error) {
        console.warn(error)
        return null        
    }
}

export const  encrypt = (plaintext) => {
    try {
        const pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("RaG&j!9G2zczJbka"));
        const key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
        const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    } catch (error) {
        console.warn(error)
        return null   
    }
  };