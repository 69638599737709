import http from '../../http'

export const fetchRegionReferals = async ({commit}, payload = {limit: 10}) => {
	let regionReferals = localStorage.getItem('regionReferals')
	// let regionReferals = window.regionReferals

	if(regionReferals) {
		regionReferals = JSON.parse(regionReferals)
		commit('setRegionReferals', regionReferals)
	}
	try {
		let stats = await http.stats.regionReferals()
		commit('setRegionReferals', stats)
		return Promise.resolve(stats)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchAgencyReferals = async ({commit}, payload = null) => {
	let agencyReferals = localStorage.getItem('agencyReferals')
	// let agencyReferals = window.agencyReferals

	if(agencyReferals) {
		agencyReferals = JSON.parse(agencyReferals)
		commit('setAgencyReferals', agencyReferals)
	}
	if(payload) {
		commit('setAgencyReferals', [])
	}
	try {
		let stats = await http.stats.agencyReferals(payload)
		commit('setAgencyReferals', stats)
		return Promise.resolve(stats)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchTerritoryReferals = async ({commit}, payload = {limit: 10}) => {
	let territoryReferals = localStorage.getItem('territoryReferals')
	// let territoryReferals = window.territoryReferals

	if(territoryReferals) {
		territoryReferals = JSON.parse(territoryReferals)
		commit('setTerritoryReferals', territoryReferals)
	}
	try {
		let stats = await http.stats.territoryReferals()
		commit('setTerritoryReferals', stats)
		return Promise.resolve(stats)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchTerritoryReferalsById = async ({commit}, id) => {
	let territoryReferals = localStorage.getItem('territoryReferals')
	// let territoryReferals = window.territoryReferals

	if(territoryReferals) {
		territoryReferals = JSON.parse(territoryReferals)
		commit('setTerritoryReferals', territoryReferals)
	}
	try {
		let stats = await http.stats.territoryReferalsById(id)
		commit('setTerritoryReferals', stats)
		return Promise.resolve(stats)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchTerritories = async ({commit}, payload = {limit: 10}) => {
	
	try {
		let data = await http.territories.list()

		commit('setTerritories', data)
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchHistoryTransactions = async ({commit}, payload = {page: 1}) => {
	try {
		let data = await http.challenges.historyTransactions(payload)
		commit('setHistoryTransactions', data)
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}