<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../http'
export default {
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			notifications: 'auth/notifications',
			newNotifications: 'auth/newNotifications',
		})
	},

	watch: {
		newNotifications: {
			handler(nVal) {
				this.markViewed()
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		markViewed() {
			let ids  = this.newNotifications.map(n => n.id)
			http.notifications.viewed({
				notifications: ids
			})
		},
		...mapActions({
			fetchNotifications: 'auth/fetchNotifications'
		})
	},

	mounted() {
		let vm = this
		vm.fetchNotifications().then(res => {
			vm.loading = false
		})
	}
}
</script>
<template>
	<div class="ranking" v-if="user">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<h5 class="m-0 w-100 text-center mb-2">Notificaciones</h5>
				</div>
			</div>
		</div>

		<div class="ranking__wrappers mt-3 px-2">
			<div class="start--show" v-if="!notifications.length">
				<div class="start__top">
					<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				</div>
			</div>
			<div v-if="notifications.length">
				<div v-for="(notification, index) in notifications" :key="`n_${index}_${notification.id}`" class="box p-3 my-2 ">
					<h6 class="fw-bold small">{{notification.title}}</h6>
					<p class="small mb-0">{{notification.description}}</p>
					<p class="small mb-0">
						<span class="small text-muted">{{notification.created_at | moment("DD-MMMM-YYYY H:mm A")}}</span>
					</p>
				</div>
			</div>
			<div v-else  class="box px-0">
				
				<div class="px-2">
					<h6 class="text-center m-0" v-if="loading">Buscando notificaciones...</h6>
					<h6 class="text-center m-0" v-else>No tienes notificaciones.</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>