<script>
import http from '../../http'
export default {
    data() {
        return {
            ranking: {
                current_page: 0,
                last_page: 10000,
                data: []
            },
            rankID: 0
        }
    },
    methods: {
        async fetchRanking(page = 1) {
            try {
                let data = await http.challenges.ranking(page)
                this.$set(this, 'ranking', data)
            } catch (error) {
                
            }
        },
        async nextPage() {
            await this.fetchRanking(this.ranking.current_page +  1)
        },
        async prevPage() {
            await this.fetchRanking(this.ranking.current_page -  1)
        },
        selectRank(rank) {
            this.$set(this, 'rankID', rank)
        }
    },
    mounted() {
        this.fetchRanking()
    }
}
</script>
<template>
    <div>
        <div v-for="(rank, index) in ranking.data" class="small mb-2 table-item">
            <div>
               
                <p class="m-0">{{ rank.user.name }}</p>
                <div class="d-flex justify-content-between col-12">
                    <div class="text-center">
                        <p class="m-0 text-primary fw-bold">
                            N° {{ rank.position }}
                        </p>
                        <p class="small m-0"><span class="small">Posición</span></p>
                    </div>
                    <div class="text-center">
                        <p class="m-0 text-primary fw-bold">
                            {{ rank.count }}
                        </p>
                        <p class="small m-0"><span class="small">Activaciones</span></p>
                    </div>
                    <div class="text-center px-2">
                        <p class="m-0 text-primary fw-bold">
                            {{ rank.user.code }}
                        </p>
                        <p class="small m-0"><span class="small">Colaborador</span></p>
                    </div>
                </div>
                <div class="text-center" v-if="rankID != rank.id">
                    <button class="btn btn-sm small" @click="selectRank(rank.id)"><span class="small">Detalles ></span></button>
                </div>
                <div v-if="rank.id == rankID" class="mt-2">
                    <hr>
                    <p class="small m-0">Agencia: {{ rank.user.agency.name }}</p>
                    <p class="small m-0">Region: {{ rank.user.region.name }}</p>
                    <p class="small m-0">Territorio: {{ rank.user.territory.name }}</p>

                    <div class="text-center">
                        <button class="btn btn-sm small" @click="selectRank(0)"><span class="small">Ocultar</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center d-flex justify-content-center">
            <button @click="prevPage" class="btn btn-primary mx-1" :disabled="ranking.current_page == 1"><</button>
             
            <button @click="nextPage" class="btn btn-primary mx-1" :disabled="ranking.current_page == ranking.last_page">></button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.table-item{
    width: 100%;
    border-radius: 0.25rem;
    background: #fff;
    border: 1px solid #eee;
    padding: 0.15rem 1rem;
}
.table-position{
    width: 50px;
    min-width: 50px;
}
</style>