import Vue from 'vue'

export const setUser = (state, payload) => {
	
    Vue.set(state, 'user', payload)
	// window.user = payload
	sessionStorage.setItem('user', JSON.stringify(payload))
}

export const setToken = (state, payload) => {
	
    Vue.set(state, 'token', payload)
	// let expiry = new Date();   
	
	// expiry.setTime(expiry.getTime()+(10000*60*1000)); 
	// document.cookie = "auth-token="+payload + "; expires=" + expiry.toGMTString();
	// window.authToken = payload
	localStorage.setItem('token', payload)
}

export const setWallet = (state, payload) => {
    Vue.set(state, 'wallet', payload)
	// window.wallet = payload
	sessionStorage.setItem('wallet', JSON.stringify(payload))

}

export const reset = (state) => {
	Vue.set(state, 'token', null)
	Vue.set(state, 'wallet', null)
	Vue.set(state, 'user', null)
}

export const setLoginFailed = (state, payload) => {
	Vue.set(state, 'loginFailed', payload);
}

export const setNotifications = (state, payload) => {
	Vue.set(state, 'notifications', payload);
}

export const setMaterials = (state, payload) => {
	Vue.set(state, 'materials', payload);
	// window.materials = payload
	localStorage.setItem('materials', JSON.stringify(payload))
}

export const setMaterial = (state, payload) => {
	Vue.set(state, 'material', payload);
}

export const setHasUpdate = (state, payload) => {
	Vue.set(state, 'hasUpdate', payload);
}
