import state from "./state";

export const regionReferalsStats = (state) => (id) => {
	let stats = {
		total_referals: 0,
		me_referals: 0,
		position: state.regionReferals.findIndex(u => u.id == id) + 1 || 0,
		count: state.regionReferals.length || 0
	}

	state.regionReferals.forEach(u => {
		stats.total_referals += u.total_referals

		if(u.id == id) {
			stats.me_referals = u.total_referals
		}
	});

	return stats
}

export const regionReferals = (state) => state.regionReferals
export const territoryReferals = (state) => state.territoryReferals
export const agencyReferals = (state) => state.agencyReferals
export const territoryReferalsStats = (state) => (id) => {
	let stats = {
		total_referals: 0,
		me_referals: 0,
		position: state.territoryReferals.findIndex(u => u.id == id) + 1 || 0,
		count: state.territoryReferals.length || 0
	}

	state.territoryReferals.forEach(u => {
		stats.total_referals += u.total_referals

		if(u.id == id) {
			stats.me_referals = u.total_referals
		}
	});

	return stats
}

export const leaderTerritoryReferalsStats = (state) => {

	let stats = {
		total_referals: 0,
		success: 0,
		daily_goal: 0,
		daily_activations: 0,
		weekly_activations: 0,
		count: state.territoryReferals.length || 0,
		sumary: 0,
		gems_earned: 0,
		gems_spent: 0,
		daily_earned: 0,
		daily_spent: 0,
	}

	state.territoryReferals.forEach(u => {
		// stats.daily_goal += u.daily_goal 
		stats.daily_activations += u.daily_activations
		stats.sumary += u.total_referals
		if(u.daily_activations >= u.daily_goal) {
			stats.success += 1
		}
		// stats.gems_earned += u.weekly_gems.earned
		// stats.gems_spent += u.weekly_gems.spent
		// stats.daily_earned += u.daily_gems.earned
		// stats.daily_spent += u.daily_gems.spent

		stats.total_referals += u.daily_activations
		stats.weekly_activations += u.weekly_activations
	});

	return stats
}

export const corpTerritoryReferalsStats = (state) => {

	let stats = {
		total_referals: 0,
		success: 0,
		daily_goal: 0,
		daily_activations: 0,
		count: state.territoryReferals.length || 0,
		sumary: 0,
		gems_earned: 0,
		gems_spent: 0,
		daily_earned: 0,
		daily_spent: 0,
	}

	state.territoryReferals.forEach(u => {
		stats.daily_goal += u.daily_goal 
		stats.daily_activations += u.daily_activations
		stats.sumary += u.total_referals
		if(u.daily_activations >= u.daily_goal) {
			stats.success += 1
		}
		stats.gems_earned += u.weekly_gems.earned
		stats.gems_spent += u.weekly_gems.spent
		stats.daily_earned += u.earned
		stats.daily_spent += u.spent

		stats.total_referals += u.daily_activations
		
	});

	return stats
}

export const agencyReferalsStats = (state) => {

	let stats = {
		total_referals: 0,
		success: 0,
		daily_goal: 0,
		daily_activations: 0,
		count: state.agencyReferals.length || 0,
		sumary: 0,
		gems_earned: 0,
		gems_spent: 0,
		daily_earned: 0,
		daily_spent: 0,
		weekly_activations: 0
	}

	state.agencyReferals.forEach(u => {
		stats.daily_goal += u.daily_goal 
		stats.daily_activations += u.daily_activations
		stats.sumary += u.total_referals
		if(u.daily_activations >= u.daily_goal) {
			stats.success += 1
		}

		stats.gems_earned += u.weekly_gems.earned
		stats.gems_spent += u.weekly_gems.spent
		stats.daily_earned += u.daily_gems.earned
		stats.daily_spent += u.daily_gems.spent

		stats.total_referals += u.daily_activations
		stats.weekly_activations += u.weekly_activations
	});

	return stats
}

export const leaderRegionReferalsStats = (state) => {

	let stats = {
		total_referals: 0,
		success: 0,
		daily_goal: 0,
		daily_activations: 0,
		count: state.regionReferals.length || 0,
		sumary: 0,
		gems_earned: 0,
		gems_spent: 0,
		daily_earned: 0,
		daily_spent: 0,
		weekly_activations: 0
	}

	state.regionReferals.forEach(u => {
		stats.daily_goal += u.daily_goal 
		stats.daily_activations += u.daily_activations
		stats.sumary += u.total_referals
		if(u.daily_activations >= u.daily_goal) {
			stats.success += 1
		}
		stats.gems_earned += u.weekly_gems.earned
		stats.gems_spent += u.weekly_gems.spent
		stats.daily_earned += u.daily_gems.earned
		stats.daily_spent += u.daily_gems.spent
		
		stats.total_referals += u.daily_activations
		stats.weekly_activations += u.weekly_activations
	});

	return stats
}

export const territories = (state) => state.territories
export const territoryById = (state) => (id) => {
	return state.territories.find(t => t.id == id)
}
export const historyTransactions = (state) => {
	return state.historyTransactions
}