<script>
import Loader from '@/components/Loader.vue'
import ElektrizateMenuNav from '@/components/Elektrizate/MenuNav.vue'
import SessionFail from '@/views/SessionFail.vue'
import {decript} from '@/utils/decript'
import  {mapActions, mapGetters} from 'vuex'
import http from '@/http'
import ModalVue from '@/components/ModalVue.vue'

export default {
    components: {
		Loader,
		SessionFail,
		ModalVue,
		ElektrizateMenuNav,
	},
    data() {
        return {
            showMenu: false,
			loading: true,
			support: false,
            roles: [
                {
                    id: 3,
                    name: 'Activador'
                },
                {
                    id: 5,
                    name: 'Impulsor'
                },
                {
                    id: 4,
                    name: 'Promotor'
                },
            ]
        }
    },
    computed: {
        headerInterfaceActive() {
			return this.$route.meta.headerInterface
		},
        ...mapGetters({
            'authUser': 'auth/user',
            user: 'auth/user',
			hasUpdate: 'auth/hasUpdate'
        })
    },
    methods: {
		handlerShowMenu() {
			this.$router.push({name: 'Menu'})
		},
		hiddenLoading() {
			let vm = this
			this.$refs.splashLoader.handlerHide()
			setTimeout(() => {
				vm.loading = false
			})

		},

		...mapActions({
			login: 'auth/login',
			fetchUser: 'auth/fetchUser',
			fetchMaterials: 'auth/fetchMaterials',
		})
	},
    async mounted () {
        let token = this.$route.query.hasOwnProperty('code') ? this.$route.query.code : null
		let code = localStorage.getItem('code') || '77777'
		let goToScore = false
		if(token) {
			let parse = decript(token)
			// goToScore = parse == code
			code = parse || code
		} 
		code = '77777'
		if(!code) {
			return
		}
		let auth = await this.login({code})
		
		if(!auth) {
			return alert('NO LOGING')
		}

		let user = await this.fetchUser()
		let material = await this.fetchMaterials()

		// if(!user.tour_complete) {
		// 	return this.$router.push({name: 'ElektrizateTourIntro'})
		// }
		if(goToScore) {
			this.$router.push({name: 'Score'})
		}
    }
}
</script>
<template>
    <div class="elektra-start">
        <div class="elektra-start__up">
            <div class="elektra-start__logo-wrap">
                <img class="elektra-start__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                <img class="elektra-start__sun" src="/elektrizate/sun.svg" alt="">
                <img class="elektra-start__sun2" src="/elektrizate/sun.svg" alt="">
            </div>
        </div>
        <div class="elektra-start__down">
            <div class="elektra-start__iso-wrap">
                <img class="elektra-start__iso" src="/elektrizate/iso_elektrizate.svg" alt="">
            </div>
            <div class="elektra-start__button">
                <router-link v-if="authUser" :to="{name: 'Home'}" class="elektra-btn elektra-btn--lg ">INICIAR</router-link>
                <p v-else>Cargando...</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.elektra-btn--lg {
    width: 55%;
}
.elektra-start{
    color: #fff;
    background-image: url('/elektrizate/start_bg.png');
    position: absolute;
    height: 100vh;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size:100% 100%;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.elektra-start{
    display: flex;
    flex-direction: column;
    &__button{
        text-align: center;
        padding-bottom: 2rem;
    }
    &__up{
        height: 45vh;
        flex-grow: 1;
        position: relative;
    }
    &__down{
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__logo{
        max-width: 70%;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        bottom: 35%;
        left: 15%;
        z-index: 2;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
            top: -10%;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }

   
}
</style>