<script>
import { mapActions, mapGetters } from 'vuex'
import ChallengesTransactions from '../../components/Elektrizate/ChallengesTransactions.vue';
export default {
    components: {
        ChallengesTransactions,
    },
    data() {
        return {
            referalChallenges: [
                {
                    id: 1,
                    title: 'Recarga celular',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'recarga.svg'
                },
                {
                    id: 2,
                    title: 'Pago de servicios',
                    client_bonus: 50,
                    user_bonus: 1,
                    icon: 'pago_servicio.svg'
                },
                {
                    id: 1,
                    title: 'Pago de crédito',
                    client_bonus: 30,
                    user_bonus: 1,
                    icon: 'pago_credito.svg'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user',
            'authWallet': 'auth/wallet'
        })
    },
    methods: {
        ...mapActions({
            fetchUser: 'auth/fetchUser',
        })
    },
    async mounted() {
        await this.fetchUser()
        if(!this.authUser.accepted_terms) {
            this.$router.push({name: 'ElektrizateAcceptTerms'})
        }
    }
}
</script>
<template>
    <div class="elektra-score elektra-scroll-y">
        <div class="elektra-score__up">
            <div class="elektra-score__header">
                <div class="elektra-score__badge">
                    <img class="elektra-score__sun" src="/elektrizate/sun.svg" alt="">
                    <div class="elektra-score__sun2">
                        <img class="elektra-score__sun" src="/elektrizate/sun.svg" alt="">
                    </div>
                    <img class="elektra-score__iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <img class="elektra-score__base-iso" src="/elektrizate/base-iso.svg" alt="">
                </div>
                <div class="elektra-score__user">
                    <p class="elektra-socre__title m-0 ">¡BIENVENIDO!</p>
                    <p class="m-0">{{ authUser.name }}</p>
                    <p class="m-0">{{ authUser.work_position }}</p>
                    <p>{{ authUser.code }}</p>
                </div>
            </div>
            <div class="elektra-score__wallet">
                <div class="elektra-score__wallet-wrap"> 
                    <img class="elektra-score__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <p class="elektra-score__wallet-balance">{{ authWallet.balance }}</p>
                </div>
            </div>
        </div>
        <div class="elektra-score__content">
            <div class="elektra-score__content-top">
                
            </div>
            <ChallengesTransactions :challenges="referalChallenges" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.elektra-score{
    background-color: #fff;
    height: 100vh;
    padding-bottom: 70px;
    &__up{
        position: relative;
        height: 40vh;
        min-height: 406px;
    }
    &__header{
        padding-top: 50px;
        width: 100%;
        height: 40vh;
        background-image: url('/elektrizate/bg_header_score.png');
        background-size: 100% 100%;
        position: relative;
        min-height: 406px;
        z-index: 2;
        user-select: none;
        pointer-events: none;
        .elektra-socre__title{
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
    &__user{
        position: absolute;
        bottom: 16%;
        padding: 1rem;
        padding-left: 2.5rem;
    }
    &__badge{
        width: 50%;
        position: relative;
        margin-left: auto;
    }
    &__sun2{
        position: absolute;
        transform: scale(1.5);
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    &__sun{
        animation: sun 220s infinite alternate;
    }
    &__iso{
        position: absolute;
        z-index: 3;
        width: 60%;
        left: 20%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__wallet{
        background-color: #954cdd;
        height: 160px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        padding-top: 75px;
        padding-left: 60px;
        &-wrap{
            display: flex;
            align-items: center;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            font-weight: 600;
            font-size: 0.9rem;
            margin-top: -6px;
            margin-bottom: 0;
        }
    }
    &__content{
        color: #000;
        &-top{
            position: relative;
            background-color: #fff;
            padding: 1.5rem;
            text-align: center;
            z-index: 3;
            margin-top: -30px;
            border-radius: 0 20% 0 0;
            font-size: 0.9rem;
            line-height: 1rem;
            &::before{
                content: '';
                display: block;
                background-image: url('/elektrizate/score_top_decoration.png');
                background-size: 100% 100%;
                position: absolute;
                top: -49px;
                height: 50px;
                width: 60px;
                left: 0;

            }

        }
        
    }   
    &__challenges{
        display: flex;
        justify-content: space-between;
    }
    &__challenge{
        background-color: #f1f1f1;
        width: 32%;
        border-radius: 100px;
        line-height: 0.9rem;
        &-wrap-icon{
            background-color: #c9ff66;
            border-radius: 100px;
            padding: 1rem 0rem;
            box-shadow: 3px 3px 0 1px rgba($color: #000000, $alpha: 0.1);
        }
        &-client{
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 800;
            margin: 0;
            padding-bottom: 0.5rem;
            text-align: center;
        }
        &-user{
            color: #6700ce;
            font-size: 0.7rem;
            padding: 0 0.5rem;
            padding-left: 0.75rem;
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        &-title{
            font-size: 0.60rem;
            font-weight: 600;
            text-align: center;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
        &-x{
            padding-right: 0.5rem;
            padding-left: 0.5rem;

        }
    }
}
</style>