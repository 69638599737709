<script>
export default {
    props: {
        title: {
            type: String
        },
        now: {
            type: [Number, String],
            default() {
                return 0
            }
        },
        total: {
            type: [Number, String],
            default() {
                return 0
            }
        },
        weekly: {
            type: [Number, String],
            default() {
                return 0
            }
        },
        weekly_goal: {
            type: [Number, String],
            default() {
                return 0
            }
        },
        route: {
            type: String
        },
        id: {
            type: [Number, String]
        },
        activators: {
            type: [Number, String]
        }
    },
    methods: {
        goTo(data) {
			this.$router.push(data)
		},
    }
}
</script>
<template>
    <div @click="goTo({name: route, params: {id: id}})" class="box d-block px-3 py-2 mb-2 "  >
        <h6 class="fw-bold mb-0 py-2 text-center small">
            {{ title }}
        </h6>
        
        <div class="m-0 small d-flex flex-wrap  justify-space-between">
            <div class="col-12">
            </div>
            <div class="text-center col-4">
                <span class="text-primary fw-bold ms-1">{{now || 0}}</span>
                <span class="fw-bold d-block w-100 small">Hoy</span>
            </div>
            <div class="text-center col-4 mx-auto">
                <span class="text-primary fw-bold ms-1">{{weekly || 0}}</span>
                <span class="fw-bold d-block w-100 small">Semanales</span>
            </div>
            <span class="text-center col-4">
                <span class="text-primary fw-bold ms-1">{{total}}</span>
                <span class="fw-bold d-block w-100 small">Totales</span>
            </span>
        </div>
        <div class="m-0 small d-flex flex-wrap mt-2 border-top pt-2">
            <div class="text-center  small">
                <span class="fw-bold  w-100 small">Activadores</span>
                <span class="text-primary fw-bold ms-1">{{activators}}</span>
            </div>
            <div class="text-center ms-auto small">
                <span class="fw-bold  w-100 small">Meta semanal</span>
                <span class="text-primary fw-bold ms-1">{{weekly_goal}}</span>
            </div>
        </div>
    </div>
</template>