import Vue from 'vue'

export const setRegionReferals = (state, payload) => {
	Vue.set(state, 'regionReferals', payload)
	localStorage.setItem('regionReferals', JSON.stringify(payload))
	// window.regionReferals = payload
}

export const setTerritoryReferals = (state, payload) => {
	Vue.set(state, 'territoryReferals', payload)
	localStorage.setItem('territoryReferals', JSON.stringify(payload))
	// window.territoryReferals = payload


}

export const setTerritories = (state, payload) => {
	
	Vue.set(state, 'territories', payload)
	localStorage.territories = JSON.stringify(payload)
	// window.territoryReferals = payload
}

export const setAgencyReferals = (state, payload) => {
	Vue.set(state, 'agencyReferals', payload)
	localStorage.setItem('agencyReferals', JSON.stringify(payload))
	// window.agencyReferals = payload

}
export const setHistoryTransactions = (state, payload) => {

	if(state.historyTransactions && payload.current_page > 1) {
		let data = [...state.historyTransactions.data, ...payload.data]
		Vue.set(state, 'historyTransactions', payload)
		Vue.set(state.historyTransactions, 'data', data)
		
	} else {
		Vue.set(state, 'historyTransactions', payload)
	}
	// window.agencyReferals = payload
}