<script>
import { mapActions, mapGetters } from 'vuex'
import ModalVue from '../components/ModalVue.vue'
import http from '../http'


export default {
    components:{ModalVue},
    data(){
        return {
            form:{
                comments:'',
                topic:''
            },
            errors:{
                comments:false,
                topic:false
            },
            response: '',
            openModal: false,
            loading:false
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
		}),
        chars(){
            let vm = this
            return vm.form.comments.length;
        }
    },
    methods:{
        go(name) {
			this.$router.push({name: name})
		},
        limitChar(event){
            event.target.value = event.target.value.substring(0, 199);
        },
        async submit(event){
            let vm = this
            vm.errors.topic = vm.form.topic == ''
            vm.errors.comments = vm.form.comments == ''

            if(!vm.errors.comments && !vm.errors.topic){
                vm.loading = true;
                await http.support.createTicket(vm.user.id, vm.form).then((response) => {
                    vm.form.topic = ''
                    vm.form.comments = ''

                    vm.errors.topic = ''
                    vm.errors.comments = ''

                    vm.response = response;
                    vm.openModal = true;
                });


            }
        }
    }
}
</script>
<template>
    <div  class="score">
        <div class="score__wellcome text-center pb-3">
            <h3 class="title m-0 text-white">
                Levantar un nuevo ticket <br/> de soporte
            </h3>
        </div>
        <div class="score pb-5">
            <div class="bg-primary text-white p-2 small text-center py-3" role="alert">
                <!-- <p>¡Atención!</p>
                <p class="m-0">
                    Hola, tenemos un apartado de preguntas frecuentes, que puedes consultar <a class="text-underline text-white" @click="go('questions')">aquí</a>,
                    en caso de que tu duda no este resuelta en este apartado, por favor escribenos
                    a continuación, el tiempo promedio de respuesta es de 2 a 3 días.
                </p> -->
                <p class="m-0">¡Nuevo ticket!</p>
            </div>
            <div v-if="!loading" class="px-3">
                <div  class="d-flex flex-wrap box mt-3 px-3">
                    <div class="col-12">
                        <div class="vault__search flex-wrap mt-1">
                            <label class="w-100 d-block ms-2" for="">Número de empleado</label>
                            <div class="vault__search-input">
                                <input type="text" disabled readonly :value="user.code">
                            </div>
                        </div>
                        <p class="small px-3 fw-bold text-primary"></p>
                    </div>
                    <div class="col-12">
                        <div class="vault__search flex-wrap mt-1">
                            <label class="w-100 d-block ms-2" for="">Nombre</label>
                            <div class="vault__search-input">
                                <input type="text" disabled readonly :value="user.name">
                            </div>
                        </div>
                        <p class="small px-3 fw-bold text-primary"></p>
                    </div>
                    <div class="col-12">
                        <div class="vault__search flex-wrap mt-1">
                            <label class="w-100 d-block ms-2" for="">Tema</label>
                            <div class="vault__search-input">
                                <select required class="bg-white" v-model="form.topic">
                                    <option value="">Selecciona un tema</option>
                                    <option value="game">Juego de Gemas</option>
                                    <option value="baz">Baz superapp</option>
                                </select>
                            </div>
                        </div>
                        <p class="small px-3 fw-bold text-primary" v-if="errors.topic">Por favor selecciona un tema</p>
                    </div>
                    <div class="col-12">
                        <div class="vault__search flex-wrap mt-1">
                            <label class="w-100 d-block ms-2" for="">Comentarios</label>
                            <div class="vault__search-input">
                                <textarea class="py-3" required @keydown="limitChar($event)" v-model="form.comments" rows="4"></textarea>
                            </div>
                            <small class="count-characters">{{chars}}/200</small>
                        </div>
                        <p class="small px-3 fw-bold text-primary mt-4" v-if="errors.comments">Por favor añade tus comentarios</p>
                    </div>

                    <div class="col-12 text-end py-3 mt-4">
                        <button @click="submit($event)" class="btn btn-primary btn--rounded fw-bold">
                            Enviar ticket
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="loading" class="ranking__wrappers mt-3 px-2">
                <div class="start--show">
                    <div class="start__top">
                        <img src="/assets/pantalla_inicio/robot_grande_central.png" alt="" class="start__robot start__robot--front">
                    </div>
                </div>
                <div class="box px-0">
                    <div class="px-2">
                        <div class="d-flex flex-wrap">
                            <div class="mx-auto text-primary">
                                <div class="lds-ripple"><div></div><div></div></div>
                            </div>
                        </div>
                        <h6 class="text-center m-0">
                            Enviando...
                        </h6>
                    </div>
                </div>
            </div>

            <modal-vue ref="responseModal" :opening="openModal" :can-close="false">
                <template v-slot:header>
                    <h5 class="m-0">¡Mensaje enviado!</h5>
                </template>
                <template v-slot:body>
                    <div class="text-center py-3" v-html="response"></div>
                    <div class="py-2 d-flex">
                        <button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="go('Support')">Aceptar</button>
                    </div>
                </template>
            </modal-vue>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}

.height-65{
    height: 65px;
    overflow: hidden;
}

.count-characters{
    position: absolute;
    bottom: -25px;
    right: 40px;
    color: gray;
}
textarea{
    display: block;
    width: 100%;
    border-radius: 2rem;
    border: 0 none;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    outline: none !important;
    box-shadow: 0 none;
}
</style>