<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	computed: {
		
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			regionReferals: 'stats/regionReferals',
			leaderRegionReferalsStats: 'stats/leaderRegionReferalsStats',
		})
	},
	methods: {
		goToHistory(user_id) {
			this.$router.push({name: 'HistoryActivations', params: {id: user_id}})
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchRegionReferals: 'stats/fetchRegionReferals',
		})
	},
	mounted() {
		this.fetchUser()
		this.fetchRegionReferals()
	}
}
</script>
<template>
	<div class="ranking" v-if="user">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<h5 class="m-0 w-100 text-center mb-2">Ranking region</h5>
					<h6 class="fw-bold text-center w-100">Meta semanal: <span class="text-primary">{{leaderRegionReferalsStats.weekly_activations}}/{{user.weekly_goal}}</span></h6>
				</div>
			</div>
		</div>

		<div class="ranking__wrappers mt-3 px-2">
			<div class="start--show" v-if="leaderRegionReferalsStats.sumary == 0">
				<div class="start__top">
					<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				</div>
			</div>
			<div  class="box px-0">
				<div v-if="leaderRegionReferalsStats.sumary > 0">
					<div class="d-flex">
						<div class="fw-bold col-2 px-2 text-center">#</div>
						<div class="fw-bold col-10 px-2">Nombre</div>
					</div>
					<template
						v-for="(user, index) in regionReferals"
						>
						<div class="d-flex border-top py-2 mx-n3" :key="`user_r_${index}`" @click="goToHistory(user.id)">
							<div class="col-2 px-2 text-center">
								<span class="fw-bold">
									{{index + 1 }}
								</span>
							</div>
							<div class="col-10 text-capitalize px-2">
								<p class="m-0 text-capitalize text-ellipsis small">
									{{user.name.toLowerCase()}}
								</p>
								<h6 class="mb-0 mt-2 fw-bold small">Activaciones semanales:  <span class="text-primary">{{user.weekly_activations}}</span></h6>
								<p class="small fw-bold m-0">
									Activaciones  
								</p>
								<p class="small  m-0">
									<span class="">Hoy</span>: <span class="text-primary fw-bold">{{ user.daily_activations }}</span>
									<span class="ms-2 "> Totales: <span class="text-primary fw-bold">{{user.total_referals}}</span></span>
									<span class=" ms-2">Meta diaria:</span> <span class="text-primary fw-bold">{{user.daily_goal}}</span>
									
								</p>
							</div>
						</div>
					</template>
				</div>
				<div v-else class="px-2">
					<h6 class="text-center m-0">Tu equipo aún no logra activaciones.</h6>
					<h6 class="text-center m-0 fw-bold">Motiva y ayuda a los activadores a lograr su meta diaria.</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>