<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ProductItem from '../../components/Elektrizate/ProductItem.vue'
export default {
    components: {
        ProductItem,
    },
    data() {
        return {

        }   
    },
    computed: {
        product() {
            return this.products.length ? this.products[1] : null
        },
        ...mapGetters({
            authUser: 'auth/user',
            authWallet: 'auth/wallet',
            products: 'vault/localProducts',
            vaultCurrentPage: 'vault/currentPage',
            vaultLoading: 'vault/loading'
        })
    },
    methods: {
        async loadProducts(page = 1){
			let vm = this
			vm.setQuery(vm.query)
			vm.getNextLocalProducts(page)
		},
        exchage(amount) {
            let rate = this.authWallet.exchange.value

            return Math.ceil(amount / rate)
        },
        ...mapActions({
            fetchUser: 'auth/fetchUser',
            getNextLocalProducts: 'vault/getNextLocalProducts',
        }),
        ...mapMutations({
			startLoading: 'vault/start',
			setQuery:'vault/setQuery',
			setLocalProducts: 'vault/setLocalProducts'
		})
    },
    async mounted() {
        let session = await this.fetchUser()
        await this.loadProducts(1)
    }
}
</script>
<template>
    <div class="elektra-vault elektra-scroll-y" @scroll="(e) => scrollBottomEnd(e, loadProducts, vaultCurrentPage + 1)">
        <div class="elektra-vault__header">
            <div class="elektra-vault__header-content">
                <h1 class="elektra-vault__header-title">PREMIOS</h1>
                <p class="elektra-vault__header-text">Estos son los premios que puedes canjear</p>
            </div>
            <div class="elektra-vault__wallet">
                <div class="elektra-vault__wallet-wrap">
                    <img class="elektra-vault__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <p  class="elektra-vault__wallet-balance">{{ authWallet.balance }}</p>
                </div>
            </div>
        </div>
        <div>
            <div v-if="!authUser.vault_open">
                <div  class="elektra-vault__product animate__animated animate__fadeInUp ">
                    <div class="elektra-vault__product-bg"></div>
                    <div class="elektra-vault__product-details">
                        <div class="elektra-vault__product-icon">
                            <img src="/elektrizate/warning.svg" alt="">
                        </div>
                        <div>
                            <h2 class="elektra-vault__product-title">Bóveda cerrada, regresamos pronto.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <template v-for="(product, index) in products" >
                <ProductItem :product="product" :key="`products-${product.id}-${index}`"/>
            </template>

            
            <p class="text-center" v-if="vaultLoading">Cargando...</p>
        </div>
        <div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.elektra-vault{
    background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 170px;
    &__header{
        padding-top: 60px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 60px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 4rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
    &__product{
        padding: 1rem 2rem;
        position: relative;
        max-width: 320px;
        margin: 0 auto;
        margin-bottom: 1rem;
        opacity: 0;
        &-bg{
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 2rem;
            transform: skew(-12deg, 0deg);
            box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
        }
        &-details{
            position: relative;
            padding-top: 1rem;
            z-index: 2;
            color: #000;
            display: flex;
            align-items: center;
        }
        &-title{
            margin: 0;
            font-size: 0.7rem;
            font-weight: 600;
            margin-bottom: 0.7rem;
        }
        &-ref{
            color: #7100d6;
            font-size: 0.6rem;
            margin-bottom: 0;
        }
        &-picture{
            width: 40%;
            min-width: 40%;
            img{
                width: 100%;
                display: block;
                margin-top: -20%;
            }
        }
        &-price{
            color: #6700ce;
            font-size: 0.8rem;
            margin: 0;
        }
        &-icon{
            width: 20%;
            margin-right: 0.5rem;
            img{
                width: 100%;
                margin-top: -25%;
            }
        }
    }
}
</style>