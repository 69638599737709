export default {
    user: null,
    token: null,
    wallet: null,
    loginFailed: false,
    hasUpdate: false,
    materials: [],
    material: null,
	notifications: [],
}
