<script>
import ElektrizateHeaderInterface from './components/Elektrizate/HeaderInterface.vue'
import ElektrizateMenuNav from './components/Elektrizate/MenuNav.vue'
export default {
	components: {
		ElektrizateMenuNav,
		ElektrizateHeaderInterface
	},

	data() {
		return {}
	},
	computed: {
		headerInterfaceActive() {
			return this.$route.meta.headerInterface
		},
	},

}
</script>

<template>
	<div class="elektra-wrap">
		<ElektrizateHeaderInterface v-if="headerInterfaceActive"/>
		<router-view></router-view>
		<ElektrizateMenuNav v-if="$route.meta.menu"/>
	</div>
</template>
<style lang="scss">
@import './assets/scss/elektra.scss';
</style>