<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            material: 'auth/material' 
        })
    },

    mounted() {
        if(!this.material) {
            this.$router.push({name: 'Help'})
        }
    }
}
</script>
<template>
	<div class="elektra-up-order elektra-scroll-y" v-if="material">
		<div  class="elektra-up-order__header">
            <div class="elektra-up-order__header-content">
                <h1 class="elektra-up-order__header-title">AYUDA</h1>
				<p class="elektra-up-order__header-text">{{material.title}}</p>
            </div>
        </div>
		<div  v-if="material">
			
			<div class="col-12 px-2 py-3">
				<div class="elektra-box elektra-box--normal p-4"> 
					<div v-if="material.type == 'html'" v-html="material.content"></div>
				</div>
			</div>
		</div>

		<div v-if="faq">
			
		</div>
	</div>
</template>
<style lang="scss" scoped>
.vault__search-input{
	background: #6700ce !important;
}
.elektra-up-order{
	background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 170px;
	color: #000;
	&__header{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 50px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 1rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
	&__success{
		padding-top: 60px;
		height: calc(100vh - 170px);
		display: flex;
		&-wrap{
			background: rgba($color: #fff, $alpha: 0.7);
			border-radius: 4rem;
		}
		.elektra-up-order__wallet{
			position: relative;
			top: auto;
			left: auto;
			bottom: auto;
			transform: none;
			margin: 0;
			&-text{
				font-size: 0.7rem;
				margin: 0;
			}
		}
	}
	&__notice{
		background-color: #c9ff66;
		border-radius: 3rem 3rem 0 3rem;
		padding: 2rem 2rem;
		padding-right: 5rem;
		margin-right: -15%;
		position: relative;
		&::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            bottom: -49px;
            right: 0;
            background: url('/elektrizate/notice_bottom_decoration.png');
            background-size: 100% 100%;
        }
		h2{
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
		}
		h6{
			color: #6700ce;
			font-weight: 600;
		}
		p{
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
	&__logo{
        max-width: 70%;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        left: 15%;
		top: 5%;
        z-index: 2;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }
}
</style>