<script>
import ProductHolder from '../../components/Elektrizate/ProductHolder.vue'
import http from '../../http'
export default {
    components: {
        ProductHolder
    },
    data() {
        return {
            status: 'EVALUATION',
            questions: [
                {
                    id: 1,
                    question: '¿Qué acciones del cliente en baz súperapp te permiten ganar Elektrones a un activador?',
                    options: [
                        {
                            id: 1,
                            label: 'Comprar en tiendas en línea',
                            assert: false, 
                        },
                        {
                            id: 2,
                            label: 'Recargar tiempo aire, Pagar servicios y  abono semanal de crédito',
                            assert: true, 
                        },
                        {
                            id: 3,
                            label: 'Registarse en la baz súperapp',
                            assert: false, 
                        },
                    ]
                },
                {
                    id: 2,
                    question: '¿Cuántos Elektrones puede ganar como máximo un activador por cada tipo de transacción del cliente?',
                    options: [
                        {
                            id: 1,
                            label: '1 Elektrón',
                            assert: true, 
                        },
                        {
                            id: 2,
                            label: '2 Elektrones',
                            assert: false, 
                        },
                        {
                            id: 3,
                            label: '3 Elektrones',
                            assert: false, 
                        },
                    ]
                },
                {
                    id: 3,
                    question: '¿Se puede ganar Elektrones por transacciones de clientes que ya estaban activos antes del 21 de marzo de 2023?',
                    options: [
                        {
                            id: 1,
                            label: 'Sí',
                            assert: false, 
                        },
                        {
                            id: 2,
                            label: 'No',
                            assert: true, 
                        },
                       
                    ]
                },
                {
                    id: 4,
                    question: '¿Cómo funciona la promoción de baz pago de servicio?',
                    options: [
                        {
                            id: 1,
                            label: 'Si el cliente paga $200 o más, baz le devuelve $50 pesos.',
                            assert: true, 
                        },
                        {
                            id: 2,
                            label: 'Si el cliente paga $100 o más, baz le devuelve $30 pesos.',
                            assert: false, 
                        },
                        {
                            id: 3,
                            label: 'Si el cliente paga $300 o más, baz le devuelve $100 pesos.',
                            assert: false, 
                        },
                       
                    ]
                },
                {
                    id: 5,
                    question: '¿Cómo gana elektrones la cadena de mando (Promotores)?',
                    options: [
                       
                        {
                            id: 1,
                            label: 'La cadena de mando ganarán “Elektrones” con cada transacción generada por el cliente.',
                            assert: false, 
                        },
                        {
                            id: 2,
                            label: 'La cadena de mando ganarán “Elektrones” por el cumplimiento de meta por transacciones del cliente de manera semanal.',
                            assert: false, 
                        },
                        {
                            id: 3,
                            label: 'La cadena de mando ganarán “Elektrones” en una proporción 3 a 1. Por cada 3 “Elektrones” que consiga el Activador, cada uno de los integrantes de su Cadena de Mando ganarán 1 “Elektrón” .',
                            assert: true, 
                        },
                       
                    ]
                },
            ],
            selected: {}
        }
    },
    computed: {
        questionsList() {

            let list = this.questions.map(q => {
                q.options = q.options.sort(this.randomSort)
                return q
            })
            return list.sort(this.randomSort)
        }
    },
    methods: {
        resetEvaluation() {
            this.$set(this, 'selected', {})
            this.status = 'EVALUATION'
        },
        randomSort(a, b) {
            return Math.random() - 0.5;
        },
        selectOption(id, option) {
            this.$set(this.selected, id, option)
        },
        sendEvaluation() {
            let asserts = 0
            for (const key in this.selected) {
                if (Object.hasOwnProperty.call(this.selected, key)) {
                    if(this.selected[key].assert) {
                        asserts += 1
                    }
                }
            }

            if(asserts == this.questions.length) {
                this.status = 'SUCCESS'
                http.auth.tourComplete()
            } else {
                this.status = 'FAIL'
            }
        }
    }
}
</script>
<template>
    <div class="elektra-tour-intro ">
        <div v-if="status == 'EVALUATION'">

            <div class="elektra-tour-intro__header animate__animated animate__fadeInUp">
                <div class="elektra-tour-intro__header-top">
                    <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                </div>
            </div>
            <div class="elektra-tour-intro__content px-4 animate__animated animate__fadeInUp">
                <div class="px-4 elektra-evaluation__wrap" >
                    <span class="elektra-evaluation__title">Evaluación</span>
                    <h1 class="elektra-evaluation__question-title">Selecciona la respuesta correcta a las siguientes preguntas.</h1>
                    <hr>
                    <div v-for="(q, index) in questionsList" class="elektra-evaluation__question" :key="`question-${index}_${q.id}`">
                        <h3 class="elektra-evaluation__question-title">{{ index + 1 }} {{ q.question }}</h3>
                        <div>
                            <button class="elektra-evaluation__question-option" :class="{'elektra-evaluation__question-option--select': selected[q.id]?selected[q.id].id == option.id : false}" v-for="(option, index_option) in q.options" :key="`option-${index_option}_${index}`" @click="selectOption(q.id, option)">{{ option.label }}</button>
                        </div>
                    </div>
                </div>
                <div class="text-center d-flex justify-content-between  py-3">
                    <router-link class="elektra-btn elektra-btn--md me-2" :to="{name: 'ElektrizateTourMenu'}">Regresar</router-link>
                    <button :disabled="Object.keys(selected).length < questions.length" class="elektra-btn elektra-btn--secondary elektra-btn--md" @click="sendEvaluation">Calificar</button>
                </div>
            </div>
           
        </div>
        <div class="animate__animated animate__fadeInUp" v-else-if="status == 'SUCCESS'">
            <div class="elektra-evaluation__result px-4 ">
                <div class="px-4 elektra-evaluation__wrap " >
                    <div class="mb-3">
                        <img class="elektra-tour-intro__logo" src="/elektrizate/logo_elektrizate.svg" alt="">
                    </div>
                    <h1>¡Felicidades!</h1>
                    <p>Completaste el tutorial, estas listo para ganar Elektrones</p>
                    <div class="">
                        <img class="elektra-tour-intro__iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    </div>
                </div>
                <div class="text-center mt-5 ">
                    <router-link class="elektra-btn elektra-btn--secondary elektra-btn--md me-2" :to="{name: 'Home'}">Ir a Elektízate</router-link>
                </div>
            </div>
        </div>
        <div v-else-if="status == 'FAIL'" class="animate__animated animate__fadeInUp">
            <div class="elektra-evaluation__result px-4 ">
                <div class="mb-3 ">
                    <img class="elektra-tour-intro__logo2" src="/elektrizate/logo_elektrizate.svg" alt="">
                </div>
                <div class="px-3 elektra-evaluation__wrap " >
                    <div class=" elektra-evaluation__wrap-purple">
                        <div class="">
                            <img class="elektra-tour-intro__iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                        </div>
                        <p>Eres un experto, pero podrías mejorar.</p>
                        <p>Revisa el tutorial una vez más para ser el mejor.</p>
                    </div>
                </div>
                <div class="text-center mt-5 ">
                    <button class="elektra-btn elektra-btn--md me-2" @click="resetEvaluation">Volver</button>
                </div>
            </div>
           
        </div>


    </div>
</template>
<style lang="scss" scoped>
.elektra-evaluation{
    &__result{
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        align-items: center;
        p,
        h1{
            font-size: 2.5rem;
            font-weight: bold;
            text-align: center;
        }
        p{
            font-size: 2rem;
        }
        .elektra-tour-intro__logo{
            width: 100% !important;
            display: block;
            max-width: 100%;
            margin-top: -25%;
        }
        .elektra-tour-intro__logo2{
            width: 100% !important;
            display: block;
            max-width: 100%;
            margin-top: 0%;
        }
        .elektra-tour-intro__iso{
            display: block;
            max-width: 50%;
            margin-bottom: -25%;
        }
        .elektra-evaluation__wrap-purple{
            background-color: #6700ce;
            color: #fff;
            padding: 1rem;
            border-radius: 1.5rem;
            p{
                font-size: 1.6rem;
            }
            .elektra-tour-intro__iso{
                margin-bottom: 0rem;
            }

        }

    }
    &__wrap{
        background-color: #fff;
        color: #000;
        border-radius: 2rem;
        position: relative;
        padding-top: 1.5rem;
        margin-top: 2rem;
        padding-bottom: 1rem;
        hr{
            border-color: #6700ce;
            color: #6700ce;
            height: 2px;
        }
    }
    &__title{
        background-color: #6700ce;
        color: #fff;
        display: inline-block;
        padding: 0.7rem 2rem;
        border-radius: 2rem;
        position: absolute;
        top: -1.5rem;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px -2px 0 2px rgba($color: #fff, $alpha: 0.5);
        font-weight: bold;
    }
    &__question{
        margin-bottom: 0.75rem;
    }
    &__question-title{
        font-weight: 600;
        font-size: 0.9rem;
    }
    &__question-option{
        display: block;
        width: 100%;
        margin-bottom: 0.5rem;
        display: block;
        text-align: left;
        padding-left: 2rem;
        position: relative;
        background-color: transparent !important;
        border: 0 none !important;
        outline: none !important;
        &::before{
            content: '';
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            background: #ccc;
            box-shadow: inset 0 0 0 2rem #ccc;
            position: absolute;
            border-radius: 50%;
            left: 0;
            top: 0.2rem;
        }
        &--select{
            &::before{
                content: '';
                display: block;
                width: 1.25rem;
                height: 1.25rem;
                background: #6700ce;
                box-shadow: inset 0 0 0 0.25rem #ccc;
                position: absolute;
                border-radius: 50%;
                left: 0;
                top: 0.2rem;
            }
        }
    }
}
.elektra-btn__icon{
    min-width: 50px;
    width: 50px;
    margin-right: 0.5rem;
}
.elektra-btn__text{
    font-size: 1.2rem;
    text-align: left;
    color: #000;
    line-height: 1.2rem;
}
.elektra-icon-bonus{
    width: 60px;
    min-width: 60px;

}
.elektra-box{
    border-radius: 1rem;
    font-size: 0.9rem;
    h5{
        font-size: 0.8rem;
        font-weight: 600;

    }
    p{
        font-size: 0.8rem;
        text-align: justify;
    }
}
.elektra-btn--lg {
    width: 55%;
}
.elektra-tour-intro-box{
    background-color: rgba($color: #000000, $alpha: 0.7);
    border: 2px solid #bcff3f;
    border-radius: 2rem;
}
.elektra-start{
    color: #fff;
    background-image: url('/elektrizate/start_bg.png');
    position: absolute;
    height: 100vh;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size:100% 100%;
    padding-bottom: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.elektra-tour-intro{
    display: flex;
    flex-direction: column;
    &__notice{
        background: #bcff3f;
        border-radius: 2rem;
        padding: 0.5rem 1rem;
        display: flex;
        color: #000;
        font-size: 0.8rem;

        &-icon{
            width: 65px;
            display: inline-block;
            margin-right: 0.5rem;
        }
    }
    &__battery{
        position: absolute;
        width: 40px;
        right: 12%;
        top: -27px;
    }
    &__badge{
        width: 60%;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-top: -40%;
    }
    &__iso{
        position: relative;
        z-index: 3;
        width: 100%;
        top: 10%;
    }
    &__base-iso{
        position: absolute;
        z-index: 2;
        width: 70%;
        left: 15%;
        bottom: 10%;
    }
    &__header{
        &-top{
            position: relative;
            margin-bottom: 1.5rem;
        }
    }
    &__button{
        text-align: center;
        padding-bottom: 2rem;
    }
    &__up{
        height: 45vh;
        flex-grow: 1;
        position: relative;
    }
    &__down{
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__logo{
        max-width: 60%;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        bottom: 45%;
        z-index: 2;
        display: block;
        margin-left: auto;
        margin-right: auto;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
            top: -10%;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }

   
}
</style>
