<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
	data() {
		return {
			rewards: [],
			seletedCategories: [],
			categories: [
				{
					name: 'Italikas',
					tag: ['italika', 'ITALIKA-TRABAJO', 'ITALIKA-MOTONETA', 'ITALIKA-DEPORTIVA', 'ITALIKA-ATV', 'ITALIKA-DOBLE PROPOSITO'],
					icon: '/assets/categorias/motos.png',
				},
				{
					name: 'Celulares',
					tag: ['celulares', 'telefonos', 'abierta'],
					icon: '/assets/categorias/celulares.png',
				},
				{
					name: 'Videojuegos',
					tag: ['videojuegos', 'consolas', 'juegos'],
					icon: '/assets/categorias/videojuegos.png',
				},
				{
					name: 'Pantallas',
					tag: ['pantallas', 'televisiones', 'video'],
					icon: '/assets/categorias/pantallas.png',
				},
				{
					name: 'Laptops',
					tag: ['laptops', 'computadoras'],
					icon: '/assets/categorias/laptops.png',
				},
				{
					name: 'Muebles',
					tag: ['muebles', 'colchones y boxes'],
					icon: '/assets/categorias/muebles.png',
				},
				{
					name: 'Audios',
					tag: ['audios', 'audio', ''],
					icon: '/assets/categorias/audios.png',
				},
				{
					name: 'Hogar',
					tag: ['hogar', 'refrigeradores', 'estufas', 'hornos'],
					icon: '/assets/categorias/hogar.png',
				},
				{
					name: 'Tejeta',
					tag: ['tarjetas'],
					icon: '/assets/categorias/card.png',
				},
			],
			// products: [],
			query: '',
			loading: true,
		}
	},
	computed: {
		results() {
			let vm = this
			let data = this.products

			return data
		},

		...mapGetters({
			products: 'vault/localProducts',
			wallet: 'auth/wallet',
			user: 'auth/user',
			vaultLoading: 'vault/loading',
			currentPage: 'vault/currentPage',
			lastPage: 'vault/lastPage'
		})

		
	},
	watch:{
		query(){
			let vm = this
			if(vm.query.length > 2){
				vm.loadMoreProducts(1)
			}else if(vm.query == ''){
				vm.setLocalProducts([])
				vm.loadMoreProducts(1)
				vm.$set(vm, 'seletedCategories', [])
			}
		}
	},
	methods: {
		search(event){
			let vm = this
            clearTimeout(window.timeOutIDClient);
            window.timeOutIDClient = setTimeout(() => {
                if(event.target){
                    let qs = event.target.value;
                    vm.query = qs;
                }
            }, 500);
        },
		setCategory(tag) {
			let vm = this
			if(vm.seletedCategories.includes(tag[0])) {
				vm.$set(vm, 'seletedCategories', [])
			} else {
				vm.$set(vm, 'seletedCategories', tag)
			}

			vm.query = vm.seletedCategories.join(",");
			document.querySelector("#search").value = vm.query;
			// vm.loadMoreProducts()
		},

		goToDetails(sku) {
			this.$router.push({name: 'VaultDetails', params: {sku}})
		},
		getShippingCost(price) {
			return 0
			if(price <= 1000) {
				return 1;
			} else if(price <= 5000) {
				return 2;
			} else {
				return 3;
			}
		},
	
		calcGems(price) {
			let shippingCost = this.getShippingCost(price)
			return Math.ceil(price / 102) + shippingCost
		},
		loadMoreProducts(page){
			let vm = this
			vm.setQuery(vm.query)
			vm.startLoading()
			vm.getNextLocalProducts(page)
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			getLocalProducts: 'vault/getLocalProducts',
			getNextLocalProducts: 'vault/getNextLocalProducts',
		}),
		...mapMutations({
			startLoading: 'vault/start',
			setQuery:'vault/setQuery',
			setLocalProducts: 'vault/setLocalProducts'
		})
	},

	async mounted() {
		let session = await this.fetchUser()
		this.setQuery(this.query)
		// let now = this.$moment()
		this.getLocalProducts().then(res => {
			this.loading = false
		}).catch(err => {
			this.loading = false
		}) 


		// :style="{
		// 			'animation-delay': (25 * index ) + 25  + 'ms'
		// 		}"
	}
}
</script>

<template>
	<div class="vault">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper text-center">
					<img class="vault__header-word" src="/assets/pantalla_boveda/titulo_boveda.png" alt="">
				
				</div>
				
			</div>

		</div>
		<div v-if="wallet" class="score__coins">
			<span class="score__coins-text">{{wallet.balance}}</span>
			<img class="score__coins-bg" src="/assets/pantalla_perfil_puntaje/conteo_monedas.png" alt="">
		</div>
		<div class="vault__search mt-1">
			<div class="vault__search-input">
				<input id="search" type="text" @keydown="search" placeholder="Buscar premio">
				<button class="">
					<i class="fa fa-search" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<div class="vault__categories mt-1">
			<button class="vault__category" :class="{
				'vault__category--mark': seletedCategories.length,
				'vault__category--selected': seletedCategories.includes(c.tag[0]),
			}" v-for="(c, index) in categories" :key="`c_${index}`" @click="setCategory(c.tag)">
				<img :src="c.icon" alt="">
			</button>
		</div>
		
		<div class="vault__wrapper mt-3">
			<div class="alert alert-primary" v-if="!user.vault_open">
				<h6 class="text-center">Bóveda cerrada, regresamos pronto.</h6>
			</div>
			<div v-if="results.length" class="vault__list px-2">
				<div v-for="(reward, index) in results"  :key="`r-${index}-${reward.id}`" class="vault__reward" >
					<div v-if="reward.type == 'elektra'" class="vault__reward-content" @click="goToDetails(reward.sku)">
						<div class="vault__reward-image col-4 p-0">
							<img :src="forceHTTPS(reward.elektra.ImageUrl)" alt="">
						</div>
						<div class="vault__reward-name col-8">
							<p class="m-0">{{reward.elektra.ProductName}}</p>
							<div class="d-flex">
								<h6 v-if="calcGems(reward.elektra.Price) >  wallet.balance || !user.vault_open" class="text-primary m-0 vault__reward-action p-2">Precio G{{calcGems(reward.elektra.Price)}}</h6>
								<button v-else class="btn btn-primary btn--rounded btn-sm vault__reward-action ">
									<span class="fw-bold text-white">G{{calcGems(reward.elektra.Price)}} CANJEAR</span> 
								</button>
							</div>
						</div>
					</div>
					<div v-else class="vault__reward-content" @click="goToDetails(reward.sku)">
						<div class="vault__reward-image col-4 p-0">
							<img :src="reward.img" alt="">
						</div>
						<div class="vault__reward-name col-8">
							<p class="m-0 small">{{reward.name}}</p>
							<div class="d-flex">
								<h6 v-if="reward.gems >  wallet.balance || !user.vault_open" class="text-primary m-0 vault__reward-action p-2">Precio G{{reward.gems}}</h6>
								<button v-else class="btn btn-primary btn--rounded btn-sm vault__reward-action ">
									<span class="fw-bold text-white">G{{reward.gems}} CANJEAR</span> 
								</button>
							</div>
						</div>
					</div>
				</div>
				<div v-if="currentPage < lastPage && !vaultLoading" class="col-12">
					<div class="mx-auto text-white text-center">
						<button @click="loadMoreProducts(currentPage + 1)" class="btn btn-primary btn--rounded">Ver más productos</button>
					</div>
				</div>
				<div v-if="vaultLoading" class="col-12">
					<div class="mx-auto text-white text-center">
						<div class="lds-ripple"><div></div><div></div></div>
						<p class="text-center text-white">Cargando...</p>
					</div>
				</div>
			</div>
			<div class="text-center " v-else>
				
				<div class="start--show">
					<div class="start__top">
						<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
					</div>
				</div>
				<div class="box p-3">
					<h6 class="m-0 text-center" v-if="loading || vaultLoading">Buscando premios...</h6>
					<h6 class="" v-else>No encontré lo que buscas...</h6>

				</div>
			</div>
		</div>
		<div class="vault__bg">
			<div class="vault__d">
				<img src="/assets/pantalla_boveda/fondo_boveda.png" alt="">
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>