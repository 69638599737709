import { render, staticRenderFns } from "./TourIntro.vue?vue&type=template&id=568ccc3b&scoped=true&"
var script = {}
import style0 from "./TourIntro.vue?vue&type=style&index=0&id=568ccc3b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568ccc3b",
  null
  
)

export default component.exports