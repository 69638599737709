import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import stats from './stats'
import vault from './vault'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		stats,
		vault,
	}
})
