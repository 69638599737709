<script>
export default {
	data() {
		return {
			classdownload: '',
		}
	},

	mounted() {
		let vm = this
		setTimeout(() => {
			vm.classdownload = 'download--show'
		}, 2000)
	}
}
</script>
<template>
	<div class="download" :class="classdownload">
		<div class="download__wrapper">
			<div class="download__top">
				<img class="download__robot download__robot--left" src="/assets/pantalla_inicio/robot_pequeno_izquierda.png" alt="">
				<img class="download__robot download__robot--rigth" src="/assets/pantalla_inicio/robot_pequeno_derecha.png" alt="">
				<img class="download__robot download__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
			</div>
			<div class="download__bottom">
				<div class="download__content">
					<h3>Descargar app</h3>
					<div class="start__bottom__slogan mb-2 pb-1 mx-auto">
						<img class="start__bottom__slogan-pin" src="/assets/logo_verde.png" alt="">
					</div>
					<span class="small text-success">Ya disponible</span>
					<a href="/apps/androidbaz.apk" class="btn btn-success btn--rounded w-100 m-1">
						<span class="fw-bold text-white">
							<i class="fa fa-android" aria-hidden="true"></i> DESCARGAR ANDROID
						</span>
					</a>
					<p class="m-0">- O -</p>
					<span class="small text-primary">Ya disponible</span>
					<a   href="itms-services://?action=download-manifest&url=https://descargarbaz.sorianoariza.com/apps/app.plist" class="btn btn-primary btn--rounded w-100 m-1">
						<span class="fw-bold text-white">
							<i class="fa fa-apple" aria-hidden="true"></i> DESCARGAR iOS
						</span>
					</a>
				</div>
				<div class="download__logo">
					<img src="/assets/pantalla_inicio/logo_zeus.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>