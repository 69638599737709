<script>
import { mapGetters, mapMutations } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import ModalVue from '../../components/Elektrizate/ModalVue.vue'

export default {
    components: {
        Carousel,
		Slide,
        ModalVue,
    },
    data() {
        return {}
    },
    computed: {
        product() {
            return this.vaultProductBySku(this.$route.params.sku)
        },
        ...mapGetters({
            vaultProductBySku: 'vault/productBySku',
            authWallet: 'auth/wallet',
            authUser: 'auth/user'
        })
    },
    methods: {
        upOrder() {
			this.$refs.upOrder.open()
		},
        exchage(amount) {
            let rate = this.authWallet.exchange.value

            return Math.ceil(amount / rate)
        },
        setProduct() {
            this.vaultSetProduct(this.product)
			this.$router.push({name: 'UpOrder'})
        },
        ...mapMutations({
			vaultSetProduct: 'vault/setProduct'
		})
       
    },
    mounted() {
        if(!this.product) {

        }
    }
}
</script>
<template>
    <div class="elektra-vault-detail elektra-scroll-y" >
        <div class="px-3" v-if="product.type == 'elektra'">
            <div class="elektra-box elektra-box--normal px-0 pt-3">
                <div class="elektra-box__details">
                    <div class="elektra-vault-detail__slide">
                        <carousel :per-page="1">
                            <slide v-for="(image, index) in product.elektra.Images" :key="`img_${index}_${image.FileId}`">
                                <img :src="forceHTTPS(image.ImageUrl)" class="w-100" alt="">
                            </slide>
                        
                        </carousel>	
                    </div>
                </div>
            </div>
            <div>
                <div class="mb-3 text-center">
                    <span class="elektra-btn elektra-btn--md elektra-vault-detail__price" v-if="exchage(product.elektra.Price) > authWallet.balance || !authUser.vault_open">Disponible por E{{ exchage(product.elektra.Price) }}</span>
                    <button v-else class="elektra-btn elektra-btn--md elektra-btn--secondary font-weight-bold" @click="upOrder">E{{ exchage(product.elektra.Price) }} Canjear</button>
                </div>
            </div>
            <div class="elektra-box elektra-box--normal">
                <div class="elektra-box__details">
                    <h2 class="elektra-vault-detail__slide-title">{{ product.elektra.ProductName }}</h2>
                    <div class="p-3" v-html="product.elektra.ProductDescription"></div>
                </div>
            </div>
            <div>
                <div class="mb-3 text-center">
                    <span class="elektra-btn elektra-btn--md elektra-vault-detail__price" v-if="exchage(product.elektra.Price) > authWallet.balance || !authUser.vault_open">Disponible por E{{ exchage(product.elektra.Price) }}</span>
                    <button v-else class="elektra-btn elektra-btn--md elektra-btn--secondary font-weight-bold" @click="upOrder">E{{ exchage(product.elektra.Price) }} Canjear</button>
                </div>
            </div>
        </div>
        <div class="px-3" v-else>
            <div class="elektra-box elektra-box--normal px-0 pt-3">
                <div class="elektra-box__details">
                    <div class="elektra-vault-detail__slide">
                        <carousel :per-page="1">
                            <slide v-for="(image, index) in product.gallery" :key="`img_${index}}`">
                                <img :src="image" class="w-100" alt="">
                            </slide>
                        </carousel>	
                    </div>
                </div>
            </div>
            <div>
                <div class="mb-3 text-center">
                    <span class="elektra-btn elektra-btn--md elektra-vault-detail__price" v-if="product.gems > authWallet.balance || !authUser.vault_open">Disponible por E{{ product.gems }}</span>
                    <button v-else class="elektra-btn elektra-btn--md elektra-btn--secondary font-weight-bold" @click="upOrder">E{{ product.gems }} Canjear</button>
                </div>
            </div>
            <div class="elektra-box elektra-box--normal">
                <div class="elektra-box__details">
                    <h2 class="elektra-vault-detail__slide-title">{{ product.name }}</h2>
                    <div class="p-3" v-html="product.description"></div>
                </div>
            </div>
            <div>
                <div class="mb-3 text-center">
                    <span class="elektra-btn elektra-btn--md elektra-vault-detail__price" v-if="product.gems > authWallet.balance || !authUser.vault_open">Disponible por E{{ product.gems }}</span>
                    <button v-else class="elektra-btn elektra-btn--md elektra-btn--secondary font-weight-bold" @click="upOrder">E{{ product.gems }} Canjear</button>
                </div>
            </div>
        </div>
        <modal-vue ref="upOrder">
			<template v-slot:header>
				<h5 class="m-0">Aviso</h5>
			</template>
			<template v-slot:body>
				<p class="small">Antes de continuar debes aceptar los terminos y condiciones.</p>
				<h6>Consideraciones:</h6>
				<ul class="small">
					<li class="mb-1">No se permite retiro en tienda solo envíos.</li>
					<li class="mb-1">No se aceptan devoluciones.</li>
					<li class="mb-1">No se reembolsán gemas.</li>
					<li class="mb-1">No se reembolsa efectivo.</li>
					<li class="mb-1">Si el artículo esta dañado se permite cambio por otro similar.</li>
					<li class="mb-1">No se aceptan cancelaciones en la entrega y se harán dos intentos para la misma.</li>
				</ul>
				<div class="py-2 d-flex">
					<button class="elektra-btn ms-auto" @click="$refs.upOrder.close">Cancelar</button>
					<button class="elektra-btn elektra-btn--secondary fw-bold ms-2 me-auto" @click="setProduct">Aceptar 
						<span class="d-none d-md-inline-block">
							y continuar
						</span>
					</button>
				</div>
			</template>
		</modal-vue>
    </div>
</template>
<style lang="scss" scoped>
.elektra-box{
    overflow: hidden;
}
.elektra-vault-detail{
    background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 80px;
    padding-top: 70px;
    &__price{
        color: #6700ce;
    }
    &__slide{
        &-title{
            font-size: 1rem;
            font-weight: 600;
            padding: 0 1rem;
        }
    }
    &__header{
        padding-top: 60px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 60px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 4rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
    &__product{
        padding: 1rem 2rem;
        position: relative;
        max-width: 320px;
        margin: 0 auto;
        margin-bottom: 1rem;
        opacity: 0;
        &-bg{
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 2rem;
            transform: skew(-12deg, 0deg);
            box-shadow: 4px 4px 0 2px rgba($color: #000000, $alpha: 0.1);
        }
        &-details{
            position: relative;
            padding-top: 1rem;
            z-index: 2;
            color: #000;
            display: flex;
            align-items: center;
        }
        &-title{
            margin: 0;
            font-size: 0.7rem;
            font-weight: 600;
            margin-bottom: 0.7rem;
        }
        &-ref{
            color: #7100d6;
            font-size: 0.6rem;
            margin-bottom: 0;
        }
        &-picture{
            width: 40%;
            min-width: 40%;
            img{
                width: 100%;
                display: block;
                margin-top: -20%;
            }
        }
        &-price{
            color: #6700ce;
            font-size: 0.8rem;
            margin: 0;
        }
        &-icon{
            width: 20%;
            margin-right: 0.5rem;
            img{
                width: 100%;
                margin-top: -25%;
            }
        }
    }
}

</style>