<script>
import { mapActions, mapGetters } from 'vuex'
import http from '../../http'
import ModalVue from '../../components/Elektrizate/ModalVue.vue'
import ProductItem from '../../components/Elektrizate/ProductItem.vue'
export default {
	components: {
		ModalVue,
		ProductItem
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
				address: '',
				state: '',
				city: '',
			},

			orders: [],
			products: [],
			loading: true,
			selectedOrder: null,
			saving: false,
			currentOrder: 0
		}
	},
	computed: {
		
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
		})
	},
	methods: {
		async fetchOrders() {
			let vm = this

			try {
				let response = await http.orders.list()
				
				let products = []
				if(response.products.Products) {
					products = response.products.Products.map(p => p.result)
				}
				vm.$set(vm, 'products', products)
				response.orders.forEach(order => {
					if(order.type  == 'elektra') {
						order.product = vm.products.find(p => p.ProductId == order.sku)	
					} 
				});
				
				vm.$set(vm, 'orders', response.orders)
				vm.loading = false
			} catch (error) {
				vm.loading = false
			}
		},

		async confirmOrder() {
			this.saving = true
			try {
				let response = await http.orders.confirm(this.selectedOrder.id)
				this.$notify({
					group: 'tc',
					title: 'Comfirmada',
					text: response.message,
					type: 'succcess'
				});

				this.$refs.confirmCardModal.close()

				this.fetchOrders()
				this.saving = false

			} catch (error) {
				this.saving = false
			}
		},

		openConfrimCardModal() {
			// this.$set(this, 'selectedOrder', order)
			this.$refs.questionModal.close()
			this.$refs.confirmCardModal.open()
		},
		openQuestionModal(order) {
			this.$set(this, 'selectedOrder', order)
			this.$refs.questionModal.open()
		},
		getShippingCost(price) {
			return 0
			if(price <= 1000) {
				return 1;
			} else if(price <= 5000) {
				return 2;
			} else {
				return 3;
			}
		},
	
		calcGems(price) {
			let shippingCost = this.getShippingCost(price)
			return Math.ceil(price / 102) + shippingCost
		},
		upOrder() {
			this.$refs.upOrder.open()
		},
		product(sku) {
			return this.products.find(p => p.Id == sku)
		},
		setCurrentOrder(order) {
			this.$set(this, 'currentOrder', order.id)
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
		})
	}, 
	mounted() {
		this.fetchOrders()
		this.fetchUser()
	}
}
</script>
<template>
	<div class="elektra-up-order elektra-scroll-y" v-if="user">
		<div  class="elektra-up-order__header">
            <div class="elektra-up-order__header-content">
                <h1 class="elektra-up-order__header-title">PREMIOS</h1>
            </div>
            <div class="elektra-up-order__wallet">
                <div class="elektra-up-order__wallet-wrap">
                    <img class="elektra-up-order__wallet-iso" src="/elektrizate/iso_elektrizate-single.svg" alt="">
                    <p  class="elektra-up-order__wallet-balance">{{ wallet.balance }}</p>
                </div>
            </div>
        </div>
		
		<div class="px-2 mt-3">
			<div v-if="loading">

				<div class="elektra-up-order__logo-wrap">
					<img class="elektra-up-order__logo" src="/elektrizate/iso_elektrizate.svg" alt="">
					<img class="elektra-up-order__sun" src="/elektrizate/sun.svg" alt="">
					<img class="elektra-up-order__sun2" src="/elektrizate/sun.svg" alt="">
				</div>
				
				<div class="box p-3">
					<h6 class="m-0 text-center">Verificando premios canjeados...</h6>
				</div>
				
			</div>
			<div class="d-flex flex-wrap" v-else-if="orders.length">
				<div class="col-12 p-1"  v-for="(order, index) in orders" :key="`order_${index}`">
					<div v-if="order.type == 'elektra'" class="elektra-box elektra-box--normal m-0 mb-2" :style="{'animation-delay': index * 0.3 + 's'}">
						<div class="elektra-box__details d-flex py-2 flex-wrap">
							
							<div class="vault__reward-image col-4 p-0">
								<div v-if="order.status == 'CANCELED'">
									<span class="bg-danger text-white badge">Cancelada</span>
								</div>
								<img :src="order.product.ImageUrl" alt="">
							</div>
							<div class="vault__reward-name col-8 py-2 pb-0">
								<p class="m-0 small">
									<span class="fw-bold small">{{order.date | moment("DD-MMMM-YYYY")}}</span>
								</p>
								<p class="m-0 small">
									Orden: {{order.order}}
								</p>
								<p class="m-0 small">{{order.product.NameComplete}}</p>
								<div class="d-flex">
									<p class="m-0 small text-primary fw-bold">G{{order.total}}</p>
								</div>
							</div>
							<!-- <div class="col-12 px-2 text-center pb-2" v-if="currentOrder != order.id">
								<button v-if="order.status_history" class="m-0 small fw-bold btn-transparent ms-auto" @click="setCurrentOrder(order)">Seguimiento <i class="fa fa-angle-down" aria-hidden="true"></i></button>
							</div> -->
							<div class="col-12 px-2 " v-if="order.status == 'CANCELED' && order.comment">
								<div class="alert alert-danger small text-center">
									{{order.comment}}.
								</div>
							</div>
							<div v-if="order.status_history && order.status != 'CANCELED'" class="w-100 px-2">
								<div class="d-flex pt-1 pb-2">
									<div class="tracking" :class="{
										'tracking-current': ['En proceso', 'Colocada'].includes(order.status_elektra),
										'tracking-completed': ['Confirmada', 'En Proceso de entrega', 'Entregada'].includes(order.status_elektra),
									}">
										<span class="tracking-icon">
											<i class="fa fa-shopping-cart" aria-hidden="true"></i>
										</span>
										<span class="tracking-title">En proceso</span>
									</div>
									<div class="tracking-bard  ">
										<div class="tracking-bard-progress"></div>
									</div>
									<div class="tracking" :class="{
										'tracking-current': ['Confirmada'].includes(order.status_elektra),
										'tracking-completed': ['En Proceso de entrega', 'Entregada'].includes(order.status_elektra),
									}">
										<span class="tracking-icon">
											<i class="fa fa-shopping-bag" aria-hidden="true"></i>
										</span>
										<span class="tracking-title">Confirmada</span>
									</div>
									<div class="tracking-bard">
										<div class="tracking-bard-progress"></div>
									</div>
									<div class="tracking" :class="{
										'tracking-current': ['En Proceso de entrega'].includes(order.status_elektra),
										'tracking-completed': ['Entregada'].includes(order.status_elektra),
									}">
										<span class="tracking-icon">
											<i class="fa fa-truck" aria-hidden="true"></i>
										</span>
										<span class="tracking-title">En transito</span>
									</div>
									<div class="tracking-bard">
										<div class="tracking-bard-progress"></div>
									</div>
									<div class="tracking" :class="{
										'tracking-current': ['Entregada'].includes(order.status_elektra),
										'tracking-completed': ['Entregada'].includes(order.status_elektra),
									}">
										<span class="tracking-icon">
											<i class="fa fa-check-circle" aria-hidden="true"></i>
										</span>
										<span class="tracking-title">Entregada</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="order.type == 'card'" class="elektra-box elektra-box--normal m-0 mb-2" :style="{'animation-delay': index * 0.3 + 's'}">
						<div class="elektra-box__details d-flex py-2 flex-wrap">
							<div class="vault__reward-image col-4 p-0">
								<img :src="order.product.img" alt="">
							</div>
							<div class="vault__reward-name col-8 py-2">
								
								<p class="m-0 small">
									<span class="fw-bold small">{{order.date | moment("DD-MMMM-YYYY")}}</span>
								</p>
								<p class="m-0 small">
									Orden: {{order.order}}
								</p>
								<p class="m-0 small">{{order.product.name}}</p>
								<p class="m-0 small text-primary fw-bold">G{{order.total}}</p>
								
							</div>
							<!-- <div v-if="order.status == 'PENDING'" class="col-12 px-2 pb-3">
								<div class="alert alert-danger text-center py-1 px-1 small" role="alert">
								Cuando el premio  <span class="fw-bold"> HAYA LLEGADO A TU DOMICILIO</span>  <br> haz clic en  <span class="fw-bold">"Confirmo la recepción"</span>. Antes de ese momento, no lo hagas por favor. 
								</div>
								<button @click="openQuestionModal(order)" class="btn btn-primary btn--rounded w-100">Confirmo la recepción</button>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="elektra-up-order__logo-wrap">
					<img class="elektra-up-order__logo" src="/elektrizate/iso_elektrizate.svg" alt="">
					<img class="elektra-up-order__sun" src="/elektrizate/sun.svg" alt="">
					<img class="elektra-up-order__sun2" src="/elektrizate/sun.svg" alt="">
				</div>
				
				<div class="box p-3 text-center">
					<h6 class="m-0 mb-2 text-center">No has canjeado premios, sigue activando o visita la bóveda</h6>
					<router-link class="elektra-btn elektra-btn--secondary elektra-btn--md fw-bold" :to="{name: 'Vault'}">VER BÓVEDA</router-link>
				</div>

				
			</div>
		</div>
	
		<modal-vue ref="confirmCardModal">
			<template v-slot:header>
				<h5 class="m-0 text-center w-100">Aviso</h5>
			</template>
			<template v-slot:body>
				<div v-if="saving">
					<div class="d-flex">
						<div class="mx-auto text-primary">
							<div class="lds-ripple"><div></div><div></div></div>
							<p class="text-center">Cargando...</p>
						</div>
					</div>
				</div>
				<div v-else>
					<h6 class="text-center fw-bold mb-2">Has confirmado que esta tarjeta de regalo está en tus manos.</h6>
					<p class="text-center text-primary fw-bold">Orden: {{selectedOrder.order}}</p>
					<div class="text-center" v-if="selectedOrder.type == 'card'">
						<p>
							Una vez que tus datos hayan sido verificados correctamente por el sistema, recibirás un correo electrónico o un mensaje a través de Chat Zeus informándote a partir de qué momento podrás disponer del saldo. 
							<span class="fw-bold">Este correo podría tardar de 36 a 48 horas.</span>
						</p>
						
					</div>

					<div class="py-2 d-flex">
						
						<!-- <button class="btn btn--rounded fw-bold me-auto ms-auto" @click="$refs.confirmCardModal.close">Cancelar</button> -->
						<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="confirmOrder">Confirmar la recepción</button>
					</div>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="questionModal">
			<template v-slot:header>
				<h5 class="m-0 text-center w-100">IMPORTANTE</h5>
			</template>
			<template v-slot:body>
				
				<div >
					<p class="text-center text-primary fw-bold">Orden: {{selectedOrder.order}}</p>
					<p class="text-center">
						Confirmarás que ya cuentas físicamente con esta tarjeta.
					</p>
					<h6 class="text-center fw-bold mb-2">¿Ya tienes esta tarjeta en tus manos?</h6>

					<div class="py-2 d-flex">
						
						<!-- <button class="btn btn--rounded fw-bold me-auto ms-auto" @click="$refs.confirmCardModal.close">Cancelar</button> -->
						<button class="btn btn--rounded fw-bold ms-auto px-3" @click="$refs.questionModal.close">No</button>
						<button class="btn btn--rounded btn-primary fw-bold me-auto px-3" @click="openConfrimCardModal">SI</button>
					</div>
				</div>
			</template>
		</modal-vue>
	</div>
</template>

<style lang="scss" scoped>
.btn-transparent{
	color: #8200FF;
	border: 0;
	background: transparent ;
}
.tracking{
	position: relative;
	display: flex;
	align-items: center;
	width: 45px;
	// height: 35px;
	padding-bottom: 1.5rem;
	text-align: center;
	
	&-title{
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 0.6rem;
		white-space: nowrap;
	}
	&-icon{
		background: white;
		display: flex;
		width: 35px;
		height: 35px;
		font-size: 1.2rem;
		text-align: center;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 1px dashed #ccc;
		margin-left: auto;
		margin-right: auto;

	}
	&-bard{
		flex-grow: 1;
		background-color: rgb(236, 236, 236);
		width: 10%;
		height: 5px;
		border-radius: 20px;
		margin-top: 15px;
		position: relative;
		overflow: hidden;
		&-progress{
			position: absolute;
			width: 0%;
			background-color: #8200FF;
			height: 100%;
		}
		
	}
	&:first-child{
		.tracking-title{
			position: absolute;
			right: auto;
			left: 0;
			white-space: nowrap;
			transform: translateX(0);

		}
	}
	&:last-child{
		.tracking-title{
			position: absolute;
			right: 0;
			left: auto;
			white-space: nowrap;
			transform: translateX(0);
		}
	}
	&.tracking-current{
		.tracking-icon{
			border-color: #8200FF;
			color: #8200FF;
		}
		.tracking-title{
			// border-color: #8200FF;
			color: #8200FF;
			font-weight: 700;
		}
		& + .tracking-bard{
			.tracking-bard-progress{
				width: 50%;
			}
		}
	}
	&.tracking-completed{
		.tracking-icon{
			border-color: #8200FF;
			background: #8200FF;
			border-style: solid;
			color: #fff;
		}
		.tracking-title{
			color: #8200FF;
			// font-weight: 700;
		}
		& + .tracking-bard{
			.tracking-bard-progress{
				width: 100%;
			}
		}
	}
	
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
.box{
	border-radius: 1rem;
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
.menu-item__icon{
	margin-bottom: 0.5rem;
	span{
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: #36C800;
	}
}

.text-primary{
	color: #6700ce !important;
}
.vault__search-input{
	background: #6700ce !important;
}
.elektra-up-order{
	background: url('/elektrizate/start_bg.png');
    background-size: 100% 100%;
    height: 100vh;
    padding-bottom: 170px;
	color: #000;
	&__header{
        padding-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        background: #a466e2;
        text-align: center;
        border-radius: 0 0 0 100px;
        position: sticky;
        top: 0;
        margin-bottom: 50px;
        z-index: 99;
        &::before{
            content: '';
            position: absolute;
            display: block;
            top: 100%;
            right: 0;
            width: 120px;
            height: 50px;
            background: url('/elektrizate/vault_bottom_decoration.png');
            background-size: 100% 100%;
        }
        &-content{
            padding: 0 1rem;
        }
        &-title{
            font-size: 1rem;
            font-weight: bold;
            color: #f8d543;
        }
        &-text{
            margin: 0;
            font-size: 0.9rem;
        }
    }
    &__wallet{
        position: absolute;
        color: #7100d6;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
        bottom: -30px;
        &-wrap{
            display: flex;
            align-items: center;
            background: #fff;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            padding: 0.1rem 1rem;
            border-radius: 50px;
            min-width: 100px;
        }
        &-iso{
            width: 30px;
        }
        &-balance{
            width: 50%;
            flex-grow: 1;
            margin: 0;
            margin-left: 0rem;
        }
    }
	&__success{
		padding-top: 60px;
		height: calc(100vh - 170px);
		display: flex;
		&-wrap{
			background: rgba($color: #fff, $alpha: 0.7);
			border-radius: 4rem;
		}
		.elektra-up-order__wallet{
			position: relative;
			top: auto;
			left: auto;
			bottom: auto;
			transform: none;
			margin: 0;
			&-text{
				font-size: 0.7rem;
				margin: 0;
			}
		}
	}
	&__notice{
		background-color: #c9ff66;
		border-radius: 3rem 3rem 0 3rem;
		padding: 2rem 2rem;
		padding-right: 5rem;
		margin-right: -15%;
		position: relative;
		&::before{
            content: '';
            display: block;
            width: 120px;
            height: 50px;
            position: absolute;
            bottom: -49px;
            right: 0;
            background: url('/elektrizate/notice_bottom_decoration.png');
            background-size: 100% 100%;
        }
		h2{
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
		}
		h6{
			color: #6700ce;
			font-weight: 600;
		}
		p{
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
	&__logo{
        max-width: 70%;
        margin: 0 auto;
        display: inline-block;
        position: absolute;
        left: 15%;
		top: 5%;
        z-index: 2;
        &-wrap{
            position: relative;
            user-select: none;
            pointer-events: none;
        }
    }
    &__iso{
        max-width: 60%;
        margin: auto;
        display: block;
        animation: float 10s alternate infinite;
        &-wrap{
            position: relative;
        }
    }
    &__sun{
        animation: sun 220s infinite alternate;
        &2{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: sun 120s infinite alternate;
            z-index: 1;

        }
    }
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}

.elektra-box.selected{
	.elektra-box__bg{
		box-shadow: 4px 4px 0 4px #fff;
		background-color: #b0f566;
	}
}
</style>