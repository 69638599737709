<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	computed: {
		statsTerritory() {
			return this.territoryReferalsStats(this.user.id)
		},
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			territoryReferalsStats: 'stats/territoryReferalsStats',
			territoryReferals: 'stats/territoryReferals',
		})
	},
	methods: {
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchRegionReferals: 'stats/fetchRegionReferals',
			fetchTerritoryReferals: 'stats/fetchTerritoryReferals',
		})
	}, 
	mounted() {
		this.fetchUser()
		this.fetchRegionReferals()
		this.fetchTerritoryReferals()
	}
}
</script>
<template>
	<div class="ranking" v-if="user">
		<div class="vault__header">
			<div class="vault__header-content">
				<div class="vault__header-wrapper flex-wrap text-center w-100 ">
					<h5 class="m-0 w-100 text-center">Ranking territorial</h5>
					<h6 v-if="statsTerritory.me_referals > 0" class="w-100 text-center m-0">
						<span class="me-1">Tu puesto territorial es</span>  <span class="fw-bold">#{{ statsTerritory.position }}</span>
					</h6>
					<h6 v-else class="w-100 text-center m-0">
						<span class="me-1">¿Estás listo para activar?</span>
					</h6>
					<p class="w-100 small m-0" v-if="statsTerritory.me_referals == 0">
						<span class="fw-bold">¡Inicia!</span> vas a lograrlo
					</p>
					<p class="w-100 small m-0" v-else-if="statsTerritory.position == 1">
						<span class="fw-bold">¡Felicidades!</span> vas en la cima territorial
					</p>
					<p class="w-100 small m-0" v-else-if="statsTerritory.position > 4">
						<span class="fw-bold">¡Eso!</span> vas a llegar al primer lugar
					</p>
					<p class="w-100 small m-0" v-else-if="statsTerritory.position > 10">
						<span class="fw-bold">¡Hey!</span> vas en el top 10
					</p>
					<p class="w-100 small m-0" v-else-if="statsTerritory.position > 50">
						<span class="fw-bold">¡Vamos!</span> lo puedes lograr
					</p>
					<p class="w-100 small m-0" v-else>
						<span class="fw-bold">¡Vamos!</span> lo puedes lograr
					</p>
					
				</div>
			</div>
		</div>

		<div class="ranking__wrappers mt-3 px-2">
			<div class="start--show" v-if="statsTerritory.total_referals == 0">
				<div class="start__top">
					<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				</div>
			</div>
			<div class="box px-0">
				<div v-if="statsTerritory.total_referals">
					<div class="d-flex">
						<div class="fw-bold col-2 px-2 text-center">#</div>
						<div class="fw-bold col-10 px-2">Nombre</div>
					</div>
					<template  
						v-for="(user, index) in territoryReferals"
						>
						<div class="d-flex border-top py-2 mx-n3" :key="`user_r_${index}`" v-if="user.total_referals">
							<div class="col-2 px-2 text-center fw-bold">{{user.total_referals ? index + 1 : ''}}</div>
							<div class="col-10 text-capitalize px-2">
								<p class="m-0 text-ellipsis small">
									{{user.name.toLowerCase()}}
								</p>
								<p class="small fw-bold m-0">
									Activaciones: {{ user.total_referals }}
								</p>
							</div>
						</div>
					</template>
				</div>
				<div v-else class="px-2">
					<h6 class="text-center m-0">Sé el primero en activar.</h6>
					<h5 class="text-center m-0">Con tu código <span class="fw-bold text-primary">{{user.code}}</span></h5>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>