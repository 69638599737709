<script>
import { mapGetters } from 'vuex'
import http from '../../http'
import TableRanking from './TableRanking.vue'
export default {
    props: {
        challenge: {
            type: Object,
            require() {
                return true
            }
        }
    },
    components: {
        TableRanking
    },
    data() {
        return {
            myRank: null,
            tableRank: [],
            showRanking: false
        }
    },
    computed: {
        rolesID() {
            return this.challenge.roles.split(',').map(item => Number(item))
        },
        showMyRank() {
            debugger
            if(this,this.rolesID.length && this.user) {
                return this.rolesID.includes(this.user.role_id)
            }

            return false
        },
        ...mapGetters({
            user: 'auth/user',
        })
    },
    methods: {
        async fetchMyRanking() {
            try {
                let rank = await http.challenges.myRanking()
                this.$set(this, 'myRank', rank)
            } catch (error) {
                
            }
        },
        showTable() {
            this.showRanking = true
        }
    },
    async mounted() {
        if(this.showMyRank) {
            let myRank = await this.fetchMyRanking()
        }
    }
}
</script>
<template>
    <div>
        <h6 class="text-primary text-center">{{ challenge.name }}</h6>

        <div class="box-diamante px-3 pb-3 pt-3" v-if="showMyRank && myRank">
          
            <p class="text-center">
                Te encuentras en la <br><span class="text-success fw-bold">posición N° {{ myRank.position }}</span> <br> del ranking global,
                 acumulando <br>  <span class="text-success fw-bold">{{ myRank.count }} activaciones</span> 
            </p>
            <p class="text-center">Sigue acumulando activaciones! <br> válido desde el {{ challenge.start_date | moment("DD [de] MMMM") }} al {{ challenge.end_date | moment("DD [de] MMMM")}}</p>
            <div v-if="!showRanking" class="text-center">
                <button class="btn btn-primary btn-sm btn--rounded fw-bold text-uppercase" @click="showTable">Ver posiciones</button> 
            </div>

          
        </div>
        <div v-else>
            <TableRanking/>
        </div>

        <div v-if="showRanking" class="mt-3">
            <TableRanking/>
        </div>
    </div>
</template>